import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { saveBudgetSuccess, budgetFailure, generateBudgetSuccess, getBudgetSuccess } from './actions';

export function* saveBudget({ payload }) {
  try {
    const response = yield call (api.post, 'budgets', payload.data);
    toast.success('Proposta criada com sucesso!');
    yield put(saveBudgetSuccess(response.data));
  } catch (error) {
    toast.error('Não foi possível salvar sua proposta, confira seus dados');
    yield put(budgetFailure());
  }
}

export function* generateBudget({ payload }) {
  try {
    const response = yield call (api.post, 'generate-budget', payload.data);
    yield put(generateBudgetSuccess(response.data));
  } catch (error) {
    console.log('Não foi possível gerar sua proposta, confira seus dados');
    yield put(budgetFailure());
  }
}

export function* getBudget({payload}) {
  try {
    const response = yield call (api.post, `generate-budget`, payload.data);
    yield put(getBudgetSuccess(response.data));
  } catch (error) {
    console.log('Não foi possível buscar suas propostas, confira seus dados');
    yield put(budgetFailure());
  }
}

export default all([
  takeLatest('@budget/SAVE_BUDGET_REQUEST', saveBudget),
  takeLatest('@budget/GENERATE_BUDGET_REQUEST', generateBudget),
  takeLatest('@budget/GET_BUDGET_REQUEST', getBudget),
]);
