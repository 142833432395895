import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form'
import { FiArrowLeft, FiPlusCircle } from 'react-icons/fi';

import { regionSaveRequest } from '~/store/modules/region/actions';

export default function AddRegion() {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();
  const [region, setRegion] = useState([]);

  const onChange = e => {
    setRegion({...region,
      [e.target.name]: e.target.value}
    );
  };

  function onSubmit(data) {
    dispatch(regionSaveRequest(data));
  }

  return (
    <div className="container">
      <div className="title">
        <h1>
          <FiPlusCircle size="19px"/> Cadastro de <span> região </span>
        </h1>
        <Link to="/regions" className="button-add"><FiArrowLeft size="16px" color="#6159fd" />Voltar para listagem</Link>
      </div>

      <div className="painel">
        <form className="manager" onSubmit={handleSubmit(onSubmit)}>

          <h2>Preencha o formulário abaixo</h2>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="state">Estado (Sigla)</label>
                <select name="state" ref={register({ required: true })} className="select">
                  <option value="" className="placeholder" selected disabled>Selecione um estado</option>
                  <option value="ac">Acre</option>
                  <option value="al">Alagoas</option>
                  <option value="ap">Amapá</option>
                  <option value="am">Amazonas</option>
                  <option value="ba">Bahia</option>
                  <option value="ce">Ceará</option>
                  <option value="df">Distrito Federal</option>
                  <option value="es">Espírito Santo</option>
                  <option value="go">Goiás</option>
                  <option value="ma">Maranhão</option>
                  <option value="mt">Mato Grosso</option>
                  <option value="ms">Mato Grosso do Sul</option>
                  <option value="mg">Minas Gerais</option>
                  <option value="pa">Pará</option>
                  <option value="pb">Paraíba</option>
                  <option value="pr">Paraná</option>
                  <option value="pe">Pernambuco</option>
                  <option value="pi">Piauí</option>
                  <option value="rj">Rio de Janeiro</option>
                  <option value="rn">Rio Grande do Norte</option>
                  <option value="rs">Rio Grande do Sul</option>
                  <option value="ro">Rondônia</option>
                  <option value="rr">Roraima</option>
                  <option value="sc">Santa Catarina</option>
                  <option value="sp">São Paulo</option>
                  <option value="se">Sergipe</option>
                  <option value="to">Tocantins</option>
                </select>
                {errors.state && errors.state.type === 'required' && (
                  <span className="error-inputs">
                    Este campo é obrigatório
                  </span>
                )}
              </div>
            </div>
            <div className="col-2">
              <div className="input-group">
                <label htmlFor="name">Nome da região</label>
                <input name="name" type="text" ref={register()} placeholder="Francisco Beltrão" onChange={onChange} />
              </div>
            </div>
          </div>

          <div className="row row-space">
            <div className="col-1">
              <div className="input-group">
                <button type="submit">Salvar dados</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

}
