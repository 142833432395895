import { createGlobalStyle } from 'styled-components'

import 'react-toastify/dist/ReactToastify.css'
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'react-confirm-alert/src/react-confirm-alert.css'

export default createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  *.focus {
    outline: 0;
  }

  html, body, #root {
    height: 100%;
    background-color: #faf8fb;
  }

  body {
    -webkit-font-smoothing: antialiased;
  }

  body, input, button, select {
    font: 14px 'Roboto', sans-serif;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
  }

  .container {
    margin: 20px 30px 40px;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 96%;

    &.dash{
      margin: 20px 0px 40px;
      width: 100%;
    }

    .painel {
      border: 1px solid rgba(0,0,0,.09);
      margin: 20px 0 0;
      padding: 20px;
      background-color: #fff;
      -webkit-box-shadow: 0 0 13px 0 rgba(62,44,90,.08);
      box-shadow: 0 0 13px 0 rgba(62,44,90,.08);
      border-radius: 5px;

      h2 {
        font-size: 1rem;
        font-weight: 300;
        color: #505050;
        margin: 0 0 20px;
        border-bottom: 1px solid #efefef;
        padding-bottom: 5px;

        &.identificator{
          margin-top: 20px;
        }

      }
    }

    .title{
      display: flex;
      justify-content: space-between;
      align-items: center;

      h1 {
        font-size: 1.375rem;
        font-weight: bold;
        color: #505050;
        margin: 0;
        flex: 1;
        letter-spacing: 0;
        justify-content: space-between;

        svg {
          color: #a8a6ac;
          position: relative;
          top: 2px;
        }

        span {
          font-weight: 300;
        }
      }
    }

  }

  .button-add{
    margin: 8px 0px 0;
    height: 40px;
    line-height: 40px;
    background-color: #e7e6fa;
    font-weight: bold;
    color: #6159fd;
    text-transform: inherit;
    border-radius: 4px;
    font-size: 14px;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    padding: 5px 30px 5px 25px;
    font-weight: bold;
    border: none;

    svg{
      margin-right: 5px !important;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: #e7e6fa;
    }
  }

  .button-import{
    margin: 8px 0px 0;
    height: 40px;
    line-height: 40px;
    background-color: #6c757d;
    font-weight: bold;
    color: #fff;
    text-transform: inherit;
    border-radius: 4px;
    font-size: 14px;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    padding: 5px 30px 5px 25px;
    font-weight: bold;
    margin-left: 20px;
    border: none;

    svg{
      margin-right: 5px !important;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: #5a6268;
    }
  }

  .footer .button-print button,
  form.manager .button-print {
    border: none;
    margin: 10px 0px 0;
    height: 46px;
    line-height: 34px;
    background-color: #e7e6fa;
    font-weight: bold;
    color: #6159fd;
    text-transform: inherit;
    border-radius: 4px;
    font-size: 14px;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    padding: 5px 30px 5px 25px;
    font-weight: bold;
    float: right;
    margin-right: 10px;
    border: 2px solid #e7e6fa;
    text-transform: uppercase;

    svg{
      margin-right: 5px !important;
    }

    &.doc{
      background-color: #9c9c9c;
      border: 2px solid #9c9c9c;
      color: #fff;
      margin-right: 20px;

      svg{
        color: #fff !important;
      }
    }

    &:hover,
    &:focus,
    &:active {
      border: 2px solid #6159fd;
      background-color: #6159fd;
      color: #e7e6fa;

      svg{
        color: #e7e6fa !important;
      }
    }
    &.disabled {
      color: #d8d8d8;
      cursor: default;

      &:hover,
      &:focus,
      &:active {
        color: #d8d8d8;
        background-color: #e7e6fa;
        border: 2px solid #e7e6fa;
      }
    }
  }

  .button-action{
    display: flex;
    justify-content: center;
    margin-right: 10px;
    border: none;
  }

  .button-approved{
    margin: 8px 10px 0;
    height: 40px;
    line-height: 40px;
    background-color: #28a745;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    border-radius: 4px;
    font-size: 14px;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    padding: 5px 15px;
    border: none;

    &.dash{
      font-size: 12px;
      margin: 8px 5px 0;
      padding: 5px 10px 5px 15px;
    }

    svg {
      margin-top: -3px;
      margin-right: 5px;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: #1f8035;
    }
  }

  .button-details{
    margin: 8px 10px 0;
    height: 40px;
    line-height: 40px;
    background-color: #6ab8f7;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    border-radius: 4px;
    font-size: 14px;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    padding: 5px 15px;
    border: none;

    &.dash{
      line-height: 10px;
      font-size: 12px;
      margin: 8px 5px 0;
      padding: 5px 10px 5px 15px;
    }

    svg {
      margin-top: -3px;
      margin-right: 5px;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: #325571;
    }
  }

  .button-edit{
    margin: 0;
    height: 35px;
    line-height: 35px;
    background-color: #17a2b8;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    border-radius: 4px;
    font-size: 12px;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    padding: 1px 15px;
    border: none;
    display: inline-block;

    &.dash{
      font-size: 12px;
      margin: 8px 5px 0;
      padding: 5px 10px 5px 15px;
    }


    svg {
      margin-right: 5px;
      position: relative;
      top: 4px;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: #0c515d;
    }
  }

  .button-delete{
    margin: 0 0 0 5px;
    height: 35px;
    line-height: 35px;
    background-color: #dc3545;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    border-radius: 4px;
    font-size: 12px;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    padding: 1px 15px;
    border: none;
    display: inline-block;

    &.dash{
      font-size: 12px;
      margin: 8px 5px 0;
      padding: 5px 10px 5px 15px;
    }


    svg {
      margin-right: 5px;
      position: relative;
      top: 4px;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: #962731;
    }
  }

  .row {
    display: flex;
    flex-wrap: wrap;

    .row-space {
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -moz-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }

    .col-1 {
      width: calc((100% - 30px)/1);
    }
    .col-2 {
      width: calc((100% - 30px)/2);

      &:first-child {
        padding-right: 20px;
      }
    }
    .col-3 {
      width: calc((100% - 30px)/3);
      padding-right: 20px;

      &:last-child {
        padding-right: 0px;
      }
    }
  }

  .dash {
    .table {
      td:last-child,
      th:last-child {
        max-width: 200px;
      }
    }
  }

  form.manager {
    padding: 20px 10px 40px 40px;

    label {
      font-weight: bold;
      margin-bottom: 10px;
      color: #616161;
      display: inline-block;

      &.help{
        margin-bottom: 7px;
      }
    }

    .check label:first-child {
      display: block;
      margin-bottom: 10px;
    }

    .check-container{
      display: inline-block;
      position: relative;
      cursor: pointer;
      color: #666;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      margin-bottom: 20px;

      & label{
        position: relative;
        top: -5px;
        left: 3px;
      }

      label{
        margin-right: 15px;
        font-weight: 300;
      }

      input[type="radio"]{
        position: relative;
        top: 5px;
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: 0;
        background: lightgray;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        border: 1px solid white;
        margin-right: 5px;
      }
      input[type="checkbox"] {
        position: relative;
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: 0;
        background: lightgray;
        height: 20px;
        width: 20px;
        border: 1px solid white;
      }

      input[type="radio"]:checked,
      input[type="checkbox"]:checked {
        background: #797979;
      }

      input[type="radio"]:hover,
      input[type="checkbox"]:hover {
        filter: brightness(90%);
      }

      input[type="radio"]:disabled,
      input[type="checkbox"]:disabled {
        background: #e6e6e6;
        opacity: 0.6;
        pointer-events: none;
      }

      input[type="radio"]:after{
        content: '';
        position: relative;
        left: 4px;
        top: 4px;
        width: 10px;
        height: 10px;
        transform: rotate(45deg);
        display: none;
        background: #fff;
        border-radius: 50%;
      }
      input[type="checkbox"]:after {
        content: '';
        position: relative;
        left: 7px;
        top: 3px;
        width: 15%;
        height: 40%;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        display: none;
      }

      input[type="radio"]:checked:after,
      input[type="checkbox"]:checked:after {
        display: block;
      }

      input[type="radio"]:disabled:after,
      input[type="checkbox"]:disabled:after {
        border-color: #7b7b7b;
      }
    }

    select{
      background: rgba(255, 255, 255, 0.9);
      border: 1px solid #d4d4d4;
      border-radius: 4px;
      height: 45px;
      line-height: 45px;
      padding: 0 15px;
      margin: 0 0 20px;
      display: inline-block;
      width: 100%;

      option:disabled {
        color: #dedede;
      }
    }
    input[type="password"],
    input[type="email"],
    input[type="text"] {
      background: rgba(255, 255, 255, 0.9);
      border: 1px solid #d4d4d4;
      border-radius: 4px;
      height: 45px;
      line-height: 45px;
      padding: 0 15px;
      margin: 0 0 20px;
      display: inline-block;
      width: 100%;

      &.correctionFactor{
        background: #ddd;
        color: #9e9e9e;
       
        &::placeholder {
          color: #9e9e9e;
        }
      }

      &::placeholder {
        color: #dedede;
      }
    }

    span {
      color: red;
      -webkit-align-self: flex-start;
      -ms-flex-item-align: start;
      align-self: flex-start;
      margin: 0 0 10px;
      font-weight: 400;
      font-style: italic;
      position: relative;
      top: -20px;
    }

    button {
      margin: 10px 0 0;
      padding: 0px 20px;
      height: 45px;
      line-height: 42px;
      background-color: #28a745;
      border: 2px solid #28a745;
      font-weight: bold;
      color: #fff;
      text-transform: uppercase;
      border-radius: 4px;
      font-size: 14px;
      transition: background-color 0.2s;
      float: right;

      &:hover,
      &:focus,
      &:active {
        background-color: rgba(40, 167, 69, 0.5);
        border: 2px solid rgba(40, 167, 69, 0.5);
      }
    }

    a {
      color: #fff;
      margin-top: 15px;
      font-size: 14px;
      opacity: 0.8;

      &:hover,
      &:focus,
      &:active {
        opacity: 1;
      }

    }

    

  }

  .custom-ui-confirm-delete {
    text-align: center;
    width: 500px;
    padding: 40px;
    background: #28bae6;
    border-radius: 4px;
    box-shadow: 0 20px 75px rgba(251, 2, 2, 0.5);
    color: #fff;

    .title{
      font-size: 18px;
      margin: 20px 0;
    }

    > button {
      width: 160px;
      padding: 10px;
      border: 1px solid #fff;
      margin: 10px;
      cursor: pointer;
      background: none;
      color: #fff;
      font-size: 14px;

      svg {
        position: relative;
        top: 3px;
      }

      &:hover{
        background: #fff;
        color: #28bae6;
      }
    }

    > button.yes {
      background: #de4848;
      border: 1px solid #de4848;
      &:hover{
        background: none;
        color: #de4848;
      }
    }
  }

  .border-material {
    padding: 10px 0px 10px 20px;
    border-radius: 4px;
    border: 1px solid #d4d4d4;
    margin: 20px 0 40px;
    background: #fdfdfd;
    max-width: 98%;

    .action{
      display: inline-block;
      width: 98%;
      padding-right: 5px;
    }

    h2 {
      border-bottom: none !important;
      background: #fff;
      display: inline-block;
      top: -20px !important;
      position: relative;
      margin: 0 !important;
      padding: 0 10px !important;
    }
    button.add {
      height: 35px !important;
      line-height: 33px !important;
      background-color: #007bff !important;
      border-color: #007bff !important;
      font-size: 12px !important;
      float: none !important;
      margin: 0 0 10px !important;
    }
    button.remove {
      height: 35px !important;
      line-height: 33px !important;
      background-color: #dc3545 !important;
      border-color: #dc3545 !important;
      font-size: 12px !important;
      float: right !important;
      margin: 0 0 10px !important;
    }
  }

  .loadingModal{
    font-size: 24px;
    font-weight: bold;
    color: #666;
    text-align: center;
    margin: 20px 0;
  }

  span.totalGerationKwh{
    font-size: 14px;
    font-weight: normal !important;
    color: #666 !important;
    font-style: normal !important;
  }

  span.totalGerationKwh .valueKWH{
    color: #09203d !important;
    font-weight: bold !important;
    position: relative;
    top: 0px;
  }

  .basic-single > span {
    display: none;
  }

  .custom-radio {
    padding: 0px;
    margin: 0 0 20px;
    list-style:none;
  }
  .custom-radio li {
    display: inline;
    margin-right: 20px
  }
  .custom-radio li input {
    margin-right: 10px
  }

  .icon-help-message{
    position: relative;
    left: 5px;
    top: 3px;
  }
  .__react_component_tooltip .multi-line{
    color: #fff;
    font-style: normal;
    text-align: left;
    top: 0;
    padding: 0;
    font-weight: bold;
  }

  .loading-download{
    margin: 0;
    position: absolute;
    left: 5%;
    margin-top: 20px; 
    float: left;
  }

  .checkbox-import {
    display: inline-block;
  }

.table.is-striped{
  width: 100%;
  border: 1px solid #efefef;
  border-spacing: 0;
  border-radius: 4px;

  thead {
    border-spacing: 0;

    th {
      text-align: left;
      padding: 15px 0px 15px 10px;
      background: #efefef;
      color: #09203d;
      font-weight: bold;
      border: none;
      border-spacing: 0;
    }
  }
  
  tbody{
    td {
      padding: 10px 0px 10px 10px;
      border-right: 1px solid #efefef;
      border-bottom: 1px solid #efefef;
      border-spacing: 0;
    }
  }
}

.pagination {
  padding:0px;
  margin:0px;
  list-style:none;
  text-align: right;
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;

  li {
    background-color: #EDEDED;
    margin: 5px;
    border-radius: 5px;
    min-width: 38px;
    height: 30px;
    display: inline-block;
    text-align: center;

    
    a {
      font-size: 15px;
      padding: 5px 10px;
      display: inline-block;
      
    }

    &.active a {
      font-weight: bold;
    }
  }
}

.header-search{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.total-register{
  color: #989898;
  position: relative;
  top: -13px;
}
.search-table{
  position: relative;
  margin-bottom: 30px;
  display: inline-block;

  .input-group.search{
    background: rgba(255,255,255,0.9);
    border: 1px solid #eee;
    border-radius: 4px;
    height: 45px;
    line-height: 45px;
    padding: 0 15px;
    display: inline-block;
    min-width: 230px;
  }
  .button.search{
    border: 0;
    height: 45px;
    line-height: 45px;
    padding: 6px 12px;
    margin: 0 0 10px;
    background: #eee;
    display: inline-block;
    -webkit-border-top-right-radius: 5px;
    -webkit-border-bottom-right-radius: 5px;
    -moz-border-radius-topright: 5px;
    -moz-border-radius-bottomright: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    cursor: pointer;

    position: absolute;
    top: 0px;
    right: 0px;

    &:hover{
      background: #e7e6fa;
    }

  }

}

.loading {
  width: 100%;
  display: flex;
  min-height: 300px;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  color: #09203d;

  svg {
    margin-right: 10px;
  }

  .fa-spin {
    animation: fa-spin 2s infinite linear;
  }
  @keyframes fa-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
}

.uppercase{
  text-transform: uppercase;
}
.text-center{
  text-align: center;
}
select.capitalize {
  text-transform: capitalize
}

`
