import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { boardUpdateSuccess } from './actions';

export function* boardSave({ payload }) {
  try {
    const response = yield call(api.post, 'boards', payload.data);
    toast.success(response.data);
  } catch (error) {
    toast.error('Não foi possível salvar os dados. Contacte o administrador!');
  }
}

export function* boardUpdate({ id, payload }) {
  try {
    const response = yield call (api.put, `boards/${payload.id}`, payload.data);
    toast.success('Inversor atualizado com sucesso!');
    yield put(boardUpdateSuccess(response.data));
  } catch (error) {
    toast.error('Não foi possível atualizar o inversor. Contacte o administrador.');
  }
}

export function* boardDelete({ payload }) {
  try {
    yield call (api.delete, `boards/${payload.board_id}`);
    toast.success('Placa excluída com sucesso!');
  } catch (error) {
    toast.error('Não foi possível excluir a placa. Contacte o administrador.');
  }
}

export default all([
  takeLatest('@board/BOARD_SAVE_REQUEST', boardSave),
  takeLatest('@board/BOARD_UPDATE_REQUEST', boardUpdate),
  takeLatest('@board/BOARD_DELETE_REQUEST', boardDelete),
]);
