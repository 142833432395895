import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form'
import { default as NumberFormat } from 'react-number-format';

import { FiArrowLeft, FiPlusCircle } from 'react-icons/fi';

import api from '~/services/api' ;
import { dealershipUpdateRequest } from '~/store/modules/dealership/actions';


export default function EditPayment() {
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm([]);
  const [dealership, setDealership] = useState({});

  const onSubmit = data => {
    dispatch(dealershipUpdateRequest(dealership.id, dealership));
  }

  const onChange = e => {
    setDealership({...dealership,
      [e.target.name]: e.target.value}
    );
  };

  const EditPayment = () => {
    let { id } = useParams();
    return id;
  };
  const dealership_id = EditPayment();

  function calcValueWithTax(valueTaxInput){
    var tax = (valueTaxInput / 100) * dealership.value_kwh;
    var valueTax = parseFloat(dealership.value_kwh) - parseFloat(tax);
    setDealership({...dealership,
      icms: valueTaxInput,
      value_with_tax: valueTax.toFixed(2) }
    );
  }


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/dealerships/${dealership_id}`);
        setDealership(response.data);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="container">
      <div className="title">
        <h1>
          <FiPlusCircle size="19px"/> Editar <span> forma de pagamento </span>
        </h1>
        <Link to="/dealerships" className="button-add"><FiArrowLeft size="16px" color="#6159fd" />Voltar para listagem</Link>
      </div>

      <div className="painel">

        <form onSubmit={handleSubmit(onSubmit)} className="manager">

          <h2>Preencha o formulário abaixo</h2>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="potential">Estado (Sigla)</label>
                <input name="state" type="text" ref={register} placeholder="PR" onChange={onChange} defaultValue={dealership.state} />
              </div>
            </div>
            <div className="col-2">
              <div className="input-group">
                <label htmlFor="dealership">Nome da concessionária</label>
                <input name="dealership" type="text" ref={register} placeholder="Copel" onChange={onChange} defaultValue={dealership.dealership} />
              </div>
            </div>
          </div>

          <div className="row row-space">

            <div className="col-3">
              <div className="input-group">
                <label htmlFor="value_kwh">Valor KWh</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  isNumericString={true}
                  prefix={'R$ '}
                  placeholder="Ex.: R$ 0,00"
                  value={dealership.value_kwh}
                  onValueChange={(values) => {
                    const { value } = values;
                    setDealership({...dealership, value_kwh: value})}
                  }
                  getInputRef={(props) =>
                    <input name="value_kwh" type="text" value={dealership.value_kwh} ref={register}/>
                  }
                />
              </div>
            </div>

            <div className="col-3">
              <div className="input-group">
              <label htmlFor="icms">Valor do ICMS</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  isNumericString={true}
                  placeholder="Ex.: 9,00 %"
                  suffix=" %"
                  value={dealership.icms}
                  onValueChange={(values) => {
                    const { value } = values;
                    setDealership({...dealership, icms: value})
                    calcValueWithTax(value)}
                  }
                  getInputRef={(props) =>
                    <input name="icms" type="text" value={dealership.icms} ref={register}/>
                  }
                />
              </div>
            </div>

            <div className="col-3">
              <div className="input-group">
                <label htmlFor="value_with_tax">Valor do KWh com imposto</label>
                <input type="text" name="value_with_tax" value={dealership.value_with_tax} disabled/>
              </div>
            </div>

          </div>


          <div className="row row-space">
            <div className="col-1">
              <div className="input-group">
                <button type="submit">Salvar dados</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

}
