import produce from 'immer';

const INITIAL_STATE = {
  setting: null
};

export default function setting(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@settings/SETTING_SUCCESS': {
        draft.setting = action.payload;
        break;
      }
      default:
    }
  });
}
