import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FiX } from 'react-icons/fi';
import { AiOutlineFilePdf, AiOutlineFileWord } from 'react-icons/ai';
import api from '~/services/api' ;
import { generateBudgetRequest } from '~/store/modules/budget/actions';
import { store } from '~/store';
import { BlobProvider } from "@react-pdf/renderer";
import { Document, Packer, Paragraph, TextRun } from "docx";
import { saveAs } from 'file-saver';
import { PdfDocument } from "./GeneratePDF";
import { GenerateDoc } from "./GenerateDoc";

import './modal.css';
import logo from '~/assets/logo-relatorio.png';
import selo from '~/assets/selo.png';

export default function Modal(props) {
  const { show, closeModal, data } = props;
  const dispatch = useDispatch();
  const budget = useSelector(state => state.budget.budget);
  const [payments, setPayments] = useState([]);
  const [payment, setPayment] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [budgetPrint, setBudgetPrint] = useState([]);
  const [isLoadingBudget, setIsLoadingBudget] = useState(false);
  const { complete } = store.getState().budget;
  const [isShowBlobProvider, setIsShowBlobProvider] = useState(false);
  const [isShowDocumentoWord, setIsShowDocumentoWord] = useState(false);

  console.log(budget)

  const downloadFullPDF = (blob, filename) => {
    const a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      a.href = window.URL.createObjectURL(blob);
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(a.href);
      a.remove();
      setIsShowBlobProvider(false);
  };
  
  const downloadFullDocx = (budget, data) => {
    setIsShowDocumentoWord(true);
    const doc = GenerateDoc(budget, data);

    Packer.toBlob(doc).then(blob => {
      saveAs(blob, `Proposta Comercial Sol Brasil Energia Solar - ${data.client_name} - ${budget.description.power_total}.docx`);
    });
    setIsShowDocumentoWord(false);

  };

  const setting = useSelector(state => state.setting);
  const [preview, setPreview] = useState(setting && setting.setting.data.file_path);

  var datePT_BR;
  if (typeof data.created_at !== 'undefined') {
    var created_at = data.created_at;
    var retorno = created_at.split(" ");
    var newDate = retorno[0].split("-");
    var objDate = new Date(newDate[1] +"/"+ newDate[2] +"/"+ newDate[0]),
    locale = "pt-br",
    month = objDate.toLocaleString(locale, { month: "long" });
    datePT_BR = newDate[2] +" de "+ month +" de "+ newDate[0] +" às " + retorno[1];
  } else {
    datePT_BR = '';
  }

  const onChange = e => {
    const fetchPayment = async () => {
      try {
        const response = await api.get(`payments/findById/${e.target.value}`);
        console.log(response.data);
        setPayment(response.data);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchPayment();
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const response = await api.get('payments');
        setPayments(response.data.data);
        setIsLoading(false);
      } catch (error) {
        console.log('Error: '+error);

      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (show) {
      const fetchDataGenerate = async () => {
        try {
          dispatch(generateBudgetRequest(data));
        } catch (error) {
          console.log('Error: '+error);
        }
      };
      fetchDataGenerate();
    }
  }, [data, dispatch, show]);

  return (
    <>
    <div className={show ? "overlay" : "hide"} onClick={closeModal} />
      <div className={show ? "modal" : "hide"}>
        <button onClick={closeModal} className="close">
          <FiX size={20} color="#000" />
        </button>
        { complete ? (
          <div className="header">
            <div className="info-client">
              <div className="name">{data.client_name}</div>
              <div className="city">{ data.client_city_rep === '' ? data.client_city : data.client_city_rep } - {data.client_state}</div>
              <div className="date">{datePT_BR}</div>
              <div className="phone">{data.client_phone}</div>
            </div>
            <div>
              <img src={logo} width={300}/>
            </div>
          </div>
        ) : (<></>)}

        { complete ? (
          <div id="pagePDF">
          <div className="content">
            <h1>Gerador Fotovoltaico - {budget.description.power_total} kWp</h1>
            <div className="info">
              <h2>Descrição</h2>
              <div className="hr"></div>
              <div className="table">
                <table align="center">
                  <tbody>
                    <tr>
                      <th align="right" width="300">POTÊNCIA TOTAL:</th>
                      <td align="left">{budget.description.power_total} KWp</td>
                    </tr>
                    <tr>
                      <th align="right">PRODUÇÃO ENERGIA MÉDIA:</th>
                      <td align="left">{budget.description.production_energy_month} KWh</td>
                    </tr>
                    <tr>
                      <th align="right">AUTOCONSUMO INSTANT NEO DA PRODUÇÃO:</th>
                      <td align="left">{budget.description.self_consumption_snapshot_production} KWh</td>
                    </tr>
                    <tr>
                      <th align="right">PRODUÇÃO INJETADA NA REDE:</th>
                      <td align="left">{budget.description.production_injected} KWh</td>
                    </tr>
                    <tr>
                      <th align="right">ÁREA TOTAL UTILIZADA:</th>
                      <td align="left">{budget.description.area_total_used} m²</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="info">
              <h2>Equipamentos</h2>
              <div className="hr"></div>
              <div className="table">
                <table align="center">
                  <tbody>
                    <tr>
                      <th align="right" width="300">PAINEL SOLAR:</th>
                      <td align="left" width="120">{budget.equipment.board}</td>
                      <td align="left" width="150">{budget.equipment.board_manufacturer}</td>
                      <td align="left" width="150">{budget.equipment.board_qtde}</td>
                    </tr>
                    {budget.equipment.inverter.map((a, index) => {
                      return (
                        <tr>
                          <th align="right">INVERSOR:</th>
                          <td align="left">{a.inverter} KWp</td>
                          <td align="left">{a.inverter_manufacturer}</td>
                          <td align="left">{a.inverter_qtde} UNIDADES</td>
                        </tr>
                      );
                    }) }
                    <tr>
                      <th align="right">KIT FIXAÇÃO:</th>
                      <td align="left"> ---------- </td>
                      <td align="left">ARTSIGN</td>
                      <td align="left">{budget.equipment.board_qtde}</td>
                    </tr>
                    <tr>
                      <th align="right">MÓDULO DE MONITORAMENTO WIFI:</th>
                      <td align="left"> ---------- </td>
                      <td align="left">{budget.equipment.kit_monitoring_description_wifi}</td>
                      <td align="left">01 UNIDADES</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="info">
              <h2>Serviços</h2>
              <div className="hr"></div>
              <div className="table">
                <table align="center">
                  <tbody>
                    <tr>
                      <th align="right" width="300">MATERIAL ELÉTRICO:</th>
                      <td align="left">{budget.services.material_electric}</td>
                    </tr>
                    <tr>
                      <th align="right">MÃO DE OBRA INSTALAÇÃO:</th>
                      <td align="left">{budget.services.hand_work_installation}</td>
                    </tr>
                    <tr>
                      <th align="right">PROJETO ELÉTRICO SISTEMA:</th>
                      <td align="left">{budget.services.project_electric_system}</td>
                    </tr>
                    <tr>
                      <th align="right">REGULARIZAÇÃO DISTRIBUIDORA:</th>
                      <td align="left">{budget.services.regularization_distributor}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="info">
              <h2>Garantia</h2>
              <div className="hr"></div>
              <div className="table selo">
                <div className="selo">
                  <img src={selo}/>
                </div>
                <table align="center">
                  <tbody>
                    <tr>
                      <th align="right" width="300">PAINEL SOLAR:</th>
                      <td align="left" width="250">{budget.warrant.board_efficiency}</td>
                      <td align="left" width="250">{budget.warrant.board_efficiency_time} anos</td>
                    </tr>
                    <tr>
                      <th align="right">PAINEL SOLAR:</th>
                      <td align="left" width="250">DEFEITOS DE FÁBRICA</td>
                      <td align="left" width="250">12 anos</td>
                    </tr>
                    <tr>
                      <th align="right">INVERSOR:</th>
                      <td align="left" width="250">{budget.warrant.inverter_defect}</td>
                      <td align="left" width="250">{budget.warrant.inverter_defect_time}</td>
                    </tr>
                    <tr>
                      <th align="right">INSTALAÇÃO:</th>
                      <td align="left" width="120">DEFEITOS NAS INSTALAÇÕES</td>
                      <td align="left" width="250">01 ano</td>
                    </tr>
                    {/* <tr>
                      <th align="right">GERAÇÃO DE ENERGIA:</th>
                      <td align="left" width="120">95% EFICIÊNCIA</td>
                      <td align="left" width="250">06 anos</td>
                    </tr> */}
                  </tbody>
                </table>
                {/* <h1>{budget.warrant.board_efficiency_time} ANOS DE GARANTIA - {budget.warrant.board_efficiency}</h1> */}
                <h1>{budget.warrant.geration_efficiency_percentual}</h1>
              </div>
            </div>
            <div className="info">
              <h2>Geração Estimada</h2>
              <div className="hr"></div>
              <div className="table">
                <table align="center">
                  <thead>
                    <tr className="title-tr">
                      <th width="300"></th>
                      <th width="300">GERAÇÃO TOTAL <br />ENERGIA kWh</th>
                      <th width="300">GERAÇÃO ENERGIA <br />AUTOCONSUMO kWh</th>
                      <th width="300">GERAÇÃO ENERGIA <br />INJETADA kWh</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td align="right">JANEIRO</td>
                      <td align="center">{budget.generation.janeiro.consumption_01}</td>
                      <td align="center">{budget.generation.janeiro.self_consumption}</td>
                      <td align="center">{budget.generation.janeiro.injected}</td>
                    </tr>
                    <tr>
                      <td align="right">FEVEREIRO</td>
                      <td align="center">{budget.generation.fevereiro.consumption_02}</td>
                      <td align="center">{budget.generation.fevereiro.self_consumption}</td>
                      <td align="center">{budget.generation.fevereiro.injected}</td>
                    </tr>
                    <tr>
                      <td align="right">MARÇO</td>
                      <td align="center">{budget.generation.marco.consumption_03}</td>
                      <td align="center">{budget.generation.marco.self_consumption}</td>
                      <td align="center">{budget.generation.marco.injected}</td>
                    </tr>
                    <tr>
                      <td align="right">ABRIL</td>
                      <td align="center">{budget.generation.abril.consumption_04}</td>
                      <td align="center">{budget.generation.abril.self_consumption}</td>
                      <td align="center">{budget.generation.abril.injected}</td>
                    </tr>
                    <tr>
                      <td align="right">MAIO</td>
                      <td align="center">{budget.generation.maio.consumption_05}</td>
                      <td align="center">{budget.generation.maio.self_consumption}</td>
                      <td align="center">{budget.generation.maio.injected}</td>
                    </tr>
                    <tr>
                      <td align="right">JUNHO</td>
                      <td align="center">{budget.generation.junho.consumption_06}</td>
                      <td align="center">{budget.generation.junho.self_consumption}</td>
                      <td align="center">{budget.generation.junho.injected}</td>
                    </tr>
                    <tr>
                      <td align="right">JULHO</td>
                      <td align="center">{budget.generation.julho.consumption_07}</td>
                      <td align="center">{budget.generation.julho.self_consumption}</td>
                      <td align="center">{budget.generation.julho.injected}</td>
                    </tr>
                    <tr>
                      <td align="right">AGOSTO</td>
                      <td align="center">{budget.generation.agosto.consumption_08}</td>
                      <td align="center">{budget.generation.agosto.self_consumption}</td>
                      <td align="center">{budget.generation.agosto.injected}</td>
                    </tr>
                    <tr>
                      <td align="right">SETEMBRO</td>
                      <td align="center">{budget.generation.setembro.consumption_09}</td>
                      <td align="center">{budget.generation.setembro.self_consumption}</td>
                      <td align="center">{budget.generation.setembro.injected}</td>
                    </tr>
                    <tr>
                      <td align="right">OUTUBRO</td>
                      <td align="center">{budget.generation.outubro.consumption_10}</td>
                      <td align="center">{budget.generation.outubro.self_consumption}</td>
                      <td align="center">{budget.generation.outubro.injected}</td>
                    </tr>
                    <tr>
                      <td align="right">NOVEMBRO</td>
                      <td align="center">{budget.generation.novembro.consumption_11}</td>
                      <td align="center">{budget.generation.novembro.self_consumption}</td>
                      <td align="center">{budget.generation.novembro.injected}</td>
                    </tr>
                    <tr>
                      <td align="right">DEZEMBRO</td>
                      <td align="center">{budget.generation.dezembro.consumption_12}</td>
                      <td align="center">{budget.generation.dezembro.self_consumption}</td>
                      <td align="center">{budget.generation.dezembro.injected}</td>
                    </tr>
                    <tr>
                      <th align="right">TOTAL</th>
                      <th align="center">{budget.generation.total_geration_energy_kwp}</th>
                      <th align="center">{budget.generation.total_geration_energy_self_consumption_kwp}</th>
                      <th align="center">{budget.generation.total_geration_injected_kwp}</th>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="info-geracao-estimada">
                <p>GERAÇÃO TOTAL = GERAÇÃO TOTAL SISTEMA FOTOVOLTAICO</p>
                <p>GERAÇÃO ENERGIA AUTOCONSUMO = PARTE DA GERAÇÃO TOTAL QUE É CONSUMIDA INSTANTANEAMENTE PELOS EQUIPAMENTOS LIGADOS</p>
                <p>GERAÇÃO ENERGIA INJETADA = PARTE DA GERAÇÃO TOTAL QUE NÃO É CONSUMIDA INSTANTANEAMENTE PELOS EQUIPAMENTOS LIGADOS E POR ISSO É INJETADA NA REDE DA DISTRIBUIDORA</p>
              </div>
            </div>
            <div className="info">
              <h2>Economia Estimada</h2>
              <div className="hr"></div>
              <div className="table">
                <table align="center">
                  <tbody>
                    <tr className="title-tr">
                      <th width="300"></th>
                      <th width="300">ECONOMIA TOTAL R$</th>
                      <th width="300">GERAÇÃO ENERGIA <br />AUTOCONSUMO R$</th>
                      <th width="300">ECONOMIA ENERGIA <br />INJETADA R$</th>
                    </tr>
                    <tr>
                      <td align="right">JANEIRO</td>
                      <td align="center">{budget.economy.janeiro.economy}</td>
                      <td align="center">{budget.economy.janeiro.economy_self_consumption}</td>
                      <td align="center">{budget.economy.janeiro.economy_injected}</td>
                    </tr>
                    <tr>
                      <td align="right">FEVEREIRO</td>
                      <td align="center">{budget.economy.fevereiro.economy}</td>
                      <td align="center">{budget.economy.fevereiro.economy_self_consumption}</td>
                      <td align="center">{budget.economy.fevereiro.economy_injected}</td>
                    </tr>
                    <tr>
                      <td align="right">MARÇO</td>
                      <td align="center">{budget.economy.marco.economy}</td>
                      <td align="center">{budget.economy.marco.economy_self_consumption}</td>
                      <td align="center">{budget.economy.marco.economy_injected}</td>
                    </tr>
                    <tr>
                      <td align="right">ABRIL</td>
                      <td align="center">{budget.economy.abril.economy}</td>
                      <td align="center">{budget.economy.abril.economy_self_consumption}</td>
                      <td align="center">{budget.economy.abril.economy_injected}</td>
                    </tr>
                    <tr>
                      <td align="right">MAIO</td>
                      <td align="center">{budget.economy.maio.economy}</td>
                      <td align="center">{budget.economy.maio.economy_self_consumption}</td>
                      <td align="center">{budget.economy.maio.economy_injected}</td>
                    </tr>
                    <tr>
                      <td align="right">JUNHO</td>
                      <td align="center">{budget.economy.junho.economy}</td>
                      <td align="center">{budget.economy.junho.economy_self_consumption}</td>
                      <td align="center">{budget.economy.junho.economy_injected}</td>
                    </tr>
                    <tr>
                      <td align="right">JULHO</td>
                      <td align="center">{budget.economy.julho.economy}</td>
                      <td align="center">{budget.economy.julho.economy_self_consumption}</td>
                      <td align="center">{budget.economy.julho.economy_injected}</td>
                    </tr>
                    <tr>
                      <td align="right">AGOSTO</td>
                      <td align="center">{budget.economy.agosto.economy}</td>
                      <td align="center">{budget.economy.agosto.economy_self_consumption}</td>
                      <td align="center">{budget.economy.agosto.economy_injected}</td>
                    </tr>
                    <tr>
                      <td align="right">SETEMBRO</td>
                      <td align="center">{budget.economy.setembro.economy}</td>
                      <td align="center">{budget.economy.setembro.economy_self_consumption}</td>
                      <td align="center">{budget.economy.setembro.economy_injected}</td>
                    </tr>
                    <tr>
                      <td align="right">OUTUBRO</td>
                      <td align="center">{budget.economy.outubro.economy}</td>
                      <td align="center">{budget.economy.outubro.economy_self_consumption}</td>
                      <td align="center">{budget.economy.outubro.economy_injected}</td>
                    </tr>
                    <tr>
                      <td align="right">NOVEMBRO</td>
                      <td align="center">{budget.economy.novembro.economy}</td>
                      <td align="center">{budget.economy.novembro.economy_self_consumption}</td>
                      <td align="center">{budget.economy.novembro.economy_injected}</td>
                    </tr>
                    <tr>
                      <td align="right">DEZEMBRO</td>
                      <td align="center">{budget.economy.dezembro.economy}</td>
                      <td align="center">{budget.economy.dezembro.economy_self_consumption}</td>
                      <td align="center">{budget.economy.dezembro.economy_injected}</td>
                    </tr>
                    <tr>
                      <th width="300" align="right">TOTAL</th>
                      <th width="300" align="center">{budget.economy.total_economy_energy_rs}</th>
                      <th width="300" align="center">{budget.economy.total_economy_geration_energy_self_consumption_kwp_rs}</th>
                      <th width="300" align="center">{budget.economy.total_economy_injected_rs_with_tax}</th>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="info-geracao-estimada">
                <p>ECONOMIA TOTAL = TOTAL DA ECONOMIA DO SISTEMA FOTOVOLTAICO EM R$</p>
                <p>ECONOMIA DE ENERGIA AUTOCONSUMO = PARTE DA ECONOMIA TOTAL RELATIVA AO AUTOCONSUMO DA ENERGIA GERADA. CADA KWH DE AUTOCONSUMO VALE O QUE SE PAGA PELA ENERGIA (R$ 0.88).</p>
                <p>ESTE VALOR DESAPARECE DA SUA CONTA DE ENERGIA POIS VOCÊ GERA E CONSOME AUTOMATICAMENTE A SUA ENERGIA DEIXANDO DE CONSUMIR ENERGIA DA DISTRIBUIDORA A R$ 0.88/KWH.</p>
                <p>ECONOMIA ENERGIA INJETADA = PARTE DA ECONOMIA TOTAL RELATIVA À ENERGIA INJETADA NA REDE DA DISTRIBUIDORA. CADA KWH DE ENERGIA INJETADA VALE O QUE VOCÊ PAGA KWH SEM ICMS (R$ 0.88). ESTE VALOR É O QUE VEM COMO DESCONTO NA SUA CONTA DE ENERGIA.</p>
              </div>
              <div className="total-economia">
                <h3>TOTAL ECONOMIA ANUAL ESTIMADA: <span>{budget.economy.total_economy_energy_rs}</span></h3>
                <h3>REAJUSTE ANUAL DE ENERGIA: 5.00%</h3>
              </div>
            </div>
            <div className="info">
              <h2>Preço do Gerador</h2>
              <div className="hr"></div>
              <div className="total-economia">
                <h3>PREÇO DO GERADOR INSTALADO: <span>{budget.payment[0].sales_price}</span></h3>
                {/* <h3 className="price">PREÇO POR KWp: R$ {budget.viability.price_generator}</h3> */}
                <h3 className="price">Preço gerador instalado à vista: R$ {budget.viability.price_generator_in_cash}</h3>
              </div>
            </div>
            <div className="info">
              <h2>Viabilidade</h2>
              <div className="hr"></div>
              <div className="table">
                <table align="center">
                  <tbody>
                    <tr>
                      <th align="right" width="300">ECONÔMICOS DO SISTEMA:</th>
                      <td align="left" width="300"></td>
                    </tr>
                    <tr>
                      <td align="right" width="300">VALOR DO kWh – AUTOCONSUMO:</td>
                      <td align="left" width="300">R$ {budget.viability.value_kwh} </td>
                    </tr>
                    <tr>
                      <td align="right" width="300">VALOR DO kWh – CRÉDITO:</td>
                      <td align="left" width="300">R$ {budget.viability.value_kwh_with_tax} com imposto. </td>
                    </tr>
                    <tr>
                      <td align="right" width="300">DEGRADAÇÃO ANUAL GERAÇÃO ENERGIA:</td>
                      <td align="left" width="300">0,80%</td>
                    </tr>
                    <tr>
                      <td align="right" width="300">REAJUSTE ANUAL DA ENERGIA:</td>
                      <td align="left" width="300">5,00%</td>
                    </tr>
                  </tbody>
                </table>
                <table align="center">
                  <tbody>
                    <tr>
                      <th align="right" width="300">VIABILIDADE DO INVESTIMENTO:</th>
                      <td align="left" width="300"></td>
                    </tr>
                    <tr>
                      <td align="right" width="300">TEMPO DE RETORNO DO INVESTIMENTO:</td>
                      <td align="left" width="300">{budget.viability.time_return}</td>
                    </tr>
                    <tr>
                      <td align="right" width="300">ECONOMIA TOTAL EM 25 ANOS:</td>
                      <td align="left" width="300">R$ {budget.total_roi}</td>
                    </tr>
                    <tr>
                      <td align="right" width="300">ECONOMIA TOTAL PRIMEIRO ANO:</td>
                      <td align="left" width="300">{budget.economy.total_economy_energy_rs}</td>
                    </tr>
                    <tr>
                      <td align="right" width="300">RENDIMENTO DO INVESTIMENTO:</td>
                      <td align="left" width="300">{budget.viability.rentability}% a.m.</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table time-return">
                <h3>TEMPO DE RETORNO DO INVESTIMENTO</h3>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="table time-return">
                          <table align="center">
                            <tbody>
                              <tr className="title-tr">
                                <th width="300"></th>
                                <th width="300">ECONOMIA R$</th>
                                <th width="300">ACUMULADO R$</th>
                              </tr>
                              <tr>
                                <td align="left">{budget.roi[0].ano_0.ano}</td>
                                <td align="center">{budget.roi[0].ano_0.economy}</td>
                                <td align="center">{budget.roi[0].ano_0.accumulated}</td>
                              </tr>
                              <tr>
                              <td align="left">{budget.roi[1].ano_1.ano}</td>
                                <td align="center">{budget.roi[1].ano_1.economy}</td>
                                <td align="center">{budget.roi[1].ano_1.accumulated}</td>
                              </tr>
                              <tr>
                                <td align="left">{budget.roi[2].ano_2.ano}</td>
                                <td align="center">{budget.roi[2].ano_2.economy}</td>
                                <td align="center">{budget.roi[2].ano_2.accumulated}</td>
                              </tr>
                              <tr>
                                <td align="left">{budget.roi[3].ano_3.ano}</td>
                                <td align="center">{budget.roi[3].ano_3.economy}</td>
                                <td align="center">{budget.roi[3].ano_3.accumulated}</td>
                              </tr>
                              <tr>
                                <td align="left">{budget.roi[4].ano_4.ano}</td>
                                <td align="center">{budget.roi[4].ano_4.economy}</td>
                                <td align="center">{budget.roi[4].ano_4.accumulated}</td>
                              </tr>
                              <tr>
                                <td align="left">{budget.roi[5].ano_5.ano}</td>
                                <td align="center">{budget.roi[5].ano_5.economy}</td>
                                <td align="center">{budget.roi[5].ano_5.accumulated}</td>
                              </tr>
                              <tr>
                                <td align="left">{budget.roi[6].ano_6.ano}</td>
                                <td align="center">{budget.roi[6].ano_6.economy}</td>
                                <td align="center">{budget.roi[6].ano_6.accumulated}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                      <td>
                        <div className="table time-return">
                          <table align="center">
                            <tbody>
                              <tr className="title-tr">
                                <th width="300"></th>
                                <th width="300">ECONOMIA R$</th>
                                <th width="300">ACUMULADO R$</th>
                              </tr>
                              <tr>
                                <td align="left">{budget.roi[7].ano_7.ano}</td>
                                <td align="center">{budget.roi[7].ano_7.economy}</td>
                                <td align="center">{budget.roi[7].ano_7.accumulated}</td>
                              </tr>
                              <tr>
                                <td align="left">{budget.roi[8].ano_8.ano}</td>
                                <td align="center">{budget.roi[8].ano_8.economy}</td>
                                <td align="center">{budget.roi[8].ano_8.accumulated}</td>
                              </tr>
                              <tr>
                                <td align="left">{budget.roi[9].ano_9.ano}</td>
                                <td align="center">{budget.roi[9].ano_9.economy}</td>
                                <td align="center">{budget.roi[9].ano_9.accumulated}</td>
                              </tr>
                              <tr>
                                <td align="left">{budget.roi[10].ano_10.ano}</td>
                                <td align="center">{budget.roi[10].ano_10.economy}</td>
                                <td align="center">{budget.roi[10].ano_10.accumulated}</td>
                              </tr>
                              <tr>
                                <td align="left">{budget.roi[11].ano_11.ano}</td>
                                <td align="center">{budget.roi[11].ano_11.economy}</td>
                                <td align="center">{budget.roi[11].ano_11.accumulated}</td>
                              </tr>
                              <tr>
                                <td align="left">{budget.roi[12].ano_12.ano}</td>
                                <td align="center">{budget.roi[12].ano_12.economy}</td>
                                <td align="center">{budget.roi[12].ano_12.accumulated}</td>
                              </tr>
                              <tr>
                                <td align="left">{budget.roi[13].ano_13.ano}</td>
                                <td align="center">{budget.roi[13].ano_13.economy}</td>
                                <td align="center">{budget.roi[13].ano_13.accumulated}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                      <td>
                        <div className="table time-return">
                          <table align="center">
                            <tbody>
                              <tr className="title-tr">
                                <th width="300"></th>
                                <th width="300">ECONOMIA R$</th>
                                <th width="300">ACUMULADO R$</th>
                              </tr>
                              <tr>
                                <td align="left">{budget.roi[14].ano_14.ano}</td>
                                <td align="center">{budget.roi[14].ano_14.economy}</td>
                                <td align="center">{budget.roi[14].ano_14.accumulated}</td>
                              </tr>
                              <tr>
                                <td align="left">{budget.roi[15].ano_15.ano}</td>
                                <td align="center">{budget.roi[15].ano_15.economy}</td>
                                <td align="center">{budget.roi[15].ano_15.accumulated}</td>
                              </tr>
                              <tr>
                                <td align="left">{budget.roi[16].ano_16.ano}</td>
                                <td align="center">{budget.roi[16].ano_16.economy}</td>
                                <td align="center">{budget.roi[16].ano_16.accumulated}</td>
                              </tr>
                              <tr>
                                <td align="left">{budget.roi[17].ano_17.ano}</td>
                                <td align="center">{budget.roi[17].ano_17.economy}</td>
                                <td align="center">{budget.roi[17].ano_17.accumulated}</td>
                              </tr>
                              <tr>
                                <td align="left">{budget.roi[18].ano_18.ano}</td>
                                <td align="center">{budget.roi[18].ano_18.economy}</td>
                                <td align="center">{budget.roi[18].ano_18.accumulated}</td>
                              </tr>
                              <tr>
                                <td align="left">{budget.roi[19].ano_19.ano}</td>
                                <td align="center">{budget.roi[19].ano_19.economy}</td>
                                <td align="center">{budget.roi[19].ano_19.accumulated}</td>
                              </tr>
                              <tr>
                                <td align="left">{budget.roi[20].ano_20.ano}</td>
                                <td align="center">{budget.roi[20].ano_20.economy}</td>
                                <td align="center">{budget.roi[20].ano_20.accumulated}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                      <td>
                        <div className="table time-return">
                          <table align="center">
                            <tbody>
                              <tr className="title-tr">
                                <th width="300"></th>
                                <th width="300">ECONOMIA R$</th>
                                <th width="300">ACUMULADO R$</th>
                              </tr>
                              <tr>
                                <td align="left">{budget.roi[21].ano_21.ano}</td>
                                <td align="center">{budget.roi[21].ano_21.economy}</td>
                                <td align="center">{budget.roi[21].ano_21.accumulated}</td>
                              </tr>
                              <tr>
                                <td align="left">{budget.roi[22].ano_22.ano}</td>
                                <td align="center">{budget.roi[22].ano_22.economy}</td>
                                <td align="center">{budget.roi[22].ano_22.accumulated}</td>
                              </tr>
                              <tr>
                                <td align="left">{budget.roi[23].ano_23.ano}</td>
                                <td align="center">{budget.roi[23].ano_23.economy}</td>
                                <td align="center">{budget.roi[23].ano_23.accumulated}</td>
                              </tr>
                              <tr>
                                <td align="left">{budget.roi[24].ano_24.ano}</td>
                                <td align="center">{budget.roi[24].ano_24.economy}</td>
                                <td align="center">{budget.roi[24].ano_24.accumulated}</td>
                              </tr>
                              <tr>
                                <td align="left">{budget.roi[25].ano_25.ano}</td>
                                <td align="center">{budget.roi[25].ano_25.economy}</td>
                                <td align="center">{budget.roi[25].ano_25.accumulated}</td>
                              </tr>
                              <tr>
                                <td align="left">TOTAL: </td>
                                <td align="center">{budget.total_roi}</td>
                                <td align="center">{budget.roi[25].ano_25.accumulated}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="info">
              <h2>Pagamento</h2>
              <div className="hr"></div>
              {/* <form className="manager">
                <div className="col-2">
                  <div className="input-group">
                    <label htmlFor="name">Formas de pagamento</label>
                    <select name="payment_id" onChange={onChange} >
                      { isLoading ? (
                        <option>Carregando</option>
                      ) : (
                        budget.payment.map((value, index) => {
                          return <option key={index} value={value.id}> Potência: {value.potential} | Qtde Placas: {value.qtd_boards} | Valor de Venda: {value.sales_price} | Tipo: {(value.price_type === '0' ? "Padrão" : "Especial")} </option>
                        })
                      )}
                      <option value="">Selecione uma forma de pagamento</option>
                    </select>
                  </div>
                </div>
              </form> */}

              <div className="table">
                <table align="center">
                  <tbody>
                    <tr>
                      <th align="right" width="300">VALOR TOTAL:</th>
                      <th align="left" width="300">R$ {budget.payment[0].sales_price}</th>
                    </tr>
                    {/* { (budget.payment[0].ten_plots_show !== 'false') ? ( */}
                      <tr>
                        <th align="right" width="300">10 PARCELAS SEM JUROS DE: </th>
                        <td align="left" width="300">R$ {budget.payment[0].price_in_10_discount}</td>
                      </tr>
                    {/* ) : (<></>) } */}
                    <tr>
                      <th align="right" width="300"></th>
                      <td align="left" width="300"></td>
                    </tr>
                    <tr>
                      <th align="right" width="300">VALOR TOTAL À VISTA NO PEDIDO:</th>
                      <td align="left" width="300">{budget.payment[0].discount_5_percent_delivery}</td>
                    </tr>
                    {/* <tr>
                      <th align="right" width="300">VALOR TOTAL À VISTA ENTREGA EQUIPAMENTO:</th>
                      <td align="left" width="300">{budget.discount.discount_cash_order_delivery}% de desconto</td>
                    </tr> */}
                    { (budget.payment[0].discount_friend > '0') ? (
                      <tr>
                        <th align="right" width="300">À VISTA PROMOÇÃO DESCONTO AMIGO:</th>
                        <td align="left" width="300">R$ {budget.payment[0].discount_friend} - CONSULTE-NOS</td>
                      </tr>
                    ) : (<></>) }
                    <tr>
                      <th align="right" width="300"></th>
                      <td align="left" width="300"></td>
                    </tr>
                    { (budget.payment[0].price_in_36 > '0') ? (
                      <tr>
                        <td align="right" width="300">36 PARCELAS DE:</td>
                        <td align="left" width="300">R$ {budget.payment[0].price_in_36}</td>
                      </tr>
                    ) : (<></>)}
                    { (budget.payment[0].price_in_48 > '0') ? (
                      <tr>
                        <td align="right" width="300">48 PARCELAS DE:</td>
                        <td align="left" width="300">R$ {budget.payment[0].price_in_48}</td>
                      </tr>
                    ) : (<></>)}
                    { (budget.payment[0].price_in_60 > '0') ? (
                      <tr>
                        <td align="right" width="300">60 PARCELAS DE: </td>
                        <td align="left" width="300">R$ {budget.payment[0].price_in_60}</td>
                      </tr>
                    ) : (<></>)}
                    { (budget.payment[0].price_in_72 > '0') ? (
                      <tr>
                        <td align="right" width="300">72 PARCELAS DE: </td>
                        <td align="left" width="300">R$ {budget.payment[0].price_in_72}</td>
                      </tr>
                    ) : (<></>)}
                  </tbody>
                </table>
              </div>

            </div>

          </div>
        </div>
        ) : (<><div className="loadingModal">Carregando...</div></>)}
        { isShowBlobProvider ? (
          <BlobProvider document={<PdfDocument data={budget} state={data} preview={preview} />}>
          {({ blob, url, loading, error }) => {
            if (!loading) downloadFullPDF(blob, `Proposta Comercial Sol Brasil Energia Solar - ${data.client_name} - ${budget.description.power_total}.pdf`);
              return (
                <div className="loading-download">Download da proposta</div>
              );
            }}
          </BlobProvider>
        ) : (<></>)}
        { isShowDocumentoWord ? (
          <div className="loading-download">Download da proposta</div>
        ) : (<></>)}
        <div className="footer">
          <div className="button-print">
            <button onClick={() => setIsShowBlobProvider(true)} variant="text" color="primary">
              <AiOutlineFilePdf size={20} color="#6159fd" /> Gerar PDF
            </button>
          </div>
          <div className="button-print">
            <button onClick={() => downloadFullDocx(budget, data)} variant="text" color="primary" className="doc">
              <AiOutlineFileWord size={20} color="#6159fd" /> Gerar DOC
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
