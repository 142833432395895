import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { inverterUpdateSuccess } from './actions';

export function* inverterSave({ payload }) {
  try {
    const response = yield call(api.post, 'inversors', payload.data);
    toast.success(response.data);
  } catch (error) {
    toast.error('Não foi possível salvar os dados. Contacte o administrador!');
  }
}

export function* inverterUpdate({ id, payload }) {
  try {
    const response = yield call (api.put, `inversors/${payload.id}`, payload.data);
    toast.success('Inversor atualizado com sucesso!');
    yield put(inverterUpdateSuccess(response.data));
  } catch (error) {
    toast.error('Não foi possível atualizar o inversor. Contacte o administrador.');
  }
}

export function* inverterDelete({ payload }) {
  try {
    yield call (api.delete, `inversors/${payload.inverter_id}`);
    toast.success('Inversor excluído com sucesso!');
  } catch (error) {
    toast.error('Não foi possível excluir o inversor. Contacte o administrador.');
  }
}

export default all([
  takeLatest('@inverter/INVERTER_SAVE_REQUEST', inverterSave),
  takeLatest('@inverter/INVERTER_UPDATE_REQUEST', inverterUpdate),
  takeLatest('@inverter/INVERTER_DELETE_REQUEST', inverterDelete),
]);
