import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useForm, FormContext } from 'react-hook-form'
import * as Yup from 'yup';
import { default as NumberFormat } from 'react-number-format';
import { FiArrowLeft, FiPlusCircle } from 'react-icons/fi';

import api from '~/services/api' ;
import { boardUpdateRequest } from '~/store/modules/board/actions';

const schema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
});

export default function AddBoard() {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, methods } = useForm({
    validationSchema: schema
  });
  const [board, setBoard] = useState({});

  const onChange = e => {
    setBoard({...board,
      [e.target.name]: e.target.value}
    );
  };

  const onSubmit = data => {
    dispatch(boardUpdateRequest(board.id, board));
  }

  const EditInversor = () => {
    let { id } = useParams();
    return id;
  };
  const board_id = EditInversor();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/boards/${board_id}`);
        setBoard(response.data);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="container">
      <div className="title">
        <h1>
          <FiPlusCircle size="19px"/> Cadastro de <span> placa </span>
        </h1>
        <Link to="/boards" className="button-add"><FiArrowLeft size="16px" color="#6159fd" />Voltar para listagem</Link>
      </div>

      <div className="painel">
        <FormContext {...methods}>
          <form className="manager" onSubmit={handleSubmit(onSubmit)}>

            <h2>Modelo / Identificação da placa</h2>

            <div className="row row-space">
              <div className="col-2">
                <div className="input-group">
                  <label htmlFor="name">Nome da placa</label>
                  <input
                    name="name"
                    type="text"
                    placeholder="Ex.: Nome da placa"
                    defaultValue={board.name}
                    onChange={onChange}
                    ref={register}
                  />
                  {errors.name && <span>{errors.name.message}</span>}
                </div>
              </div>
              <div className="col-2">
                <div className="input-group">
                  <label htmlFor="manufacturer">Fabricante</label>
                  <input
                    name="manufacturer"
                    type="text"
                    placeholder="Ex.: Nome do Fabricante LTDA"
                    defaultValue={board.manufacturer}
                    onChange={onChange}
                    ref={register}
                  />
                </div>
              </div>
            </div>

            <div className="row row-space">

              <div className="col-2">
                <div className="input-group">
                  <label htmlFor="serie">Série</label>
                  <input
                    name="serie"
                    type="text"
                    placeholder="Ex.: CS5A"
                    defaultValue={board.serie}
                    onChange={onChange}
                    ref={register}
                  />
                </div>
              </div>

              <div className="col-2">
                <div className="input-group">
                  <label htmlFor="initial">Sigla</label>
                  <input
                    name="initial"
                    type="text"
                    placeholder="Ex.: CS3U-360P"
                    defaultValue={board.initial}
                    onChange={onChange}
                    ref={register}
                  />
                </div>
              </div>
            </div>

            <h2 className="identificator">Características principais</h2>

            <div className="row row-space">

              <div className="col-2">
                <div className="input-group">
                  <label htmlFor="char_power_nominal">Potência nominal</label>
                  <NumberFormat
                    decimalSeparator={","}
                    decimalScale={0}
                    fixedDecimalScale={true}
                    thousandSeparator={"."}
                    isNumericString={true}
                    suffix=" W"
                    placeholder="Ex.: 360 W"
                    value={board.char_power_nominal}
                    onValueChange={(values) => {
                      const {value} = values;
                      setBoard({...board, char_power_nominal: value})}
                    }
                    getInputRef={(props) =>
                      <input name="char_power_nominal" type="text" ref={register} value={board.char_power_nominal} />
                    }
                  />
                </div>
              </div>

              <div className="col-2">
                <div className="input-group">
                  <label htmlFor="char_type">Tipo</label>
                  <input
                    name="char_type"
                    type="text"
                    placeholder="Ex.: Silicío policristalino"
                    defaultValue={board.char_type}
                    onChange={onChange}
                    ref={register}
                  />
                </div>
              </div>

            </div>

            <div className="row row-space">

              <div className="col-2">
                <div className="input-group">
                  <label htmlFor="char_width">Largura</label>
                  <NumberFormat
                    decimalSeparator={","}
                    decimalScale={0}
                    fixedDecimalScale={true}
                    thousandSeparator={"."}
                    isNumericString={true}
                    suffix=" mm"
                    placeholder="Ex.: 992 mm"
                    value={board.char_width}
                    onValueChange={(values) => {
                      const {value} = values;
                      setBoard({...board, char_width: value})}
                    }
                    getInputRef={(props) =>
                      <input name="char_width" type="text" ref={register} value={board.char_width} />
                    }
                  />
                </div>
              </div>

              <div className="col-2">
                <div className="input-group">
                  <label htmlFor="char_height">Altura</label>
                  <NumberFormat
                    decimalSeparator={","}
                    decimalScale={0}
                    fixedDecimalScale={true}
                    thousandSeparator={"."}
                    isNumericString={true}
                    suffix=" mm"
                    placeholder="Ex.: 2000 mm"
                    value={board.char_height}
                    onValueChange={(values) => {
                      const {value} = values;
                      setBoard({...board, char_height: value})}
                    }
                    getInputRef={(props) =>
                      <input name="char_height" type="text" ref={register} value={board.char_height} />
                    }
                  />
                </div>
              </div>

            </div>

            <h2 className="identificator">Materiais</h2>

            <div className="row row-space">

              <div className="col-2">
                <div className="input-group">
                  <label htmlFor="material_code">Código</label>
                  <input
                    name="material_code"
                    type="text"
                    placeholder="Ex.: 123456"
                    defaultValue={board.material_code}
                    onChange={onChange}
                    ref={register}
                  />
                </div>
              </div>

              <div className="col-2">
                <div className="input-group">
                  <label htmlFor="material_production_year">Ano de produção</label>
                  <select name="material_production_year" ref={register} onChange={onChange} defaultValue={board.material_production_year} value={board.material_production_year}>
                    <option value="">Selecione o ano de produção</option>
                    <option value="2018">2018</option>
                    <option value="2019">2019</option>
                    <option value="2020">2020</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2026">2026</option>
                    <option value="2027">2027</option>
                    <option value="2028">2028</option>
                    <option value="2029">2029</option>
                    <option value="2030">2030</option>
                  </select>
                </div>
              </div>

            </div>

            <div className="row row-space">

              <div className="col-1">
                <div className="input-group">
                  <label htmlFor="material_description">Descrição</label>
                  <input
                    name="material_description"
                    type="text"
                    placeholder="Ex.: Descrição do material da placa"
                    defaultValue={board.material_description}
                    onChange={onChange}
                    ref={register}
                  />
                </div>
              </div>

            </div>

            <div className="row row-space">

              <div className="col-2">
                <div className="input-group">
                  <label htmlFor="material_value_acquisition">Preço de compra</label>
                  <NumberFormat
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={"."}
                    isNumericString={true}
                    prefix={'R$ '}
                    placeholder="Ex.: R$ 0,00"
                    value={board.material_value_acquisition}
                    onValueChange={(values) => {
                      const {value} = values;
                      setBoard({...board, material_value_acquisition: value})}
                    }
                    getInputRef={(props) =>
                      <input name="material_value_acquisition" type="text" ref={register} value={board.material_value_acquisition} />
                    }
                  />
                </div>
              </div>

              <div className="col-2">
                <div className="input-group">
                  <label htmlFor="material_value_sale">Preço de venda</label>
                  <NumberFormat
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={"."}
                    isNumericString={true}
                    prefix={'R$ '}
                    placeholder="Ex.: R$ 0,00"
                    value={board.material_value_sale}
                    onValueChange={(values) => {
                      const {value} = values;
                      setBoard({...board, material_value_sale: value})}
                    }
                    getInputRef={(props) =>
                      <input name="material_value_sale" type="text" ref={register} value={board.material_value_sale} />
                    }
                  />
                </div>
              </div>

            </div>

            <div className="row row-space">

              <div className="col-1">
                <div className="input-group check">
                  <label htmlFor="material_out_stock">Fora de produção?</label>
                  <div className="check-container">
                    <input
                      type="checkbox"
                      name="product_out_stock"
                      checked={board.product_out_stock === "0" ? false : true}
                      ref={register}
                      onChange={onChange}
                    />
                    <label> Sim? </label>
                  </div>
                </div>
              </div>

            </div>


            <h2 className="identificator">Características elétricas</h2>

            {/* <div className="row row-space">

              <div className="col-2">
                <div className="input-group">
                  <label htmlFor="char_eletrics_power">Potência / Superfície</label>
                  <NumberFormat
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={"."}
                    isNumericString={true}
                    suffix=" W/m²"
                    placeholder="Ex.: 181,45 W/m²"
                    value={board.char_eletrics_power}
                    onValueChange={(values) => {
                      const {value} = values;
                      setBoard({...board, char_eletrics_power: value})}
                    }
                    getInputRef={(props) =>
                      <input name="char_eletrics_power" type="text" ref={register} value={board.char_eletrics_power} />
                    }
                  />
                </div>
              </div>

              <div className="col-2">
                <div className="input-group">
                  <label htmlFor="char_eletrics_coefficient_tension">Coeficiente de tensão</label>
                  <NumberFormat
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={"."}
                    isNumericString={true}
                    suffix=" mV/ºC"
                    placeholder="Ex.: -0.29 mV/ºC"
                    value={board.char_eletrics_coefficient_tension}
                    onValueChange={(values) => {
                      const {value} = values;
                      setBoard({...board, char_eletrics_coefficient_tension: value})}
                    }
                    getInputRef={(props) =>
                      <input name="char_eletrics_coefficient_tension" type="text" ref={register} value={board.char_eletrics_coefficient_tension} />
                    }
                  />
                </div>
              </div>

            </div>

            <div className="row row-space">

              <div className="col-2">
                <div className="input-group">
                  <label htmlFor="char_eletrics_yield">Rendimento</label>
                  <NumberFormat
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={"."}
                    isNumericString={true}
                    placeholder="Ex.: 18,15 %"
                    suffix=" %"
                    value={board.char_eletrics_yield}
                    onValueChange={(values) => {
                      const {value} = values;
                      setBoard({...board, char_eletrics_yield: value})}
                    }
                    getInputRef={(props) =>
                      <input name="char_eletrics_yield" type="text" ref={register} value={board.char_eletrics_yield} />
                    }
                  />
                </div>
              </div>

              <div className="col-2">
                <div className="input-group">
                  <label htmlFor="char_eletrics_coefficient_chain">Coeficiente de corrente</label>
                  <NumberFormat
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={"."}
                    isNumericString={true}
                    placeholder="Ex.: -0.05 mA/ºC"
                    suffix=" mA/ºC"
                    value={board.char_eletrics_coefficient_chain}
                    onValueChange={(values) => {
                      const {value} = values;
                      setBoard({...board, char_eletrics_coefficient_chain: value})}
                    }
                    getInputRef={(props) =>
                      <input name="char_eletrics_coefficient_chain" type="text" ref={register} value={board.char_eletrics_coefficient_chain} />
                    }
                  />
                </div>
              </div>

            </div>

            <div className="row row-space">

              <div className="col-2">
                <div className="input-group">
                  <label htmlFor="char_eletrics_tension_vmpp">Tensão nominal (VMPP)</label>
                  <NumberFormat
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={"."}
                    isNumericString={true}
                    placeholder="Ex.: 18,15 %"
                    suffix=" %"
                    value={board.char_eletrics_tension_vmpp}
                    onValueChange={(values) => {
                      const {value} = values;
                      setBoard({...board, char_eletrics_tension_vmpp: value})}
                    }
                    getInputRef={(props) =>
                      <input name="char_eletrics_tension_vmpp" type="text" ref={register} value={board.char_eletrics_tension_vmpp} />
                    }
                  />
                </div>
              </div>

            </div>

            <div className="row row-space">

              <div className="col-2">
                <div className="input-group">
                  <label htmlFor="char_eletrics_tension_voc">Tensão Circuito Aberto (VOC)</label>
                  <NumberFormat
                    decimalSeparator={","}
                    decimalScale={0}
                    fixedDecimalScale={true}
                    thousandSeparator={"."}
                    isNumericString={true}
                    suffix=" V"
                    placeholder="Ex.: 47 V"
                    value={board.char_eletrics_tension_voc}
                    onValueChange={(values) => {
                      const {value} = values;
                      setBoard({...board, char_eletrics_tension_voc: value})}
                    }
                    getInputRef={(props) =>
                      <input name="char_eletrics_tension_voc" type="text" ref={register} value={board.char_eletrics_tension_voc} />
                    }
                  />
                </div>
              </div>

              <div className="col-2">
                <div className="input-group">
                  <label htmlFor="char_eletrics_coefficient_power">Coeficiente de Potência</label>
                  <NumberFormat
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={"."}
                    isNumericString={true}
                    placeholder="Ex.: -0,37 %/ºC"
                    suffix=" %/ºC"
                    value={board.char_eletrics_coefficient_power}
                    onValueChange={(values) => {
                      const {value} = values;
                      setBoard({...board, char_eletrics_coefficient_power: value})}
                    }
                    getInputRef={(props) =>
                      <input name="char_eletrics_coefficient_power" type="text" ref={register} value={board.char_eletrics_tension_voc} />
                    }
                  />
                </div>
              </div>

            </div> */}

            <div className="row row-space">

              <div className="col-2">
                <div className="input-group">
                  <label htmlFor="char_eletrics_coefficient_power">Coeficiente de Potência</label>
                  <NumberFormat
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={"."}
                    isNumericString={true}
                    placeholder="Ex.: -0,37 %/ºC"
                    suffix=" %/ºC"
                    value={board.char_eletrics_coefficient_power}
                    onValueChange={(values) => {
                      const {value} = values;
                      setBoard({...board, char_eletrics_coefficient_power: value})}
                    }
                    getInputRef={(props) =>
                      <input name="char_eletrics_coefficient_power" type="text" ref={register} value={board.char_eletrics_tension_voc} />
                    }
                  />
                </div>
              </div>

              {/* <div className="col-2">
                <div className="input-group">
                  <label htmlFor="char_eletrics_chain_impp">Corrente nominal (IMPP)</label>
                  <NumberFormat
                    decimalSeparator={","}
                    decimalScale={1}
                    fixedDecimalScale={true}
                    thousandSeparator={"."}
                    isNumericString={true}
                    placeholder="Ex.: 9.1 A"
                    suffix=" A"
                    value={board.char_eletrics_chain_impp}
                    onValueChange={(values) => {
                      const {value} = values;
                      setBoard({...board, char_eletrics_chain_impp: value})}
                    }
                    getInputRef={(props) =>
                      <input name="char_eletrics_chain_impp" type="text" ref={register} value={board.char_eletrics_tension_voc} />
                    }
                  />
                </div>
              </div> */}

              <div className="col-2">
                <div className="input-group">
                  <label htmlFor="char_eletrics_temp_noct">Temperatura nominal de trabalho </label>
                  <NumberFormat
                    decimalSeparator={","}
                    decimalScale={0}
                    fixedDecimalScale={true}
                    thousandSeparator={"."}
                    isNumericString={true}
                    placeholder="Ex.: 42 ºC"
                    suffix=" ºC"
                    value={board.char_eletrics_temp_noct}
                    onValueChange={(values) => {
                      const {value} = values;
                      setBoard({...board, char_eletrics_temp_noct: value})}
                    }
                    getInputRef={(props) =>
                      <input name="char_eletrics_temp_noct" type="text" ref={register} value={board.char_eletrics_temp_noct} />
                    }
                  />
                </div>
              </div>

            </div>

            {/* <div className="row row-space">

              <div className="col-2">
                <div className="input-group">
                  <label htmlFor="char_eletrics_chain_isc">Corrente C.C. (ISC)</label>
                  <NumberFormat
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={"."}
                    isNumericString={true}
                    placeholder="Ex.: 9.67 A"
                    suffix=" A"
                    value={board.char_eletrics_chain_isc}
                    onValueChange={(values) => {
                      const {value} = values;
                      setBoard({...board, char_eletrics_chain_isc: value})}
                    }
                    getInputRef={(props) =>
                      <input name="char_eletrics_chain_isc" type="text" ref={register} value={board.char_eletrics_chain_isc} />
                    }
                  />
                </div>
              </div>

              <div className="col-2">
                <div className="input-group">
                  <label htmlFor="char_eletrics_chain_max_isolation">Tensão máxima de isolamento </label>
                  <NumberFormat
                    decimalSeparator={","}
                    decimalScale={3}
                    fixedDecimalScale={true}
                    thousandSeparator={"."}
                    isNumericString={true}
                    placeholder="Ex.: 1.500 V"
                    suffix=" V"
                    value={board.char_eletrics_chain_max_isolation}
                    onValueChange={(values) => {
                      const {value} = values;
                      setBoard({...board, char_eletrics_chain_max_isolation: value})}
                    }
                    getInputRef={(props) =>
                      <input name="char_eletrics_chain_max_isolation" type="text" ref={register} value={board.char_eletrics_chain_max_isolation} />
                    }
                  />
                </div>
              </div>

            </div>

            <div className="row row-space">

              <div className="col-2">
                <div className="input-group">
                  <label htmlFor="char_eletrics_diodos_by_pass">Diodos de by-pass</label>
                  <select name="char_eletrics_diodos_by_pass" ref={register} onChange={onChange} defaultValue={board.char_eletrics_diodos_by_pass} value={board.char_eletrics_diodos_by_pass} >
                    <option value="">Selecione diodos by pass ...</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </select>
                </div>
              </div>

              <div className="col-2">
                <div className="input-group">
                  <label htmlFor="char_eletrics_tolerance_yield">Tolerância de rendimento </label>
                  <NumberFormat
                    decimalSeparator={","}
                    decimalScale={0}
                    fixedDecimalScale={true}
                    thousandSeparator={"."}
                    isNumericString={true}
                    suffix=" V"
                    placeholder="Ex.: 3 V"
                    value={board.char_eletrics_tolerance_yield}
                    onValueChange={(values) => {
                      const {value} = values;
                      setBoard({...board, char_eletrics_tolerance_yield: value})}
                    }
                    getInputRef={(props) =>
                      <input name="char_eletrics_tolerance_yield" type="text" ref={register} value={board.char_eletrics_tolerance_yield} />
                    }
                  />
                </div>
              </div>

            </div> */}

            <h2 className="identificator">Dimensões</h2>

            <div className="row row-space">

              <div className="col-2">
                <div className="input-group">
                  <label htmlFor="dimensions_thickness">Espessura</label>
                  <NumberFormat
                    decimalSeparator={","}
                    decimalScale={0}
                    fixedDecimalScale={true}
                    thousandSeparator={"."}
                    suffix=" mm"
                    placeholder="Ex.: 35 mm"
                    isNumericString={true}
                    value={board.dimensions_thickness}
                    onValueChange={(values) => {
                      const {value} = values;
                      setBoard({...board, dimensions_thickness: value})}
                    }
                    getInputRef={(props) =>
                      <input name="dimensions_thickness" type="text" ref={register}  value={board.dimensions_thickness} />
                    }
                  />
                </div>
              </div>

              <div className="col-2">
                <div className="input-group">
                  <label htmlFor="dimensions_area_reference">Área disponível de referência </label>
                  <NumberFormat
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={"."}
                    suffix=" m²"
                    placeholder="Ex.: 2 m²"
                    isNumericString={true}
                    value={board.dimensions_area_reference}
                    onValueChange={(values) => {
                      const {value} = values;
                      setBoard({...board, dimensions_area_reference: value})}
                    }
                    getInputRef={(props) =>
                      <input name="dimensions_area_reference" type="text" ref={register}  value={board.dimensions_area_reference} />
                    }
                  />
                </div>
              </div>

            </div>

            <div className="row row-space">

              <div className="col-2">
                <div className="input-group">
                  <label htmlFor="dimensions_weight">Peso</label>
                  <NumberFormat
                    decimalSeparator={","}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={"."}
                    placeholder="Ex.: 22,5 KG"
                    format={"##,## KG"}
                    value={board.dimensions_weight}
                    onValueChange={(values) => {
                      const {value} = values;
                      setBoard({...board, dimensions_weight: value})}
                    }
                    getInputRef={(props) =>
                      <input name="dimensions_weight" type="text" ref={register}  value={board.dimensions_weight} />
                    }
                  />
                </div>
              </div>

            </div>

            <h2 className="identificator">Certificações</h2>

            <div className="row row-space">

              <div className="col-3">
                <div className="input-group check">
                  <div className="check-container check">
                    <input
                      type="checkbox"
                      name="certifications_iec"
                      checked={board.certifications_iec === "0" ? false : true}
                      ref={register}
                      onChange={onChange}
                    />
                    <label> Certificação IEC</label>
                  </div>
                </div>
              </div>

              <div className="col-3">
                <div className="input-group check">
                  <div className="check-container check">
                    <input
                      type="checkbox"
                      name="certifications_class_ii"
                      checked={board.certifications_class_ii === "0" ? false : true}
                      ref={register}
                      onChange={onChange}
                    />
                    <label> Certificação de classe II</label>
                  </div>
                </div>
              </div>

              <div className="col-3">
                <div className="input-group check">
                  <div className="check-container check">
                    <input
                      type="checkbox"
                      name="certifications_inmetro"
                      checked={board.certifications_inmetro === "0" ? false : true}
                      ref={register}
                      onChange={onChange}
                    />
                    <label> Certificação INMETRO</label>
                  </div>
                </div>
              </div>

            </div>

            <div className="row row-space">

              <div className="col-1">
                <div className="input-group">
                  <label htmlFor="certifications_note">Notas</label>
                  <input
                    name="certifications_note"
                    type="text"
                    defaultValue={board.certifications_note}
                    placeholder="Informe as notas"
                    onChange={onChange}
                    ref={register}
                  />
                </div>
              </div>

            </div>

            <h2 className="identificator">Garantia em anos</h2>

            <div className="row row-space">

              <div className="col-2">
                <div className="input-group">
                  <label htmlFor="warrant">Informe o tempo de garantia da placa </label>
                  <input
                    name="warrant"
                    type="text"
                    placeholder="25"
                    onChange={onChange}
                    ref={register}
                    defaultValue={board.warrant}
                  />
                </div>
              </div>

            </div>


            <div className="row row-space">
              <div className="col-1">
                <div className="input-group">
                  <button type="submit">Salvar dados</button>
                </div>
              </div>
            </div>

          </form>
        </FormContext>
      </div>
    </div>
  );

}
