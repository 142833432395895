import produce from 'immer';

const INITIAL_STATE = {
  budget: null,
  complete: false,
};

export default function budget(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@budget/SAVE_BUDGET_SUCCESS': {
        const newState = Object.assign({}, state, {
          budget: action.payload.budget,
          complete: false,
        });
        return newState;
        break;
      }
      case '@budget/GENERATE_BUDGET_SUCCESS': {
        const newState = Object.assign({}, state, {
          budget: action.payload,
          complete: true,
        });
        return newState;
        break;
      }

      case '@budget/GET_BUDGET_SUCCESS': {
        const newState = Object.assign({}, state, {
          budget: action.payload,
          complete: true,
        });
        return newState;
        break;
      }
      default:
        return state
    }

  });
}
