export function boardSaveRequest(data) {
  return {
    type: '@board/BOARD_SAVE_REQUEST',
    payload: { data },
  };
}
export function boardUpdateRequest(id, data) {
  return {
    type: '@board/BOARD_UPDATE_REQUEST',
    payload: { id, data },
  };
}

export function boardUpdateSuccess(profile) {
  return {
    type: '@board/BOARD_UPDATE_SUCCESS',
  };
}

export function boardDeleteRequest(board_id) {
  return {
    type: '@board/BOARD_DELETE_REQUEST',
    payload: { board_id },
  };
}
