import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { regionUpdateSuccess } from './actions';

export function* regionSave({ payload }) {
  try {
    const response = yield call(api.post, 'regions', payload.data);
    toast.success(response.data);
  } catch (error) {
    toast.error('Não foi possível salvar os dados. Contacte o administrador!');
  }
}

export function* regionUpdate({ id, payload }) {
  try {
    const response = yield call (api.put, `regions/${payload.id}`, payload.data);
    toast.success('Região atualizada com sucesso!');
    yield put(regionUpdateSuccess(response.data));
  } catch (error) {
    toast.error('Não foi possível atualizar a região. Contacte o administrador.');
  }
}

export function* regionDelete({ payload }) {
  try {
    yield call (api.delete, `regions/${payload.region_id}`);
    toast.success('Região excluída com sucesso!');
  } catch (error) {
    toast.error('Não foi possível excluir a região. Contacte o administrador.');
  }
}

export default all([
  takeLatest('@region/REGION_SAVE_REQUEST', regionSave),
  takeLatest('@region/REGION_UPDATE_REQUEST', regionUpdate),
  takeLatest('@region/REGION_DELETE_REQUEST', regionDelete),
]);
