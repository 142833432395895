import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FiLayers, FiCloudLightning, FiCpu, FiDollarSign, FiUserCheck, FiPlusCircle, FiPower, FiSettings, FiZap, FiCloudDrizzle, FiMapPin, FiDivideCircle, FiEdit } from 'react-icons/fi';
// import Notifications from '~/components/Notifications';

import { signOut } from '~/store/modules/auth/actions';
import logo from '~/assets/logo-white.png';

import { Container, TopHeader, Profile, Navbar } from './styles';

export default function Header() {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.user.profile);
  var admin = true;
  if ( profile.role_name !== 'admin' ) {
    admin = false;
  }
  var agent = false;
  if ( profile.role_name === 'agent' ) {
    agent = true;
  }

  function handleSignOut() {
    dispatch(signOut());
  }

  return (
    <Container>
      <TopHeader>
        <div>
          <Link to="/dashboard">
            <img
              src={logo}
              alt="Sol Brasil Empreendimentos Energéticos"
            />
          </Link>
        </div>

        <aside>
          {/* <Notifications /> */}
          {!agent ?
            <Link to="/settings" className="settings">
              <FiSettings size={20} color="#ffffff" />
            </Link>
          : ''}
          <Profile>
            <div>
              <strong>Olá {profile.name}</strong>
              <Link to={"/edit-user/" + profile.id }>Meu perfil</Link>
            </div>
            <img
              src={profile.file_path || 'https://api.adorable.io/avatars/67/abott@adorable.png'}
              alt="Vagner Luis Soletti"
            />
          </Profile>
        </aside>
      </TopHeader>

      <Navbar>
        <nav>
          <ul>
            <li>
              <Link to="/dashboard"><FiLayers size="18px"/>Dashboard</Link>
            </li>
            
            {!agent ?
              <li>
                <Link to="#"><FiEdit size="18px"/>Cadastros</Link>
                <ul>
                    <li><Link to="/boards"><FiCpu size="18px"/>Placas</Link></li>
                    <li><Link to="/dealerships"><FiZap size="18px"/>Concessionária</Link></li>
                    <li><Link to="/temperatures"><FiCloudDrizzle size="18px"/>Temperaturas</Link></li>
                    <li><Link to="/inverters"><FiCloudLightning size="18px"/>Inversores</Link></li>
                  </ul>
              </li>
             : ''}
            
            {!agent ?
              <li>
                <Link to="/payments"><FiDollarSign size="18px"/>Formas de Pagamento</Link>
              </li>
            : ''}
            
            {!agent ?
              <li>
                <Link to="#"><FiDivideCircle size="18px"/>Desconto</Link>
                <ul>
                    <li><a href="/discount-regions">Região</a></li>
                    <li><a href="/discount-users">Usuário</a></li>
                  </ul>
              </li>
             : ''}
            
            {!agent ?
              <li>
                <Link to="/regions"><FiMapPin size="18px"/>Regiões</Link>
                <ul>
                    <li><a href="/regions">Região</a></li>
                    <li><a href="/cities-regions">Cidades</a></li>
                  </ul>
              </li>
             : ''}

            {admin ?
                <li>
                  <Link to="/users"><FiUserCheck size="18px"/>Usuários</Link>
                </li>
            : ''}

            <li>
              <Link to="/budget" className="button-budget"><FiPlusCircle size="18px"/>Criar proposta</Link>
            </li>
          </ul>
        </nav>

        <Link to="" className="logout" onClick={handleSignOut}><FiPower size="18px"/>Sair</Link>

      </Navbar>
    </Container>
  );
}
