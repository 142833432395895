export function regionSaveRequest(data) {
  return {
    type: '@region/REGION_SAVE_REQUEST',
    payload: { data },
  };
}

export function regionSaveSuccess(data) {
  return {
    type: '@region/REGION_SAVE_SUCCESS',
    payload: { data },
  };
}

export function regionUpdateRequest(id, data) {
  return {
    type: '@region/REGION_UPDATE_REQUEST',
    payload: { id, data },
  };
}

export function regionUpdateSuccess() {
  return {
    type: '@region/REGION_UPDATE_SUCCESS',
  };
}

export function regionDeleteRequest(region_id) {
  return {
    type: '@region/REGION_DELETE_REQUEST',
    payload: { region_id },
  };
}
