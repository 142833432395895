export function paymentSaveRequest(data) {
  return {
    type: '@payment/PAYMENT_SAVE_REQUEST',
    payload: { data },
  };
}

export function paymentSaveSuccess(data) {
  return {
    type: '@payment/PAYMENT_SAVE_SUCCESS',
    payload: { data },
  };
}

export function paymentUpdateRequest(id, data) {
  return {
    type: '@payment/PAYMENT_UPDATE_REQUEST',
    payload: { id, data },
  };
}

export function paymentUpdateSuccess() {
  return {
    type: '@payment/PAYMENT_UPDATE_SUCCESS',
  };
}

export function paymentDeleteRequest(payment_id) {
  return {
    type: '@payment/DELETE_PAYMENT_REQUEST',
    payload: { payment_id },
  };
}

export function paymentImportRequest(data) {
  return {
    type: '@payment/PAYMENT_IMPORT_REQUEST',
    payload: { data },
  };
}

export function paymentImportSuccess(data) {
  return {
    type: '@payment/PAYMENT_IMPORT_SUCCESS',
    payload: { data },
  };
}

export function paymentUpdateBoardRequest(data, first, last) {
  return {
    type: '@payment/PAYMENT_UPDATE_BOARD_REQUEST',
    payload: { data, first, last },
  };
}

export function paymentUpdateBoardSuccess(data) {
  return {
    type: '@payment/PAYMENT_UPDATE_BOARD_SUCCESS',
    payload: { data },
  };
}
