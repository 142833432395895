import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { settingsUpdateSuccess, settingsFailure } from './actions';

export function* updateSettings({ id, payload }) {
  try {
    const response = yield call (api.put, `settings/1`, payload.data);
    console.log(response);
    toast.success('Configurações atualizada com sucesso!');
    yield put(settingsUpdateSuccess(response.data));
  } catch (error) {
    toast.error('Não foi possível atualizar perfil, confira seus dados');
    yield put(settingsFailure());
  }
}

export default all([
  takeLatest('@settings/SETTING_REQUEST', updateSettings),
]);
