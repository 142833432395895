export function temperatureSaveRequest(data) {
  return {
    type: '@temperature/TEMPERATURE_SAVE_REQUEST',
    payload: { data },
  };
}

export function temperatureSaveSuccess(data) {
  return {
    type: '@temperature/TEMPERATURE_SAVE_SUCCESS',
    payload: { data },
  };
}

export function temperatureUpdateRequest(id, data) {
  return {
    type: '@temperature/TEMPERATURE_UPDATE_REQUEST',
    payload: { id, data },
  };
}

export function temperatureUpdateSuccess() {
  return {
    type: '@temperature/TEMPERATURE_UPDATE_SUCCESS',
  };
}

export function temperatureDeleteRequest(temperature_id) {
  return {
    type: '@temperature/TEMPERATURE_DELETE_REQUEST',
    payload: { temperature_id },
  };
}
