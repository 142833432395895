import React, { useState, useEffect  } from 'react';
import { useDispatch } from 'react-redux';
import { FiFileText, FiLayers } from 'react-icons/fi';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdFirstPage, MdLastPage, MdSearch } from 'react-icons/md';
import { ImSpinner11 } from 'react-icons/im';
import Pagination from "react-js-pagination";

import api from '~/services/api' ;
import { Wrapper } from './styles';
import Modal from '~/components/Modal';
import { getBudgetRequest } from '~/store/modules/budget/actions';

export default function Inverter() {
  // Modal
  const [show, setShow] = useState(false);
  const openModal = () => setShow(true);
  const closeModal = () => setShow(false);

  const dispatch = useDispatch();
  const [budget, setBudget] = useState({});
  const [budgets, setBudgets] = useState([]);
  const [searchInput, setSearchInput] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async (pageNumber = 1) => {
    setIsLoading(true);
    try {
      const response = await api.get(`budgets?page=${pageNumber}`);
      setBudgets(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log('Error: '+error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const setSearch = e => {
    setSearchInput(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  }

  async function handleSearch(){
    setIsLoading(true);
    if (searchInput !== '') {
      try {
        const response = await api.get(`budgets/search/${searchInput}`);
        setBudgets(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log(`Error: ${error}`);
      }
    } else {
      fetchData();
    }
  }

  function showDetalhes(budget) {
    setBudget({...budget, budget: budget});
    dispatch(getBudgetRequest(budget));
    openModal();
  }

  return (
    <Wrapper>
      <div className="container dash">
        <div className="title">
          <h1>
            <FiLayers size="19px"/> Listagem de <span> propostas </span>
          </h1>
        </div>

        <div className="painel">
          <div className="header-search">
            <div className="search-table">
              <div className="control">
                <input
                  className="input-group search"
                  type="text"
                  placeholder="Pesquisar"
                  autoComplete="off"
                  onChange={setSearch}
                  onKeyDown={handleKeyDown}
                />
              </div>
              <div className="control">
                <a
                  className="button search"
                  onClick={handleSearch}
                  >
                  <MdSearch
                    size={20}
                    color="#a9a9a9"
                  />
                </a>
              </div>
            </div>
            <div>
              <span className="total-register">Total de registros: <strong>{budgets.total}</strong></span>
            </div>
          </div>
          {isLoading ? (
            <div className="loading">
            <ImSpinner11
              size={24}
              color="#0A203F"
              className="fa-spin"
            /> Carregando ...</div>
          ) : (
            <>
              <table className="table is-striped">
                <thead>
                  <tr>
                    <th width="80" className="text-center">Código</th>
                    <th>Cliente</th>
                    <th>E-mail</th>
                    <th>Cidade de instalação</th>
                    <th width="180" className="text-center">Ações</th>
                  </tr>
                </thead>
                {budgets.length === 0 ? (
                  <tbody>
                    <tr>
                      <td colSpan="5" className="text-center">Nenhum Registro encontrado :(</td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {budgets.map((value, index) => (
                      <tr key={value.id}>
                        <td key={value.id}>{value.id}</td>
                        <td>{value.client_name}</td>
                        <td>{value.client_email}</td>
                        <td>{value.client_city_rep}</td>
                        <td width="250" className="text-center">
                          <div className="button-action">
                            <button onClick={() => showDetalhes(value)} className="button-details dash" title="Detalhes da proposta">
                              <FiFileText size="18px"/>Detalhes
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
              <Pagination
                activePage={budgets.current_page}
                itemsCountPerPage={budgets.per_page}
                totalItemsCount={budgets.total}
                onChange={(pageNumber) => fetchData(pageNumber)}
                prevPageText={<MdKeyboardArrowLeft size="18px" color="#A3AEB7" />}
                firstPageText={<MdFirstPage size="18px" color="#A3AEB7" />}
                nextPageText={<MdKeyboardArrowRight size="18px" color="#A3AEB7" />}
                lastPageText={<MdLastPage size="18px" color="#A3AEB7" />}
              />
            </>
          )}
        </div>
      </div>
      <Modal closeModal={closeModal} show={show} data={budget} />
    </Wrapper>
  );

}
