export function discountUserSaveRequest(data) {
  return {
    type: '@discountUser/DISCOUNT_USER_SAVE_REQUEST',
    payload: { data },
  };
}

export function discountUserSaveSuccess(data) {
  return {
    type: '@discountUser/DISCOUNT_USER_SAVE_SUCCESS',
    payload: { data },
  };
}

export function discountUserUpdateRequest(id, data) {
  return {
    type: '@discountUser/DISCOUNT_USER_UPDATE_REQUEST',
    payload: { id, data },
  };
}

export function discountUserUpdateSuccess() {
  return {
    type: '@discountUser/DISCOUNT_USER_UPDATE_SUCCESS',
  };
}

export function discountUserDeleteRequest(discount_user_id) {
  return {
    type: '@discountUser/DISCOUNT_USER_DELETE_REQUEST',
    payload: { discount_user_id },
  };
}
