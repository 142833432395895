import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useForm, FormContext } from 'react-hook-form';
import * as Yup from 'yup';
import { FiArrowLeft, FiPlusCircle } from 'react-icons/fi';

import AvatarInput from './AvatarInput';
import api from '~/services/api' ;
import { updateUserRequest } from '~/store/modules/user/actions';

const schema = Yup.object().shape({
  name: Yup.string()
    .required('Campo obrigatório.'),
  email: Yup.string()
    .email()
    .required('Campo obrigatório.'),
  roles: Yup.string()
    .required('Campo obrigatório.'),
});

export default function App() {
  const dispatch = useDispatch();
  const methods = useForm({validationSchema: schema});
  const { register, handleSubmit, errors } = methods;
  const [user, setUser] = useState({});


  const onSubmit = data => {
    dispatch(updateUserRequest(user.id, data));
  }

  const EditUser = () => {
    let { id } = useParams();
    return id;
  };
  const user_id = EditUser();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/user/${user_id}`);
        setUser(response.data);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="container">

      <div className="title">
        <h1>
          <FiPlusCircle size="19px"/> Edição de <span> usuário </span>
        </h1>
        <Link to="/users" className="button-add"><FiArrowLeft size="16px" color="#6159fd" />Voltar para listagem</Link>
      </div>

      <div className="painel">
        <FormContext {...methods}>

          <form onSubmit={handleSubmit(onSubmit)} className="manager">

            <h2>Preencha o formulário abaixo com os dados do usuário</h2>

            <div className="row row-space">

              <div className="col-2">
                <div className="input-group">
                  <AvatarInput />
                </div>
              </div>
            </div>

            <div className="row row-space">
              <div className="col-2">
                <div className="input-group">
                  <label htmlFor="name">Nome do usuário</label>
                  <input name="name" type="text" ref={register} defaultValue={user.name} placeholder="Ex.: Daniel " />
                  {errors.name && <span>{errors.name.message}</span>}
                </div>
              </div>
              <div className="col-2">
                <div className="input-group">
                  <label htmlFor="email">E-mail</label>
                  <input name="email" type="email" ref={register} defaultValue={user.email} placeholder="daniel@solbrasilenergia.com.br" />
                  {errors.email && <span>{errors.email.message}</span>}
                </div>
              </div>

            </div>

            <div className="row row-space">

              <div className="col-2">
                <div className="input-group">
                  <label htmlFor="password">Senha</label>
                  <input name="password" type="password" ref={register} placeholder="********" />
                  {errors.password && <span>{errors.password.message}</span>}
                </div>
              </div>

              <div className="col-2">
                <div className="input-group">
                  <label htmlFor="confirm_password">Confirmação de senha</label>
                  <input name="confirm_password" type="password" ref={register} placeholder="********" />
                  {errors.confirm_password && <span>{errors.confirm_password.message}</span>}
                </div>
              </div>

            </div>

            <div className="row row-space">

              <div className="col-2">
                <div className="input-group">
                  <label htmlFor="roles">Nível de acesso</label>
                  <select name="roles" ref={register} defaultValue={user.role_name} value={user.role_name}>
                    <option value="admin">Administrador</option>
                    <option value="user">Criar proposta</option>
                    <option value="agent">Representante</option>
                  </select>
                  {errors.roles && <span>{errors.roles.message}</span>}
                </div>
              </div>

            </div>

            <div className="row row-space">
              <div className="col-1">
                <div className="input-group">
                  <button type="submit">Salvar dados</button>
                </div>
              </div>
            </div>

          </form>
        </FormContext>
      </div>

    </div>
  )
}
