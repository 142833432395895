import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form'
import { default as NumberFormat } from 'react-number-format';

import { FiArrowLeft, FiPlusCircle } from 'react-icons/fi';

import api from '~/services/api' ;
import { discountRegionUpdateRequest } from '~/store/modules/discount_region/actions';


export default function EditDiscountRegion() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const { register, handleSubmit, errors } = useForm();
  const [discountRegion, setDiscountRegion] = useState([]);
  const [region, setRegion] = useState([]);

  const onChange = e => {
    setDiscountRegion({...discountRegion,
      [e.target.name]: e.target.value}
    );
  };

  const EditDiscountRegion = () => {
    let { id } = useParams();
    return id;
  };
  const discount_region_id = EditDiscountRegion();

  const onSubmit = data => {
    data.discount_cash_order = discountRegion.discount_cash_order;
    data.discount_cash_value_on_order = discountRegion.discount_cash_value_on_order;
    data.discount_cash_order_delivery = discountRegion.discount_cash_order_delivery;
    dispatch(discountRegionUpdateRequest(discount_region_id, data));
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/discount-regions/${discount_region_id}`);
        setDiscountRegion(response.data);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await api.get('/regions/results/all');
        setRegion(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="container">
      <div className="title">
        <h1>
          <FiPlusCircle size="19px"/> Editar <span> desconto por região </span>
        </h1>
        <Link to="/discount-regions" className="button-add"><FiArrowLeft size="16px" color="#6159fd" />Voltar para listagem</Link>
      </div>

      <div className="painel">

        <form onSubmit={handleSubmit(onSubmit)} className="manager">

          <h2>Preencha o formulário abaixo</h2>

          <div className="row row-space">

            <div className="col-1">
              <div className="input-group">
                <label htmlFor="region_id">Região</label>
                <select name="region_id" ref={register({ required: true })} value={discountRegion.region_id}  className="select capitalize"  onChange={onChange}>
                  <option value="" className="placeholder" selected disabled>Selecione a região</option>
                  { isLoading ? (
                    <option>Carregando</option>
                  ) : (
                    region.map((value, index) => {
                      return <option value={value.id} key={index}>{value.state} {value.name ? (` - ${value.name}`) : ' - Todo estado' }</option>
                    })
                  )}
                </select>
                {errors.region_id  && errors.region_id .type === 'required' && (
                  <span className="error-inputs">
                    Este campo é obrigatório
                  </span>
                )}
              </div>
            </div>
            
          </div>

          <div className="row row-space">

            <div className="col-3">
              <div className="input-group">
                <label htmlFor="discount_cash_order">Desconto valor à vista</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={0}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  isNumericString={true}
                  placeholder="Ex.: 13"
                  value={discountRegion.discount_cash_order}
                  onValueChange={(values) => {
                    const { value } = values;
                    setDiscountRegion({...discountRegion, discount_cash_order: value})}
                  }
                  getInputRef={(props) =>
                    <input name="discount_cash_order" type="text" value={discountRegion.discount_cash_order} ref={register}/>
                  }
                />
              </div>
            </div>

            <div className="col-3">
              <div className="input-group">
                <label htmlFor="discount_cash_value_on_order">Desconto valor à vista no pedido (%)</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={0}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  isNumericString={true}
                  placeholder="Ex.: 5"
                  value={discountRegion.discount_cash_value_on_order}
                  onValueChange={(values) => {
                    const { value } = values;
                    setDiscountRegion({...discountRegion, discount_cash_value_on_order: value})}
                  }
                  getInputRef={(props) =>
                    <input name="discount_cash_value_on_order" type="text" value={discountRegion.discount_cash_value_on_order} ref={register}/>
                  }
                />
              </div>
            </div>
            
            <div className="col-3">
              <div className="input-group">
                <label htmlFor="discount_cash_order_delivery">Desconto valor à vista na entrega equipamento (%)</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={0}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  isNumericString={true}
                  placeholder="Ex.: 8"
                  value={discountRegion.discount_cash_order_delivery}
                  onValueChange={(values) => {
                    const { value } = values;
                    setDiscountRegion({...discountRegion, discount_cash_order_delivery: value})}
                  }
                  getInputRef={(props) =>
                    <input name="discount_cash_order_delivery" type="text" value={discountRegion.discount_cash_order_delivery} ref={register}/>
                  }
                />
              </div>
            </div>

          </div> 

          <div className="row row-space">
            <div className="col-1">
              <div className="input-group">
                <button type="submit">Salvar dados</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

}
