import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form'
import { default as NumberFormat } from 'react-number-format';

import { FiArrowLeft, FiPlusCircle } from 'react-icons/fi';
import { temperatureSaveRequest } from '~/store/modules/temperature/actions';

export default function AddTemperature() {
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const [temperature, setTemperature] = useState([]);

  const onChange = e => {
    setTemperature({...temperature,
      [e.target.name]: e.target.value}
    );
  };

  function onSubmit(data) {
    dispatch(temperatureSaveRequest(temperature));
  }

  return (
    <div className="container">
      <div className="title">
        <h1>
          <FiPlusCircle size="19px"/> Cadastro de <span> temperaturas </span>
        </h1>
        <Link to="/temperatures" className="button-add"><FiArrowLeft size="16px" color="#6159fd" />Voltar para listagem</Link>
      </div>

      <div className="painel">
        <form className="manager" onSubmit={handleSubmit(onSubmit)}>

          <h2>Preencha o formulário abaixo</h2>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="potential">Estado (Sigla)</label>
                <input name="state" type="text" ref={register} placeholder="PR" onChange={onChange} />
              </div>
            </div>
            <div className="col-2">
              <div className="input-group">
                <label htmlFor="city">Cidade</label>
                <input name="city" type="text" ref={register} placeholder="Francisco Beltrão" onChange={onChange} />
              </div>
            </div>
          </div>

          <div className="row row-space">

            <div className="col-3">
              <div className="input-group">
                <label htmlFor="january">Janeiro</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  placeholder="Ex.: 0,00"
                  getInputRef={(props) =>
                    <input name="january" type="text" ref={register}/>
                  } onValueChange={(values) => {
                    const {value} = values;
                    setTemperature({...temperature, january: value})}
                  }
                />
              </div>
            </div>

            <div className="col-3">
              <div className="input-group">
                <label htmlFor="february">Fevereiro</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  placeholder="Ex.: 0,00"
                  getInputRef={(props) =>
                    <input name="february" type="text" ref={register}/>
                  } onValueChange={(values) => {
                    const {value} = values;
                    setTemperature({...temperature, february: value})}
                  }
                />
              </div>
            </div>

            <div className="col-3">
              <div className="input-group">
                <label htmlFor="march">Março</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  placeholder="Ex.: 0,00"
                  getInputRef={(props) =>
                    <input name="march" type="text" ref={register}/>
                  } onValueChange={(values) => {
                    const {value} = values;
                    setTemperature({...temperature, march: value})}
                  }
                />
              </div>
            </div>
          </div>

          <div className="row row-space">

            <div className="col-3">
              <div className="input-group">
                <label htmlFor="april">Abril</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  placeholder="Ex.: 0,00"
                  getInputRef={(props) =>
                    <input name="april" type="text" ref={register}/>
                  } onValueChange={(values) => {
                    const {value} = values;
                    setTemperature({...temperature, april: value})}
                  }
                />
              </div>
            </div>

            <div className="col-3">
              <div className="input-group">
                <label htmlFor="may">Maio</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  placeholder="Ex.: 0,00"
                  getInputRef={(props) =>
                    <input name="may" type="text" ref={register}/>
                  } onValueChange={(values) => {
                    const {value} = values;
                    setTemperature({...temperature, may: value})}
                  }
                />
              </div>
            </div>

            <div className="col-3">
              <div className="input-group">
                <label htmlFor="june">Junho</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  placeholder="Ex.: 0,00"
                  getInputRef={(props) =>
                    <input name="june" type="text" ref={register}/>
                  } onValueChange={(values) => {
                    const {value} = values;
                    setTemperature({...temperature, june: value})}
                  }
                />
              </div>
            </div>
          </div>

          <div className="row row-space">

            <div className="col-3">
              <div className="input-group">
                <label htmlFor="july">Julho</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  placeholder="Ex.: 0,00"
                  getInputRef={(props) =>
                    <input name="july" type="text" ref={register}/>
                  } onValueChange={(values) => {
                    const {value} = values;
                    setTemperature({...temperature, july: value})}
                  }
                />
              </div>
            </div>

            <div className="col-3">
              <div className="input-group">
                <label htmlFor="august">Agosto</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  placeholder="Ex.: 0,00"
                  getInputRef={(props) =>
                    <input name="august" type="text" ref={register}/>
                  } onValueChange={(values) => {
                    const {value} = values;
                    setTemperature({...temperature, august: value})}
                  }
                />
              </div>
            </div>

            <div className="col-3">
              <div className="input-group">
                <label htmlFor="september">Setembro</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  placeholder="Ex.: 0,00"
                  getInputRef={(props) =>
                    <input name="september" type="text" ref={register}/>
                  } onValueChange={(values) => {
                    const {value} = values;
                    setTemperature({...temperature, september: value})}
                  }
                />
              </div>
            </div>
          </div>

          <div className="row row-space">

            <div className="col-3">
              <div className="input-group">
                <label htmlFor="october">Outubro</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  placeholder="Ex.: 0,00"
                  getInputRef={(props) =>
                    <input name="october" type="text" ref={register}/>
                  } onValueChange={(values) => {
                    const {value} = values;
                    setTemperature({...temperature, october: value})}
                  }
                />
              </div>
            </div>

            <div className="col-3">
              <div className="input-group">
                <label htmlFor="november">Novembro</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  placeholder="Ex.: 0,00"
                  getInputRef={(props) =>
                    <input name="november" type="text" ref={register}/>
                  } onValueChange={(values) => {
                    const {value} = values;
                    setTemperature({...temperature, november: value})}
                  }
                />
              </div>
            </div>

            <div className="col-3">
              <div className="input-group">
                <label htmlFor="december">Dezembro</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  placeholder="Ex.: 0,00"
                  getInputRef={(props) =>
                    <input name="december" type="text" ref={register}/>
                  } onValueChange={(values) => {
                    const {value} = values;
                    setTemperature({...temperature, december: value})}
                  }
                />
              </div>
            </div>
          </div>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="tmin">Tmin</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  placeholder="Ex.: 0,00"
                  getInputRef={(props) =>
                    <input name="tmin" type="text" ref={register}/>
                  } onValueChange={(values) => {
                    const {value} = values;
                    setTemperature({...temperature, tmin: value})}
                  }
                />
              </div>
            </div>

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="tmaxabs">TmaxABS</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  placeholder="Ex.: 0,00"
                  getInputRef={(props) =>
                    <input name="tmaxabs" type="text" ref={register}/>
                  } onValueChange={(values) => {
                    const {value} = values;
                    setTemperature({...temperature, tmaxabs: value})}
                  }
                />
              </div>
            </div>

          </div>

          <div className="row row-space">
            <div className="col-1">
              <div className="input-group">
                <button type="submit">Salvar dados</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

}
