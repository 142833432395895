import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { cityRegionUpdateSuccess } from './actions';

export function* cityRegionSave({ payload }) {
  try {
    const response = yield call(api.post, 'cities-regions', payload.data);
    toast.success(response.data);
  } catch (error) {
    toast.error('Não foi possível salvar os dados. Contacte o administrador!');
  }
}

export function* cityRegionUpdate({ id, payload }) {
  try {
    const response = yield call (api.put, `cities-regions/${payload.id}`, payload.data);
    toast.success('Cidade atualizada com sucesso!');
    yield put(cityRegionUpdateSuccess(response.data));
  } catch (error) {
    toast.error('Não foi possível atualizar a cidade. Contacte o administrador.');
  }
}

export function* cityRegionDelete({ payload }) {
  try {
    yield call (api.delete, `cities-regions/${payload.city_region_id}`);
    toast.success('Cidade excluída com sucesso!');
  } catch (error) {
    toast.error('Não foi possível excluir a cidade. Contacte o administrador.');
  }
}

export default all([
  takeLatest('@cityRegion/CITY_REGION_SAVE_REQUEST', cityRegionSave),
  takeLatest('@cityRegion/CITY_REGION_UPDATE_REQUEST', cityRegionUpdate),
  takeLatest('@cityRegion/CITY_REGION_DELETE_REQUEST', cityRegionDelete),
]);
