import styled from 'styled-components';
import { device } from '~/styles/medias';

export const Wrapper = styled.div`
  height: 100%;
  margin: 40px auto;
  padding: 0 30px;
`;

export const GridColumns = styled.div`
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  margin-bottom: 40px;

  @media ${device.tabletM} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${device.tablet} {
    grid-template-columns: repeat(1, 1fr);
  }

`;

export const GridColumn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 120px;
  border-radius: 4px;
  padding-left: 20px;
  background: ${props => (props.background ? props.background : '#fff')};
  position: relative;

  svg {
    position: absolute;
    right: -30px;
    opacity: .30;
  }

  div {
    color: #fff;

    strong{
      font-size: 50px;
      line-height: 50px;
      font-weight: bold;
    }

    p{
      font-size: .9375rem;
      font-weight: bold;
      display: block;
    }
  }
`;
