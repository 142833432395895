import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form'
import { default as NumberFormat } from 'react-number-format';

import { FiArrowLeft, FiPlusCircle } from 'react-icons/fi';

import api from '~/services/api' ;
import { temperatureUpdateRequest } from '~/store/modules/temperature/actions';


export default function EditTemperature() {
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm([]);
  const [temperature, setTemperature] = useState({});

  const onSubmit = data => {
    dispatch(temperatureUpdateRequest(temperature.id, temperature));
  }

  const onChange = e => {
    setTemperature({...temperature,
      [e.target.name]: e.target.value}
    );
  };

  const EditTemperature = () => {
    let { id } = useParams();
    return id;
  };
  const temperature_id = EditTemperature();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/temperatures/${temperature_id}`);
        setTemperature(response.data);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="container">
      <div className="title">
        <h1>
          <FiPlusCircle size="19px"/> Editar <span> temperatura </span>
        </h1>
        <Link to="/temperatures" className="button-add"><FiArrowLeft size="16px" color="#6159fd" />Voltar para listagem</Link>
      </div>

      <div className="painel">

        <form onSubmit={handleSubmit(onSubmit)} className="manager">

          <h2>Preencha o formulário abaixo</h2>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="potential">Estado (Sigla)</label>
                <input name="state" type="text" ref={register} placeholder="PR" onChange={onChange} defaultValue={temperature.state} />
              </div>
            </div>
            <div className="col-2">
              <div className="input-group">
              <label htmlFor="city">Cidade</label>
                <input name="city" type="text" ref={register} placeholder="Copel" onChange={onChange} defaultValue={temperature.city} />
              </div>
            </div>
          </div>

          <div className="row row-space">
            <div className="col-3">
              <div className="input-group">
                <label htmlFor="january">Janeiro</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  isNumericString={true}
                  placeholder="Ex.: 0,00"
                  value={temperature.january}
                  onValueChange={(values) => {
                    const { value } = values;
                    setTemperature({...temperature, january: value})}
                  }
                  getInputRef={(props) =>
                    <input name="january" type="text" value={temperature.january} ref={register}/>
                  }
                />
              </div>
            </div>

            <div className="col-3">
              <div className="input-group">
                <label htmlFor="february">Fevereiro</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  isNumericString={true}
                  placeholder="Ex.: 0,00"
                  value={temperature.february}
                  onValueChange={(values) => {
                    const { value } = values;
                    setTemperature({...temperature, february: value})}
                  }
                  getInputRef={(props) =>
                    <input name="february" type="text" value={temperature.february} ref={register}/>
                  }
                />
              </div>
            </div>

            <div className="col-3">
              <div className="input-group">
                <label htmlFor="march">Março</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  isNumericString={true}
                  placeholder="Ex.: 0,00"
                  value={temperature.march}
                  onValueChange={(values) => {
                    const { value } = values;
                    setTemperature({...temperature, march: value})}
                  }
                  getInputRef={(props) =>
                    <input name="march" type="text" value={temperature.march} ref={register}/>
                  }
                />
              </div>
            </div>
          </div>

          <div className="row row-space">
            <div className="col-3">
              <div className="input-group">
                <label htmlFor="april">Abril</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  isNumericString={true}
                  placeholder="Ex.: 0,00"
                  value={temperature.april}
                  onValueChange={(values) => {
                    const { value } = values;
                    setTemperature({...temperature, april: value})}
                  }
                  getInputRef={(props) =>
                    <input name="april" type="text" value={temperature.april} ref={register}/>
                  }
                />
              </div>
            </div>

            <div className="col-3">
              <div className="input-group">
                <label htmlFor="may">Maio</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  isNumericString={true}
                  placeholder="Ex.: 0,00"
                  value={temperature.may}
                  onValueChange={(values) => {
                    const { value } = values;
                    setTemperature({...temperature, may: value})}
                  }
                  getInputRef={(props) =>
                    <input name="may" type="text" value={temperature.may} ref={register}/>
                  }
                />
              </div>
            </div>

            <div className="col-3">
              <div className="input-group">
                <label htmlFor="june">Junho</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  isNumericString={true}
                  placeholder="Ex.: 0,00"
                  value={temperature.june}
                  onValueChange={(values) => {
                    const { value } = values;
                    setTemperature({...temperature, june: value})}
                  }
                  getInputRef={(props) =>
                    <input name="june" type="text" value={temperature.june} ref={register}/>
                  }
                />
              </div>
            </div>
          </div>

          <div className="row row-space">
            <div className="col-3">
              <div className="input-group">
                <label htmlFor="july">Julho</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  isNumericString={true}
                  placeholder="Ex.: 0,00"
                  value={temperature.july}
                  onValueChange={(values) => {
                    const { value } = values;
                    setTemperature({...temperature, july: value})}
                  }
                  getInputRef={(props) =>
                    <input name="july" type="text" value={temperature.july} ref={register}/>
                  }
                />
              </div>
            </div>

            <div className="col-3">
              <div className="input-group">
                <label htmlFor="august">Agosto</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  isNumericString={true}
                  placeholder="Ex.: 0,00"
                  value={temperature.august}
                  onValueChange={(values) => {
                    const { value } = values;
                    setTemperature({...temperature, august: value})}
                  }
                  getInputRef={(props) =>
                    <input name="august" type="text" value={temperature.august} ref={register}/>
                  }
                />
              </div>
            </div>

            <div className="col-3">
              <div className="input-group">
                <label htmlFor="september">Março</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  isNumericString={true}
                  placeholder="Ex.: 0,00"
                  value={temperature.september}
                  onValueChange={(values) => {
                    const { value } = values;
                    setTemperature({...temperature, september: value})}
                  }
                  getInputRef={(props) =>
                    <input name="september" type="text" value={temperature.september} ref={register}/>
                  }
                />
              </div>
            </div>
          </div>

          <div className="row row-space">
            <div className="col-3">
              <div className="input-group">
                <label htmlFor="october">Outubro</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  isNumericString={true}
                  placeholder="Ex.: 0,00"
                  value={temperature.october}
                  onValueChange={(values) => {
                    const { value } = values;
                    setTemperature({...temperature, october: value})}
                  }
                  getInputRef={(props) =>
                    <input name="october" type="text" value={temperature.october} ref={register}/>
                  }
                />
              </div>
            </div>

            <div className="col-3">
              <div className="input-group">
                <label htmlFor="november">Novembro</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  isNumericString={true}
                  placeholder="Ex.: 0,00"
                  value={temperature.november}
                  onValueChange={(values) => {
                    const { value } = values;
                    setTemperature({...temperature, november: value})}
                  }
                  getInputRef={(props) =>
                    <input name="november" type="text" value={temperature.november} ref={register}/>
                  }
                />
              </div>
            </div>

            <div className="col-3">
              <div className="input-group">
                <label htmlFor="december">Dezembro</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  isNumericString={true}
                  placeholder="Ex.: 0,00"
                  value={temperature.december}
                  onValueChange={(values) => {
                    const { value } = values;
                    setTemperature({...temperature, december: value})}
                  }
                  getInputRef={(props) =>
                    <input name="december" type="text" value={temperature.december} ref={register}/>
                  }
                />
              </div>
            </div>
          </div>

          <div className="row row-space">
            <div className="col-2">
              <div className="input-group">
                <label htmlFor="tmin">Tmin</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  isNumericString={true}
                  placeholder="Ex.: 0,00"
                  value={temperature.tmin}
                  onValueChange={(values) => {
                    const { value } = values;
                    setTemperature({...temperature, tmin: value})}
                  }
                  getInputRef={(props) =>
                    <input name="tmin" type="text" value={temperature.tmin} ref={register}/>
                  }
                />
              </div>
            </div>

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="tmaxabs">TmaxABS</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  isNumericString={true}
                  placeholder="Ex.: 0,00"
                  value={temperature.tmaxabs}
                  onValueChange={(values) => {
                    const { value } = values;
                    setTemperature({...temperature, tmaxabs: value})}
                  }
                  getInputRef={(props) =>
                    <input name="tmaxabs" type="text" value={temperature.tmaxabs} ref={register}/>
                  }
                />
              </div>
            </div>
          </div>


          <div className="row row-space">
            <div className="col-1">
              <div className="input-group">
                <button type="submit">Salvar dados</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

}
