import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form'
import { default as NumberFormat } from 'react-number-format';

import { FiArrowLeft, FiPlusCircle } from 'react-icons/fi';

import api from '~/services/api' ;
import { paymentUpdateRequest } from '~/store/modules/payment/actions';


export default function EditPayment() {
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm([]);
  const [payment, setPayment] = useState({});

  const onSubmit = data => {
    dispatch(paymentUpdateRequest(payment.id, payment));
    // dispatch(paymentUpdateRequest(payment.id, data));
  }

  const onChange = e => {
    setPayment({...payment,
      [e.target.name]: e.target.value}
    );
  };

  const EditPayment = () => {
    let { id } = useParams();
    return id;
  };
  const payment_id = EditPayment();


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/payments/${payment_id}`);
        setPayment(response.data);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="container">
      <div className="title">
        <h1>
          <FiPlusCircle size="19px"/> Editar <span> forma de pagamento </span>
        </h1>
        <Link to="/payments" className="button-add"><FiArrowLeft size="16px" color="#6159fd" />Voltar para listagem</Link>
      </div>

      <div className="painel">

        <form onSubmit={handleSubmit(onSubmit)} className="manager">

          <h2>Preencha o formulário abaixo</h2>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="potential">Potencia KWp</label>
                <input name="potential" type="text" ref={register} placeholder="Ex.: 1.44 " onChange={onChange} defaultValue={payment.potential}  />
              </div>
            </div>
            <div className="col-2">
              <div className="input-group">
                <label htmlFor="middle_generation">Geração média KWp</label>
                <input name="middle_generation" type="text" ref={register} placeholder="Ex.: 180" onChange={onChange} defaultValue={payment.middle_generation}  />
              </div>
            </div>
          </div>

          <div className="row row-space">

            <div className="col-1">
              <div className="input-group">
                <label htmlFor="qtd_boards">Quantidade de placas</label>
                <input name="qtd_boards" type="text" ref={register} placeholder="Ex.: 4" onChange={onChange} defaultValue={payment.qtd_boards}  />
              </div>
            </div>

          </div>

          <div className="row row-space">

            <div className="col-3">
              <div className="input-group">
                <label htmlFor="sales_price">Preço de venda</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  isNumericString={true}
                  prefix={'R$ '}
                  placeholder="Ex.: R$ 0,00"
                  value={payment.sales_price}
                  onValueChange={(values) => {
                    const { value } = values;
                    setPayment({...payment, sales_price: value})}
                  }
                  getInputRef={(props) =>
                    <input name="sales_price" type="text" value={payment.sales_price} ref={register}/>
                  }
                />
              </div>
            </div>

            <div className="col-3">
              <div className="input-group">
                <label htmlFor="cash_price_advance">Preço à vista (adiant.)</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  isNumericString={true}
                  prefix={'R$ '}
                  placeholder="Ex.: R$ 0,00"
                  value={payment.cash_price_advance}
                  onValueChange={(values) => {
                    const { value } = values;
                    setPayment({...payment, cash_price_advance: value})}
                  }
                  getInputRef={(props) =>
                    <input name="cash_price_advance" type="text" value={payment.cash_price_advance} ref={register}/>
                  }
                />
              </div>
            </div>

            <div className="col-3">
              <div className="input-group">
                <label htmlFor="cash_price_delivery">Preço à vista (entrega)</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  isNumericString={true}
                  prefix={'R$ '}
                  placeholder="Ex.: R$ 0,00"
                  value={payment.cash_price_delivery}
                  onValueChange={(values) => {
                    const { value } = values;
                    setPayment({...payment, cash_price_delivery: value})}
                  }
                  getInputRef={(props) =>
                    <input name="cash_price_delivery" type="text" value={payment.cash_price_delivery} ref={register}/>
                  }
                />
              </div>
            </div>

          </div>

          <h2>Preço venda - Sol Brasil</h2>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="price_in_10">10x sem juros</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  isNumericString={true}
                  prefix={'R$ '}
                  placeholder="Ex.: R$ 0,00"
                  value={payment.price_in_10}
                  onValueChange={(values) => {
                    const { value } = values;
                    setPayment({...payment, price_in_10: value})}
                  }
                  getInputRef={(props) =>
                    <input name="price_in_10" type="text" value={payment.price_in_10} ref={register}/>
                  }
                />
              </div>
            </div>

          </div>

          <h2>Preço financiadoras</h2>

          <div className="row row-space">

            <div className="col-3">
              <div className="input-group">
                <label htmlFor="price_in_36">36x</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  isNumericString={true}
                  prefix={'R$ '}
                  placeholder="Ex.: R$ 0,00"
                  value={payment.price_in_36}
                  onValueChange={(values) => {
                    const { value } = values;
                    setPayment({...payment, price_in_36: value})}
                  }
                  getInputRef={(props) =>
                    <input name="price_in_36" type="text" value={payment.price_in_36} ref={register}/>
                  }
                />
              </div>
            </div>

            <div className="col-3">
              <div className="input-group">
                <label htmlFor="price_in_48">48x</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  isNumericString={true}
                  prefix={'R$ '}
                  placeholder="Ex.: R$ 0,00"
                  value={payment.price_in_48}
                  onValueChange={(values) => {
                    const { value } = values;
                    setPayment({...payment, price_in_48: value})}
                  }
                  getInputRef={(props) =>
                    <input name="price_in_48" type="text" value={payment.price_in_48} ref={register}/>
                  }
                />
              </div>
            </div>

            <div className="col-3">
              <div className="input-group">
                <label htmlFor="price_in_60">60x</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  isNumericString={true}
                  prefix={'R$ '}
                  placeholder="Ex.: R$ 0,00"
                  value={payment.price_in_60}
                  onValueChange={(values) => {
                    const { value } = values;
                    setPayment({...payment, price_in_60: value})}
                  }
                  getInputRef={(props) =>
                    <input name="price_in_60" type="text" value={payment.price_in_60} ref={register}/>
                  }
                />
              </div>
            </div>

          </div>

          <div className="row row-space">
           <div className="col-3">
              <div className="input-group">
                <label htmlFor="price_in_72">72x</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  isNumericString={true}
                  prefix={'R$ '}
                  placeholder="Ex.: R$ 0,00"
                  value={payment.price_in_72}
                  onValueChange={(values) => {
                    const { value } = values;
                    setPayment({...payment, price_in_72: value})}
                  }
                  getInputRef={(props) =>
                    <input name="price_in_72" type="text" value={payment.price_in_72} ref={register}/>
                  }
                />
              </div>
            </div>
          </div>

          <h2>Tipo tabela de preço</h2>

          <div className="row row-space">

            <div className="col-1">
              <div className="input-group">
                <label htmlFor="price_type">Tabela de preço</label>
                <select name="price_type" ref={register} defaultValue={payment.price_type} value={payment.price_type}  onChange={onChange}  >
                  <option value="">Selecione o tipo do preço</option>
                  <option value="0">Padrão</option>
                  <option value="1">Especial</option>
                  <option value="2">Plus</option>
                </select>
              </div>
            </div>

          </div>

          <div className="row row-space">
            <div className="col-1">
              <div className="input-group">
                <button type="submit">Salvar dados</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

}
