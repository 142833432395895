import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useForm, FormContext } from 'react-hook-form';
import { FiArrowLeft, FiSettings } from 'react-icons/fi';
import { default as NumberFormat } from 'react-number-format';

import ReportImage from './ReportInputCapa';
import api from '~/services/api' ;
import { settingsUpdateRequest } from '~/store/modules/setting/actions';

export default function UpdateSettings() {
  const dispatch = useDispatch();

  const methods = useForm();
  const { register, handleSubmit } = methods;
  const [setting, setSetting] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const onSubmit = data => {
    data.discount_cash_order = setting.discount_cash_order;
    data.discount_cash_value_on_order = setting.discount_cash_value_on_order;
    data.discount_cash_order_delivery = setting.discount_cash_order_delivery;
    data.ten_plots_show = setting.ten_plots_show;
    data.seventy_two_plots_show = setting.seventy_two_plots_show;
    dispatch(settingsUpdateRequest(data));
  }

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await api.get(`settings`);
      setSetting(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log('Error: '+error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onChange = e => {
    console.log(e.target.value);
    setSetting({...setting,
      [e.target.name]: e.target.value}
    );

  };

  return (
    <div className="container">
      <div className="title">
        <h1>
          <FiSettings size="19px"/> Configurações
        </h1>
        <Link to="/dashboard" className="button-add"><FiArrowLeft size="16px" color="#6159fd" />Voltar para dashboard</Link>
      </div>

      <div className="painel">
        <FormContext {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} className="manager">

            <h2>Adicionar nova imagem</h2>

            { isLoading ? (
              <option>Carregando</option>
            ) : (
              <>
                <div className="row row-space">

                  <div className="col-2">
                    <div className="input-group">
                      <ReportImage />
                    </div>
                  </div>

                </div>

                <div className="row row-space">

                  <div className="col-3">
                    <div className="input-group">
                      <label htmlFor="discount_cash_order">Desconto valor à vista</label>
                      <NumberFormat
                        decimalSeparator={","}
                        decimalScale={0}
                        fixedDecimalScale={true}
                        thousandSeparator={"."}
                        isNumericString={true}
                        placeholder="Ex.: 13"
                        value={setting.discount_cash_order}
                        onValueChange={(values) => {
                          const { value } = values;
                          setSetting({...setting, discount_cash_order: value})}
                        }
                        getInputRef={(props) =>
                          <input name="discount_cash_order" type="text" value={setting.discount_cash_order} ref={register}/>
                        }
                      />
                    </div>
                  </div>

                  <div className="col-3">
                    <div className="input-group">
                      <label htmlFor="discount_cash_value_on_order">Desconto valor à vista no pedido (%)</label>
                      <NumberFormat
                        decimalSeparator={","}
                        decimalScale={0}
                        fixedDecimalScale={true}
                        thousandSeparator={"."}
                        isNumericString={true}
                        placeholder="Ex.: 5"
                        value={setting.discount_cash_value_on_order}
                        onValueChange={(values) => {
                          const { value } = values;
                          setSetting({...setting, discount_cash_value_on_order: value})}
                        }
                        getInputRef={(props) =>
                          <input name="discount_cash_value_on_order" type="text" value={setting.discount_cash_value_on_order} ref={register}/>
                        }
                      />
                    </div>
                  </div>
                  
                  <div className="col-3">
                    <div className="input-group">
                      <label htmlFor="discount_cash_order_delivery">Desconto valor à vista na entrega equipamento (%)</label>
                      <NumberFormat
                        decimalSeparator={","}
                        decimalScale={0}
                        fixedDecimalScale={true}
                        thousandSeparator={"."}
                        isNumericString={true}
                        placeholder="Ex.: 8"
                        value={setting.discount_cash_order_delivery}
                        onValueChange={(values) => {
                          const { value } = values;
                          setSetting({...setting, discount_cash_order_delivery: value})}
                        }
                        getInputRef={(props) =>
                          <input name="discount_cash_order_delivery" type="text" value={setting.discount_cash_order_delivery} ref={register}/>
                        }
                      />
                    </div>
                  </div>

                </div>  

                <div className="row row-space">

                  <div className="col-2">
                    <div className="input-group check">
                      <div className="check-container check">
                        <ul className="custom-radio">
                          <li>
                            <label>
                              <input
                                type="radio"
                                value="true"
                                name="discount_friend_show"
                                ref={register}
                                defaultChecked={setting.discount_friend_show === 'true'}
                                onChange={onChange}
                              />
                              Ativar promoção de desconto amigo.
                            </label>
                          </li>

                          <li>
                            <label>
                              <input
                                type="radio"
                                value="false"
                                name="discount_friend_show"
                                ref={register}
                                defaultChecked={setting.discount_friend_show === 'false'}
                                onClick={onChange}
                              />
                              Desativar promoção de desconto amigo.
                            </label>
                          </li>

                        </ul>

                      </div>
                    </div>
                  </div>
                  { setting.discount_friend_show === 'true' ? (
                    <div className="col-2">
                      <div className="input-group">
                        <label htmlFor="discount_friend_value">À vista promoção desconto amigo em(%)</label>
                        <NumberFormat
                          decimalSeparator={","}
                          decimalScale={0}
                          fixedDecimalScale={true}
                          thousandSeparator={"."}
                          isNumericString={true}
                          placeholder="Ex.: 15"
                          value={setting.discount_friend_value}
                          onValueChange={(values) => {
                            const { value } = values;
                            setSetting({...setting, discount_friend_value: value})}
                          }
                          getInputRef={(props) =>
                            <input name="discount_friend_value" type="text" value={setting.discount_friend_value} ref={register}/>
                          }
                        />
                      </div>
                    </div>  
                  ) : (
                    <></>
                  )}
                </div>  

                <div className="col-2">
                  <div className="input-group check">
                    <div className="check-container check">
                      <ul className="custom-radio">
                        <li>
                          <label>
                            <input
                              type="radio"
                              value="true"
                              name="ten_plots_show"
                              ref={register}
                              defaultChecked={setting.ten_plots_show === 'true'}
                              onChange={onChange}
                            />
                            Mostrar 10x sem juros.
                          </label>
                        </li>

                        <li>
                          <label>
                            <input
                              type="radio"
                              value="false"
                              name="ten_plots_show"
                              ref={register}
                              defaultChecked={setting.ten_plots_show === 'false'}
                              onClick={onChange}
                            />
                            Ocultar 10x sem juros.
                          </label>
                        </li>

                      </ul>

                    </div>
                  </div>
                </div>


                <div className="col-2">
                  <div className="input-group check">
                    <div className="check-container check">
                      <ul className="custom-radio">
                        <li>
                          <label>
                            <input
                              type="radio"
                              value="true"
                              name="seventy_two_plots_show"
                              ref={register}
                              defaultChecked={setting.seventy_two_plots_show === 'true'}
                              onChange={onChange}
                            />
                            Mostrar 72x sem juros.
                          </label>
                        </li>

                        <li>
                          <label>
                            <input
                              type="radio"
                              value="false"
                              name="seventy_two_plots_show"
                              ref={register}
                              defaultChecked={setting.seventy_two_plots_show === 'false'}
                              onClick={onChange}
                            />
                            Ocultar 72x sem juros.
                          </label>
                        </li>

                      </ul>

                    </div>
                  </div>
                </div>

              </>
            )}

            <div className="row row-space">
              <div className="col-1">
                <div className="input-group">
                  <button type="submit">Salvar dados</button>
                </div>
              </div>
            </div>

          </form>
        </FormContext>
      </div>
    </div>
  );

}
