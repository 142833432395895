import React, { useState, useEffect  } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdFirstPage, MdLastPage, MdSearch } from 'react-icons/md';
import { FiUserCheck, FiPlus, FiEdit, FiTrash, FiX  } from 'react-icons/fi';
import { ImSpinner11 } from 'react-icons/im';
import Pagination from "react-js-pagination";

import api from '~/services/api' ;
import { temperatureDeleteRequest } from '~/store/modules/temperature/actions';

export default function Temperatures() {
  const dispatch = useDispatch();
  const [temperature, setTemperature] = useState([]);
  const [searchInput, setSearchInput] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async (pageNumber = 1) => {
    setIsLoading(true);
    try {
      const response = await api.get(`temperatures?page=${pageNumber}`);
      setTemperature(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log('Error: '+error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const setSearch = e => {
    setSearchInput(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  }

  async function handleSearch(){
    setIsLoading(true);
    if (searchInput !== '') {
      try {
        const response = await api.get(`temperatures/search/${searchInput}`);
        setTemperature(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log(`Error: ${error}`);
      }
    } else {
      fetchData();
    }
  }

  function deleteTemperature(temperature_id, temperature_name) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui-confirm-delete'>
            <div className="title">Confirma a exclusão da temperatura: <strong>{temperature_name}</strong>? </div>
            <button onClick={onClose}><FiX size="18px"/> Cancelar exclusão</button>
            <button
              className="yes"
              onClick={() => {
                handleClickDelete(temperature_id);
                onClose();
                fetchData();
              }}
            >
              <FiTrash size="18px"/> Sim, pode excluir!
            </button>
          </div>
        );
      }
    });
  }

  function handleClickDelete(temperature) {
    dispatch(temperatureDeleteRequest(temperature));
  }

  return (
    <div className="container">
      <div className="title">
        <h1>
          <FiUserCheck size="19px"/> Listagem de <span> temperaturas </span>
        </h1>
        <Link to="/create-temperature" className="button-add"><FiPlus size="16px" color="#6159fd" />Cadastrar temperatura</Link>
      </div>

      <div className="painel">
        <div className="header-search">
          <div className="search-table">
            <div className="control">
              <input
                className="input-group search"
                type="text"
                placeholder="Pesquisar"
                autoComplete="off"
                onChange={setSearch}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div className="control">
              <a
                className="button search"
                onClick={handleSearch}
                >
                <MdSearch
                  size={20}
                  color="#a9a9a9"
                />
              </a>
            </div>
          </div>
          <div>
            <span className="total-register">Total de registros: <strong>{temperature.total}</strong></span>
          </div>
        </div>
        {isLoading ? (
          <div className="loading">
          <ImSpinner11
            size={24}
            color="#0A203F"
            className="fa-spin"
          /> Carregando ...</div>
        ) : (
          <>
            <table className="table is-striped">
              <thead>
                <tr>
                  <th width="80" className="text-center">Código</th>
                  <th>Estado</th>
                  <th>Cidade</th>
                  <th>Tmin</th>
                  <th>TmaxABS</th>
                  <th width="180" className="text-center">Ações</th>
                </tr>
              </thead>
              {temperature.data.length === 0 ? (
                <tbody>
                  <tr>
                    <td colSpan="5" className="text-center">Nenhum Registro encontrado :(</td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {temperature.data.map((value, index) => (
                    <tr key={value.id}>
                      <td key={value.id}>{value.id}</td>
                      <td>{value.state}</td>
                      <td>{value.city}</td>
                      <td>{value.tmin}</td>
                      <td>{value.tmaxabs}</td>
                      <td width="250" className="text-center">
                        <div className="button-action">
                          <Link to={`/edit-temperature/${value.id}`} className="button-edit"><FiEdit size="18px"/>Editar</Link>
                          <button onClick={() => deleteTemperature(value.id, value.state)} className="button-delete"><FiTrash size="18px"/>Excluir</button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
            <Pagination
              activePage={temperature.current_page}
              itemsCountPerPage={temperature.per_page}
              totalItemsCount={temperature.total}
              onChange={(pageNumber) => fetchData(pageNumber)}
              prevPageText={<MdKeyboardArrowLeft size="18px" color="#A3AEB7" />}
              firstPageText={<MdFirstPage size="18px" color="#A3AEB7" />}
              nextPageText={<MdKeyboardArrowRight size="18px" color="#A3AEB7" />}
              lastPageText={<MdLastPage size="18px" color="#A3AEB7" />}
            />
          </>
        )}
      </div>
    </div>
  );

}
