import styled from 'styled-components'

export const Container = styled.div`
  background: #09203d;
  width: 100%;
`

export const TopHeader = styled.div`
  height: 80px;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  aside {
    display: flex;
    align-items: center;

    .settings {
      display: inline-block;
      margin: 0 0 0 20px;
      height: 40px;
      line-height: 48px;
      padding-left: 20px;
    }
  }
`

export const Profile = styled.div`
  display: flex;
  margin-left: 20px;
  padding-left: 20px;
  border-left: 1px solid #3a3e44;

  div {
    text-align: right;
    margin-right: 10px;

    strong {
      display: block;
      color: #fff;
    }

    a {
      display: block;
      margin-top: 2px;
      font-size: 12px;
      color: #999;
    }
  }
  img {
    height: 40px;
    border-radius: 50%;
  }
`

export const Navbar = styled.div`
  width: 100%;
  background: #fff;
  box-shadow: 0 0 14px 0 rgba(86, 61, 124, .13);
  height: 3.5rem;
  line-height: 3.5rem;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;

  ul {
    margin: 0;
    position: relative;

    li {
      display: inline;
      padding: 0 15px 0 0;
      border-right: 1px solid #eee;
      margin-right: 15px;

      float: left;
      -webkit-transition: all .2s ease-in-out;
      transition: all .2s ease-in-out;

      &:last-child {
        border: 0;
      }

      a {
        font-size: .9rem;
        color: #584475;

        svg {
          margin-right: 5px;
          position: relative;
          top: 3px;
        }

        &:hover {
          color: #09203d;
        }

        &.button-budget {
          height: 40px;
          line-height: 40px;
          background-color: #28a745;
          font-weight: bold;
          color: #fff;
          text-transform: uppercase;
          border-radius: 4px;
          font-size: 14px;
          transition: background-color 0.2s;
          padding: 10px 15px;

          svg {
            margin-top: -3px;
            margin-right: 5px;
          }

          &:hover,
          &:focus,
          &:active {
            background-color: #1f8035;
          }
        }
      }

      &:hover > ul {
        display: block;
        margin-top: 56px;
        margin-left: -16px;
      }

      ul {
        display: none;
        background: #fff;
        position: absolute; 
        top: 100%;
        box-shadow: -3px 3px 10px -2px rgba(0,0,0,.1);
        border: 1px solid rgba(0,0,0,.1);
        z-index: 10;

        li {
          float: none;
          position: relative;
          display: block;
          border: none;
          width: 100%;
          padding: 0;

          a {
            display: block;
            padding: 0px 25px;
            border-bottom: 1px solid rgba(0,0,0,.05);
            width: 100%;
          }

        }
      }
    }
  }

  .logout {
    display: inline;
    font-size: .9rem;
    color: #584475;

    svg {
      margin-right: 5px;
      position: relative;
      top: 3px;
    }
  }
`
