export function settingsUpdateRequest(data) {
  return {
    type: '@settings/SETTING_REQUEST',
    payload: { data },
  };
}

export function settingsUpdateSuccess(data) {
  return {
    type: '@settings/SETTING_SUCCESS',
    payload: { data },
  };
}


export function settingsFailure(data) {
  return {
    type: '@settings/SETTING_FAILURE',
  };
}
