import React, { useState, useEffect  } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { FiUserCheck, FiArrowLeft, FiList, FiUpload } from 'react-icons/fi';
import { ImSpinner11 } from 'react-icons/im';

import api from '~/services/api' ;

export default function Payments() {
  const dispatch = useDispatch();
  const [payment, setPayment] = useState([]);
  const [searchInput, setSearchInput] = useState();
  const [firstItemArray, setFirstItemArray] = useState();
  const [lastItemArray, setLastItemArray] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const PriceState = () => {
    let { price_state } = useParams();
    return price_state;
  };
  const price_state = PriceState();
  
  const BoardId = () => {
    let { board_id } = useParams();
    return board_id;
  };
  const board_id = BoardId();
  
  const UserId = () => {
    let { user_id } = useParams();
    return user_id;
  };
  const user_id = UserId();
  
  const RegionId = () => {
    let { region_id } = useParams();
    return region_id;
  };
  const region_id = RegionId();

  const fetchData = async (pageNumber = 1) => {
    setIsLoading(true);
    try {
      const response = await api.get(`payments/list-prices-types/${board_id}/${price_state}/${user_id}/${region_id}`);
      var firstItem = response.data[0].id;
			var lastItem = response.data[response.data.length-1].id;  
			setFirstItemArray(firstItem);
			setLastItemArray(lastItem);
      setPayment(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log('Error: '+error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const setSearch = e => {
    setSearchInput(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  }

  async function handleSearch(){
    setIsLoading(true);
    if (searchInput !== '') {
      try {
        const response = await api.get(`/payments/list-prices/${searchInput}`);
        setPayment(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log(`Error: ${error}`);
      }
    } else {
      fetchData();
    }
  }

  return (
    <div className="container">
      <div className="title">
        <h1>
          <FiUserCheck size="19px"/> Listagem de <span> forma de pagamento </span>
        </h1>
        <Link to="/payments" className="button-add"><FiArrowLeft size="16px" color="#6159fd" />Voltar para listagem</Link>
        <Link to={`/update-payments/${firstItemArray}/${lastItemArray}`} className="button-import"><FiUpload size="16px" color="#ffffff" />Atualizar forma de pagamento</Link>
      </div>

      <div className="painel">
        {isLoading ? (
          <div className="loading">
          <ImSpinner11
            size={24}
            color="#0A203F"
            className="fa-spin"
          /> Carregando ...</div>
        ) : (
          <>
            <table className="table is-striped">
              <thead>
                <tr>
                  <th>Placa</th>
                  <th>Potência</th>
                  <th>Geração média KWp</th>
                  <th>Qtde Placas</th>
                  <th>Tabela de preço</th>
                  <th width="180" className="text-center">Ações</th>
                </tr>
              </thead>
              {payment.length === 0 ? (
                <tbody>
                  <tr>
                    <td colSpan="5" className="text-center">Nenhum Registro encontrado :(</td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {payment.map((value, index) => (
                    <tr key={value.id}>
                      <td>{value.board_name}</td>
                      <td>{value.potential}</td>
                      <td>{value.middle_generation}</td>
                      <td>{value.qtd_boards}</td>
                      <td>{value.price_type === '0' ? "Padrão" : "Especial"}</td>
                      <td>
                      <Link to={`/edit-payment/${value.id}`} className="button-edit"><FiList size="18px"/>Ver valores</Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </>
        )}
				</div>
			</div>
		);
	
	}