import React from 'react';

import { 
  HorizontalPositionAlign,
  HorizontalPositionRelativeFrom,
  VerticalPositionAlign,
  VerticalPositionRelativeFrom,
  Document, 
  Footer, 
  Header, 
  Media, 
  Paragraph,
  HeadingLevel,
  TextRun,
  Table, 
  TableCell, 
  TableRow,
  VerticalAlign,
  WidthType,
  AlignmentType,
  BorderStyle,
  TableAnchorType,
  RelativeHorizontalPosition,
  RelativeVerticalPosition,
  OverlapType,
  TableLayoutType,
} from "docx";

import logo_base64 from '~/assets/logo-relatorio.json';
import selo_base64 from '~/assets/selo.json';
import bg_rel_base64 from '~/assets/bg-relatorio.json';
import bg_servicos_base64 from '~/assets/bg-servicos-descricao.json';
import bg_servicos_equipamentos_base64 from '~/assets/bg-servicos-equipamentos.json';
import bg_servicos_servicos_base64 from '~/assets/bg-servicos-servicos.json';
import bg_servicos_garantia_base64 from '~/assets/bg-servicos-garantia.json';
import bg_servicos_geracao_estimada_base64 from '~/assets/bg-servicos-geracao-estimada.json';
import bg_servicos_economia_estimada_base64 from '~/assets/bg-servicos-economia-estimada.json';
import bg_servicos_preco_gerador_base64 from '~/assets/bg-servicos-preco.json';
import bg_servicos_viabilidade_base64 from '~/assets/bg-servicos-viabilidade.json';
import bg_servicos_pagamento_base64 from '~/assets/bg-servicos-pagamento.json';
import bg_servicos_validade_proposta_base64 from '~/assets/bg-servicos-validade-proposta.json';
import bg_servicos_footer from '~/assets/bg-servicos-footer.jpg';

export function GenerateDoc(data, state) {
  var datePT_BR;
  if (typeof state.created_at !== 'undefined') {
    var created_at = state.created_at;
    var retorno = created_at.split(" ");
    var newDate = retorno[0].split("-");
    var objDate = new Date(newDate[1] +"/"+ newDate[2] +"/"+ newDate[0]),
    locale = "pt-br",
    month = objDate.toLocaleString(locale, { month: "long" });
    datePT_BR = newDate[2] +" de "+ month +" de "+ newDate[0] +" às " + retorno[1];
  } else {
    var dt = new Date();
    var dia = dt.getDate();
    var mes = dt.getMonth();
    var ano = dt.getFullYear();
    var hora = dt.getHours();
    var min = (dt.getMinutes() < 10 ? '0' : '') + dt.getMinutes();
    locale = "pt-br";
    month = dt.toLocaleString(locale, { month: "long" });
    // datePT_BR = dia +" de "+ month +" de "+ ano +" às " + hora+":"+min;
    datePT_BR = dia +" de "+ month +" de "+ ano;
  }

  const doc = new Document({
    styles: {
      paragraphStyles: [
        {
          id: "Heading1",
          name: "Heading 1",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          run: {
            font: "Calibri-Bold",
            size: 40,
            bold: true,
            color: "#001D3A",
          },
          paragraph: {
            spacing: {
              after: 120,
            },
          },
        },
        {
          id: "Heading2",
          name: "Heading 2",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          run: {
            font: "Calibri-Bold",
            size: 22,
            bold: true,
            color: "#001D3A",
          },
          paragraph: {
            spacing: {
              after: 0,
            },
          },
        },
        {
          id: "Heading3",
          name: "Heading 3",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          run: {
            font: "Calibri-Bold",
            size: 36,
            bold: true,
            color: "#001D3A",
          },
          paragraph: {
            spacing: {
              before: 600,
              after: 0,
            },
          },
        },
        {
          id: "Heading5",
          name: "Heading 5",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          run: {
            font: "Calibri-Bold",
            size: 18,
            bold: true,
            color: "#001D3A",
          },
          paragraph: {
            spacing: {
              before: 0,
              after: 150,
            },
          },
        },
        {
          id: "Heading6",
          name: "Heading 6",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          run: {
            font: "Calibri",
            size: 18,
            color: "#001D3A",
          },
          paragraph: {
            spacing: {
              before: 0,
              after: 150,
            },
          },
        },
        {
          id: "TituloGarantiaEficiendia",
          name: "TituloGarantiaEficiendia",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          run: {
            font: "Calibri-Bold",
            size: 36,
            bold: true,
            color: "#001D3A",
          },
          paragraph: {
            spacing: {
              before: 0,
              after: 0,
            },
          },
        },
        {
          id: "TextoInfoGeracao",
          name: "TextoInfoGeracao",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          run: {
            font: "Calibri",
            size: 10,
            bold: false,
            color: "#333333",
          },
          paragraph: {
            spacing: {
              before: 0,
              after: 0,
            },
          },
        },
        {
          id: "TituloTable",
          name: "TituloTable",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          run: {
            font: "Calibri",
            size: 18,
            bold: true,
            color: "#001D3A",
          },
          paragraph: {
            spacing: {
              before: 0,
              after: 150,
            },
          },
        },
        {
          id: "ReagusteAnual",
          name: "ReagusteAnual",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          run: {
            font: "Calibri",
            size: 22,
            bold: true,
            color: "#000000",
          },
          paragraph: {
            spacing: {
              before: 20,
              after: 0,
            },
          },
        },
        {
          id: "SeguroAnual",
          name: "SeguroAnual",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          run: {
            font: "Calibri-Bold",
            size: 22,
            bold: false,
            color: "#001D3A",
          },
          paragraph: {
            spacing: {
              before: 0,
              after: 0,
            },
          },
        },
        {
          id: "DadosEconomicosBold",
          name: "DadosEconomicosBold",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          run: {
            font: "Calibri-Bold",
            size: 18,
            bold: true,
            color: "#000000",
          },
          paragraph: {
            spacing: {
              before: 0,
              after: 0,
            },
          },
        },
        {
          id: "DadosEconomicos",
          name: "DadosEconomicos",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          run: {
            font: "Calibri-Bold",
            size: 18,
            bold: false,
            color: "#000000",
          },
          paragraph: {
            spacing: {
              before: 0,
              after: 0,
            },
          },
        },
        {
          id: "TituloRetornoInvestimento",
          name: "TituloRetornoInvestimento",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          run: {
            font: "Calibri-Bold",
            size: 24,
            bold: true,
            color: "#000000",
          },
          paragraph: {
            spacing: {
              before: 0,
              after: 0,
            },
          },
        },
        {
          id: "TextoValidadeProposta",
          name: "TextoValidadeProposta",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          run: {
            font: "Calibri-Bold",
            size: 30,
            bold: true,
            color: "#000000",
            position: "absolute",
            bottom: 0
          },
          paragraph: {
            spacing: {
              before: 0,
              after: 0,
            },
          },
        },
        {
          id: "TituloTableRetornoInvestimento",
          name: "TituloTableRetornoInvestimento",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          run: {
            font: "Calibri-Bold",
            size: 15,
            bold: true,
            color: "#000000",
          },
          paragraph: {
            spacing: {
              before: 0,
              after: 0,
            },
          },
        },
        {
          id: "TextoTableRetornoInvestimento",
          name: "TextoTableRetornoInvestimento",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          run: {
            font: "Calibri",
            size: 15,
            bold: false,
            color: "#000000",
          },
          paragraph: {
            spacing: {
              before: 0,
              after: 0,
            },
          },
        },
      ],
    },
  });

  const logo_ok = Media.addImage(doc, Buffer.from(logo_base64.imagem, "base64"), 180, 45, {
    floating: {
      horizontalPosition: {
        relative: HorizontalPositionRelativeFrom.PAGE,
        align: HorizontalPositionAlign.RIGHT,
      },
      verticalPosition: {
        relative: VerticalPositionRelativeFrom.PAGE,
        align: VerticalPositionAlign.CENTER,
      },
    },
  });
  const bg_rel_ok = Media.addImage(doc, Buffer.from(bg_rel_base64.imagem, "base64"), 800, 1130, {
    floating: {
      horizontalPosition: {
        offset: 0,
      },
      verticalPosition: {
        offset: 0,
      },
    },
  });
  const logo_footer_ok = Media.addImage(doc, Buffer.from(logo_base64.imagem, "base64"), 80, 20, {
    floating: {
      horizontalPosition: {
        offset: 5800000,
      },
      verticalPosition: {
        offset: 10200000,
      },
    },
  });
  const bg_servicos_footer_OK = Media.addImage(doc, bg_servicos_footer, 800, 72, {
    floating: {
      horizontalPosition: {
        relative: HorizontalPositionRelativeFrom.PAGE,
        align: HorizontalPositionAlign.LEFT,
      },
      verticalPosition: {
        relative: VerticalPositionRelativeFrom.PAGE,
        align: VerticalPositionAlign.BOTTOM,
      },
    },
  });
  const bg_servicos_ok = Media.addImage(doc, Buffer.from(bg_servicos_base64.imagem, "base64"), 650, 38, {
    floating: {
      horizontalPosition: {
        offset: 400000,
      },
      verticalPosition: {
        offset: 2300000,
      },
      margins: {
        left: 4000,
      },
    },
  });
  const bg_equipamentos_ok = Media.addImage(doc, Buffer.from(bg_servicos_equipamentos_base64.imagem, "base64"), 650, 38, {
    floating: {
      horizontalPosition: {
        offset: 400000,
      },
      verticalPosition: {
        offset: 4500000,
      },
      margins: {
        left: 4000,
      },
    },
  });
  const bg_servicos_servicos_ok = Media.addImage(doc, Buffer.from(bg_servicos_servicos_base64.imagem, "base64"), 650, 38, {
    floating: {
      horizontalPosition: {
        offset: 400000,
      },
      verticalPosition: {
        offset: 6200000,
      },
      margins: {
        left: 4000,
      },
    },
  });
  
  const bg_servicos_garantia_ok = Media.addImage(doc, Buffer.from(bg_servicos_garantia_base64.imagem, "base64"), 650, 38, {
    floating: {
      horizontalPosition: {
        offset: 400000,
      },
      verticalPosition: {
        offset: 8100000,
      },
      margins: {
        left: 4000,
      },
    },
  });
  
  const bg_servicos_geracao_estimada_ok = Media.addImage(doc, Buffer.from(bg_servicos_geracao_estimada_base64.imagem, "base64"), 650, 38, {
    floating: {
      horizontalPosition: {
        offset: 400000,
      },
      verticalPosition: {
        offset: 300000,
      },
      margins: {
        left: 4000,
      },
    },
  });
  
  const bg_servicos_economia_estimada_ok = Media.addImage(doc, Buffer.from(bg_servicos_economia_estimada_base64.imagem, "base64"), 650, 38, {
    floating: {
      horizontalPosition: {
        offset: 400000,
      },
      verticalPosition: {
        offset: 4600000,
      },
      margins: {
        left: 4000,
      },
    },
  });
  
  const bg_selo_ok = Media.addImage(doc, Buffer.from(selo_base64.imagem, "base64"), 90, 110, {
    floating: {
      horizontalPosition: {
        offset: 1500000,
      },
      verticalPosition: {
        offset: 8600000,
      },
      margins: {
        left: 4000,
      },
    },
  });

  const bg_servicos_preco_gerador_ok = Media.addImage(doc, Buffer.from(bg_servicos_preco_gerador_base64.imagem, "base64"), 650, 38, {
    floating: {
      horizontalPosition: {
        offset: 400000,
      },
      verticalPosition: {
        offset: 300000,
      },
      margins: {
        left: 4000,
      },
    },
  });
  
  const bg_servicos_viabilidade_ok = Media.addImage(doc, Buffer.from(bg_servicos_viabilidade_base64.imagem, "base64"), 650, 38, {
    floating: {
      horizontalPosition: {
        offset: 400000,
      },
      verticalPosition: {
        offset: 1300000,
      },
      margins: {
        left: 4000,
      },
    },
  });
  
  const bg_servicos_pagamento_ok = Media.addImage(doc, Buffer.from(bg_servicos_pagamento_base64.imagem, "base64"), 650, 38, {
    floating: {
      horizontalPosition: {
        offset: 400000,
      },
      verticalPosition: {
        offset: 6500000,
      },
      margins: {
        left: 4000,
      },
    },
  });
  
  const bg_servicos_validade_proposta_ok = Media.addImage(doc, Buffer.from(bg_servicos_validade_proposta_base64.imagem, "base64"), 650, 38, {
    floating: {
      horizontalPosition: {
        offset: 400000,
      },
      verticalPosition: {
        offset: 9600000,
      },
      margins: {
        left: 4000,
      },
    },
  });


  function createContactInfo() {
    return data.equipment.inverter.map(
      (inversor) =>
        new Table({
          rows: [
            new TableRow({
              children: [
                new TableCell({
                  children: [
                    new Paragraph({
                      text: "INVERSOR: ",
                      heading: HeadingLevel.HEADING_5,
                      alignment: AlignmentType.RIGHT,
                    }),
                  ],
                  width: {
                    size: 48,
                    type: WidthType.PERCENTAGE,
                  },
                  borders: {
                    top: {size: 0, color: "white"},
                    bottom: {size: 0, color: "white"},
                    left: {size: 0, color: "white"},
                    right: {size: 50, color: "white"},
                  },
                }),
                new TableCell({
                  children: [
                    new Table({
                      rows: [
                        new TableRow({
                          children: [
                            new TableCell({
                              children: [
                                new Paragraph({
                                  text: `${inversor.inverter} KWp`,
                                  heading: HeadingLevel.HEADING_6,
                                  alignment: AlignmentType.LEFT,
                                }),
                              ],
                              width: {
                                size: 33,
                                type: WidthType.PERCENTAGE,
                              },
                              borders: {
                                top: {size: 0, color: "white"},
                                bottom: {size: 0, color: "white"},
                                left: {size: 0, color: "white"},
                                right: {size: 0, color: "white"},
                              },
                            }),
                            new TableCell({
                              children: [
                                new Paragraph({
                                  text: `${inversor.inverter_manufacturer}`,
                                  heading: HeadingLevel.HEADING_6,
                                  alignment: AlignmentType.LEFT,
                                }),
                              ],
                              width: {
                                size: 33,
                                type: WidthType.PERCENTAGE,
                              },
                              borders: {
                                top: {size: 0, color: "white"},
                                bottom: {size: 0, color: "white"},
                                left: {size: 0, color: "white"},
                                right: {size: 0, color: "white"},
                              },
                            }),
                            new TableCell({
                              children: [
                                new Paragraph({
                                  text: `${inversor.inverter_qtde} unidades`,
                                  heading: HeadingLevel.HEADING_6,
                                  alignment: AlignmentType.LEFT,
                                }),
                              ],
                              width: {
                                size: 33,
                                type: WidthType.PERCENTAGE,
                              },
                              borders: {
                                top: {size: 0, color: "white"},
                                bottom: {size: 0, color: "white"},
                                left: {size: 0, color: "white"},
                                right: {size: 0, color: "white"},
                              },
                            })
                          ]
                        }),
                      ],
                      width: {
                        size: 100,
                        type: WidthType.PERCENTAGE,
                      }
                    })
                  ],
                  width: {
                    size: 48,
                    type: WidthType.PERCENTAGE,
                  },
                  borders: {
                    top: {size: 0, color: "white"},
                    bottom: {size: 0, color: "white"},
                    left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
                    right: {size: 0, color: "white"},
                  },
                }),
              ],
            }),
          ],
          spacing: {
            before: 600,
          },
          width: {
            size: 100,
            type: WidthType.PERCENTAGE,
          }
        })
    );
  }

  const tableInfoCliente = new Table({
    rows: [
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: state.client_name,
                heading: HeadingLevel.HEADING_1,
              }),
              new Paragraph({
                text: `${state.client_city_rep} - ${state.client_state}`,
                heading: HeadingLevel.HEADING_2,
              }),
              new Paragraph({
                text: datePT_BR,
                heading: HeadingLevel.HEADING_2,
              }),
              new Paragraph({
                text: state.client_phone,
                heading: HeadingLevel.HEADING_2,
              })
            ],
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [new Paragraph(logo_ok)],
            verticalAlign: VerticalAlign.CENTER,
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
        ],
      }),
    ],
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    }
  });

  const tableInfoDescricao = new Table({
    rows: [
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: "POTÊNCIA TOTAL: ",
                heading: HeadingLevel.HEADING_5,
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 48,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 50, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.description.power_total} KWp`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.LEFT,
              }),
            ],
            width: {
              size: 48,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: "PRODUÇÃO ENERGIA MÉDIA: ",
                heading: HeadingLevel.HEADING_5,
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 48,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 50, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.description.production_energy_month} KWh`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.LEFT,
              }),
            ],
            width: {
              size: 48,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: "AUTOCONSUMO INSTANTÂNEO DA PRODUÇÃO: ",
                heading: HeadingLevel.HEADING_5,
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 48,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 50, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.description.self_consumption_snapshot_production} KWh`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.LEFT,
              }),
            ],
            width: {
              size: 48,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: "PRODUÇÃO INJETADA NA REDE: ",
                heading: HeadingLevel.HEADING_5,
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 48,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 50, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.description.production_injected} KWh`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.LEFT,
              }),
            ],
            width: {
              size: 48,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: "ÁREA TOTAL UTILIZADA: ",
                heading: HeadingLevel.HEADING_5,
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 48,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 50, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.description.area_total_used} m²`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.LEFT,
              }),
            ],
            width: {
              size: 48,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
        ],
      }),
    ],
    spacing: {
      before: 600,
    },
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    }
  });

  const tableInfoEquipamentos1 = new Table({
    rows: [
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: "PAINEL SOLAR: ",
                heading: HeadingLevel.HEADING_5,
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 48,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 50, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Table({
                rows: [
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [
                          new Paragraph({
                            text: data.equipment.board,
                            heading: HeadingLevel.HEADING_6,
                            alignment: AlignmentType.LEFT,
                          }),
                        ],
                        width: {
                          size: 33,
                          type: WidthType.PERCENTAGE,
                        },
                        borders: {
                          top: {size: 0, color: "white"},
                          bottom: {size: 0, color: "white"},
                          left: {size: 0, color: "white"},
                          right: {size: 0, color: "white"},
                        },
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            text: data.equipment.board_manufacturer,
                            heading: HeadingLevel.HEADING_6,
                            alignment: AlignmentType.LEFT,
                          }),
                        ],
                        width: {
                          size: 33,
                          type: WidthType.PERCENTAGE,
                        },
                        borders: {
                          top: {size: 0, color: "white"},
                          bottom: {size: 0, color: "white"},
                          left: {size: 0, color: "white"},
                          right: {size: 0, color: "white"},
                        },
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            text: data.equipment.board_qtde,
                            heading: HeadingLevel.HEADING_6,
                            alignment: AlignmentType.LEFT,
                          }),
                        ],
                        width: {
                          size: 33,
                          type: WidthType.PERCENTAGE,
                        },
                        borders: {
                          top: {size: 0, color: "white"},
                          bottom: {size: 0, color: "white"},
                          left: {size: 0, color: "white"},
                          right: {size: 0, color: "white"},
                        },
                      }),
                    ],
                  }),
                ],
                width: {
                  size: 100,
                  type: WidthType.PERCENTAGE,
                }
              })
            ],
            width: {
              size: 48,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
        ],
      }),
    ],
    spacing: {
      before: 600,
    },
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    }
  });

  const tableInfoEquipamentos2 = new Table({
    rows: [
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: "KIT FIXAÇÃO: ",
                heading: HeadingLevel.HEADING_5,
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 48,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 50, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Table({
                rows: [
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [
                          new Paragraph({
                            text: data.equipment.kit_fixation,
                            heading: HeadingLevel.HEADING_6,
                            alignment: AlignmentType.LEFT,
                          }),
                        ],
                        width: {
                          size: 33,
                          type: WidthType.PERCENTAGE,
                        },
                        borders: {
                          top: {size: 0, color: "white"},
                          bottom: {size: 0, color: "white"},
                          left: {size: 0, color: "white"},
                          right: {size: 0, color: "white"},
                        },
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            text: data.equipment.kit_fixation_description,
                            heading: HeadingLevel.HEADING_6,
                            alignment: AlignmentType.LEFT,
                          }),
                        ],
                        width: {
                          size: 33,
                          type: WidthType.PERCENTAGE,
                        },
                        borders: {
                          top: {size: 0, color: "white"},
                          bottom: {size: 0, color: "white"},
                          left: {size: 0, color: "white"},
                          right: {size: 0, color: "white"},
                        },
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            text: data.equipment.kit_fixation_value,
                            heading: HeadingLevel.HEADING_6,
                            alignment: AlignmentType.LEFT,
                          }),
                        ],
                        width: {
                          size: 33,
                          type: WidthType.PERCENTAGE,
                        },
                        borders: {
                          top: {size: 0, color: "white"},
                          bottom: {size: 0, color: "white"},
                          left: {size: 0, color: "white"},
                          right: {size: 0, color: "white"},
                        },
                      }),
                    ],
                  }),
                ],
                width: {
                  size: 100,
                  type: WidthType.PERCENTAGE,
                }
              })
            ],
            width: {
              size: 48,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: "MÓDULO DE MONITORAMENTO WIFI: ",
                heading: HeadingLevel.HEADING_5,
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 48,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 50, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Table({
                rows: [
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [
                          new Paragraph({
                            text: data.equipment.kit_monitoring_wifi,
                            heading: HeadingLevel.HEADING_6,
                            alignment: AlignmentType.LEFT,
                          }),
                        ],
                        width: {
                          size: 33,
                          type: WidthType.PERCENTAGE,
                        },
                        borders: {
                          top: {size: 0, color: "white"},
                          bottom: {size: 0, color: "white"},
                          left: {size: 0, color: "white"},
                          right: {size: 0, color: "white"},
                        },
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            text: data.equipment.kit_monitoring_description_wifi,
                            heading: HeadingLevel.HEADING_6,
                            alignment: AlignmentType.LEFT,
                          }),
                        ],
                        width: {
                          size: 33,
                          type: WidthType.PERCENTAGE,
                        },
                        borders: {
                          top: {size: 0, color: "white"},
                          bottom: {size: 0, color: "white"},
                          left: {size: 0, color: "white"},
                          right: {size: 0, color: "white"},
                        },
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            text: data.equipment.kit_monitoring_value_wifi,
                            heading: HeadingLevel.HEADING_6,
                            alignment: AlignmentType.LEFT,
                          }),
                        ],
                        width: {
                          size: 33,
                          type: WidthType.PERCENTAGE,
                        },
                        borders: {
                          top: {size: 0, color: "white"},
                          bottom: {size: 0, color: "white"},
                          left: {size: 0, color: "white"},
                          right: {size: 0, color: "white"},
                        },
                      }),
                    ],
                  }),
                ],
                width: {
                  size: 100,
                  type: WidthType.PERCENTAGE,
                }
              })
            ],
            width: {
              size: 48,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
        ],
      }),
    ],
    spacing: {
      before: 600,
    },
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    }
  });

  const tableInfoServicos = new Table({
    rows: [
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: "MATERIAL ELÉTRICO",
                heading: HeadingLevel.HEADING_5,
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 48,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 50, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: data.services.material_electric,
                heading: HeadingLevel.HEADING_5,
                alignment: AlignmentType.LEFT,
              }),
            ],
            width: {
              size: 48,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: "MÃO DE OBRA INSTALAÇÃO ",
                heading: HeadingLevel.HEADING_5,
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 48,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 50, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: data.services.hand_work_installation,
                heading: HeadingLevel.HEADING_5,
                alignment: AlignmentType.LEFT,
              }),
            ],
            width: {
              size: 48,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: "PROJETO ELÉTRICO SISTEMA ",
                heading: HeadingLevel.HEADING_5,
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 48,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 50, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: data.services.project_electric_system,
                heading: HeadingLevel.HEADING_5,
                alignment: AlignmentType.LEFT,
              }),
            ],
            width: {
              size: 48,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: "REGULARIZAÇÃO DISTRIBUIDORA ",
                heading: HeadingLevel.HEADING_5,
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 48,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 50, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: data.services.regularization_distributor,
                heading: HeadingLevel.HEADING_5,
                alignment: AlignmentType.LEFT,
              }),
            ],
            width: {
              size: 48,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
        ],
      }),
    ],
    spacing: {
      before: 600,
    },
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    }
  });
  
  const tableInfoGarantia = new Table({
    rows: [
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: "PAINEL SOLAR: ",
                heading: HeadingLevel.HEADING_5,
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 48,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 50, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Table({
                rows: [
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [
                          new Paragraph({
                            text: data.warrant.board_efficiency,
                            heading: HeadingLevel.HEADING_6,
                            alignment: AlignmentType.LEFT,
                          }),
                        ],
                        width: {
                          size: 50,
                          type: WidthType.PERCENTAGE,
                        },
                        borders: {
                          top: {size: 0, color: "white"},
                          bottom: {size: 0, color: "white"},
                          left: {size: 0, color: "white"},
                          right: {size: 0, color: "white"},
                        },
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            text: "25 anos",
                            heading: HeadingLevel.HEADING_6,
                            alignment: AlignmentType.LEFT,
                          }),
                        ],
                        width: {
                          size: 50,
                          type: WidthType.PERCENTAGE,
                        },
                        borders: {
                          top: {size: 0, color: "white"},
                          bottom: {size: 0, color: "white"},
                          left: {size: 0, color: "white"},
                          right: {size: 0, color: "white"},
                        },
                      }),                      
                    ],
                  }),
                ],
                width: {
                  size: 100,
                  type: WidthType.PERCENTAGE,
                }
              })
            ],
            width: {
              size: 48,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: "PAINEL SOLAR: ",
                heading: HeadingLevel.HEADING_5,
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 48,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 50, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Table({
                rows: [
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [
                          new Paragraph({
                            text: data.warrant.board_defect,
                            heading: HeadingLevel.HEADING_6,
                            alignment: AlignmentType.LEFT,
                          }),
                        ],
                        width: {
                          size: 50,
                          type: WidthType.PERCENTAGE,
                        },
                        borders: {
                          top: {size: 0, color: "white"},
                          bottom: {size: 0, color: "white"},
                          left: {size: 0, color: "white"},
                          right: {size: 0, color: "white"},
                        },
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            text: `${data.warrant.board_efficiency_time} anos`,
                            heading: HeadingLevel.HEADING_6,
                            alignment: AlignmentType.LEFT,
                          }),
                        ],
                        width: {
                          size: 50,
                          type: WidthType.PERCENTAGE,
                        },
                        borders: {
                          top: {size: 0, color: "white"},
                          bottom: {size: 0, color: "white"},
                          left: {size: 0, color: "white"},
                          right: {size: 0, color: "white"},
                        },
                      }),                      
                    ],
                  }),
                ],
                width: {
                  size: 100,
                  type: WidthType.PERCENTAGE,
                }
              })
            ],
            width: {
              size: 48,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: "INVERSOR: ",
                heading: HeadingLevel.HEADING_5,
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 48,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 50, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Table({
                rows: [
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [
                          new Paragraph({
                            text: data.warrant.inverter_defect,
                            heading: HeadingLevel.HEADING_6,
                            alignment: AlignmentType.LEFT,
                          }),
                        ],
                        width: {
                          size: 50,
                          type: WidthType.PERCENTAGE,
                        },
                        borders: {
                          top: {size: 0, color: "white"},
                          bottom: {size: 0, color: "white"},
                          left: {size: 0, color: "white"},
                          right: {size: 0, color: "white"},
                        },
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            text: data.warrant.inverter_defect_time,
                            heading: HeadingLevel.HEADING_6,
                            alignment: AlignmentType.LEFT,
                          }),
                        ],
                        width: {
                          size: 50,
                          type: WidthType.PERCENTAGE,
                        },
                        borders: {
                          top: {size: 0, color: "white"},
                          bottom: {size: 0, color: "white"},
                          left: {size: 0, color: "white"},
                          right: {size: 0, color: "white"},
                        },
                      }),                      
                    ],
                  }),
                ],
                width: {
                  size: 100,
                  type: WidthType.PERCENTAGE,
                }
              })
            ],
            width: {
              size: 48,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: "INSTALAÇÃO: ",
                heading: HeadingLevel.HEADING_5,
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 48,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 50, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Table({
                rows: [
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [
                          new Paragraph({
                            text: 'DEFEITOS NAS INSTALAÇÕES',
                            heading: HeadingLevel.HEADING_6,
                            alignment: AlignmentType.LEFT,
                          }),
                        ],
                        width: {
                          size: 50,
                          type: WidthType.PERCENTAGE,
                        },
                        borders: {
                          top: {size: 0, color: "white"},
                          bottom: {size: 0, color: "white"},
                          left: {size: 0, color: "white"},
                          right: {size: 0, color: "white"},
                        },
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            text: data.warrant.installation,
                            heading: HeadingLevel.HEADING_6,
                            alignment: AlignmentType.LEFT,
                          }),
                        ],
                        width: {
                          size: 50,
                          type: WidthType.PERCENTAGE,
                        },
                        borders: {
                          top: {size: 0, color: "white"},
                          bottom: {size: 0, color: "white"},
                          left: {size: 0, color: "white"},
                          right: {size: 0, color: "white"},
                        },
                      }),                      
                    ],
                  }),
                ],
                width: {
                  size: 100,
                  type: WidthType.PERCENTAGE,
                }
              })
            ],
            width: {
              size: 48,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
        ],
      }),
    ],
    spacing: {
      before: 600,
    },
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    }
  });

  // Página 2
  const tableInfoGeracao = new Table({
    rows: [
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: "",
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 50, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: 'GERAÇÃO TOTAL ENERGIA KWh',
                style: 'TituloTable',
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: 'GERAÇÃO ENERGIA AUTOCONSUMO KWh',
                style: 'TituloTable',
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: 'GERAÇÃO ENERGIA INJETADA KWh',
                style: 'TituloTable',
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: 'JANEIRO',
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 50, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.generation.janeiro.consumption_01}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.generation.janeiro.self_consumption}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.generation.janeiro.injected}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: 'FEVEREIRO',
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 50, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.generation.fevereiro.consumption_02}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.generation.fevereiro.self_consumption}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.generation.fevereiro.injected}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: 'MARÇO',
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 50, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.generation.marco.consumption_03}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.generation.marco.self_consumption}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.generation.marco.injected}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: 'ABRIL',
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 50, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.generation.abril.consumption_04}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.generation.abril.self_consumption}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.generation.abril.injected}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: 'MAIO',
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 50, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.generation.maio.consumption_05}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.generation.maio.self_consumption}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.generation.maio.injected}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: 'JUNHO',
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 50, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.generation.junho.consumption_06}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.generation.junho.self_consumption}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.generation.junho.injected}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: 'JULHO',
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 50, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.generation.julho.consumption_07}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.generation.julho.self_consumption}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.generation.julho.injected}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: 'AGOSTO',
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 50, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.generation.agosto.consumption_08}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.generation.agosto.self_consumption}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.generation.agosto.injected}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: 'SETEMBRO',
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 50, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.generation.setembro.consumption_09}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.generation.setembro.self_consumption}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.generation.setembro.injected}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: 'OUTUBRO',
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 50, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.generation.outubro.consumption_10}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.generation.outubro.self_consumption}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.generation.outubro.injected}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: 'NOVEMBRO',
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 50, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.generation.novembro.consumption_11}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.generation.novembro.self_consumption}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.generation.novembro.injected}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: 'DEZEMBRO',
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 50, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.generation.dezembro.consumption_12}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.generation.dezembro.self_consumption}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.generation.dezembro.injected}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: 'TOTAL',
                style: 'TituloTable',
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 50, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.generation.total_geration_energy_kwp}`,
                style: 'TituloTable',
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.generation.total_geration_energy_self_consumption_kwp}`,
                style: 'TituloTable',
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.generation.total_geration_injected_kwp}`,
                style: 'TituloTable',
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
        ],
      }),
    ],
    spacing: {
      before: 600,
    },
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    }
  });

  const tableInfoEconomia = new Table({
    rows: [
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: "",
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 50, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: 'ECONOMIA TOTAL R$',
                style: 'TituloTable',
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: 'GERAÇÃO ENERGIA AUTOCONSUMO R$',
                style: 'TituloTable',
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: 'ECONOMIA ENERGIA INJETADA R$',
                style: 'TituloTable',
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: 'JANEIRO',
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 50, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.economy.janeiro.economy}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.economy.janeiro.economy_self_consumption}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.economy.janeiro.economy_injected}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: 'FEVEREIRO',
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 50, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.economy.fevereiro.economy}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.economy.fevereiro.economy_self_consumption}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.economy.fevereiro.economy_injected}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: 'MARÇO',
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 50, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.economy.marco.economy}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.economy.marco.economy_self_consumption}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.economy.marco.economy_injected}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: 'ABRIL',
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 50, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.economy.abril.economy}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.economy.abril.economy_self_consumption}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.economy.abril.economy_injected}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: 'MAIO',
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 50, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.economy.maio.economy}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.economy.maio.economy_self_consumption}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.economy.maio.economy_injected}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: 'JUNHO',
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 50, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.economy.junho.economy}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.economy.junho.economy_self_consumption}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.economy.junho.economy_injected}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: 'JULHO',
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 50, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.economy.julho.economy}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.economy.julho.economy_self_consumption}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.economy.julho.economy_injected}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: 'AGOSTO',
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 50, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.economy.agosto.economy}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.economy.agosto.economy_self_consumption}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.economy.agosto.economy_injected}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: 'SETEMBRO',
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 50, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.economy.setembro.economy}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.economy.setembro.economy_self_consumption}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.economy.setembro.economy_injected}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: 'OUTUBRO',
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 50, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.economy.outubro.economy}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.economy.outubro.economy_self_consumption}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.economy.outubro.economy_injected}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: 'NOVEMBRO',
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 50, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.economy.novembro.economy}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.economy.novembro.economy_self_consumption}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.economy.novembro.economy_injected}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: 'DEZEMBRO',
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 50, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.economy.dezembro.economy}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.economy.dezembro.economy_self_consumption}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.economy.dezembro.economy_injected}`,
                heading: HeadingLevel.HEADING_6,
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: 'TOTAL',
                style: 'TituloTable',
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {size: 0, color: "white"},
              right: {size: 50, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.economy.total_economy_energy_rs}`,
                style: 'TituloTable',
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.economy.total_economy_geration_energy_self_consumption_kwp_rs}`,
                style: 'TituloTable',
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: `${data.economy.total_economy_injected_rs_with_tax}`,
                style: 'TituloTable',
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            borders: {
              top: {size: 0, color: "white"},
              bottom: {size: 0, color: "white"},
              left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
              right: {size: 0, color: "white"},
            },
          }),
        ],
      }),
    ],
    spacing: {
      before: 600,
    },
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    }
  });

  // doc.addSection ADICIONA NOVA PAGINA
  let marginServices = '900';
  if (data.equipment.inverter.length > '1') {
    marginServices = '500';
  }

  doc.addSection({
    children: [new Paragraph(bg_rel_ok)],
  })
  
  doc.addSection({
    footers: {
      default: new Footer({
        children: [new Paragraph(bg_servicos_footer_OK)],
      }),
    },
    margins: {
      top: 0,
      bottom: 0,
    },
    children: [
      tableInfoCliente,
      new Paragraph({
        text: `Gerador Fotovoltaico - ${data.description.power_total} KWp`,
        heading: HeadingLevel.HEADING_3,
        alignment: AlignmentType.CENTER,
      }),
      new Paragraph({
        children: [bg_servicos_ok],
      }),
      new Paragraph({
        text: "",
        spacing: {
          before: 900,
        },
      }),
      tableInfoDescricao,
      new Paragraph({
        children: [bg_equipamentos_ok],
      }),
      new Paragraph({
        text: "",
        spacing: {
          before: 900,
        },
      }),
      tableInfoEquipamentos1,
      ...createContactInfo(),
      tableInfoEquipamentos2,
      new Paragraph({
        children: [bg_servicos_servicos_ok],
      }),
      new Paragraph({
        text: "",
        spacing: {
          before: marginServices,
        },
      }),
      tableInfoServicos,
      new Paragraph({
        children: [bg_servicos_garantia_ok],
      }),
      new Paragraph({
        text: "",
        spacing: {
          before: 1000,
        },
      }),
      tableInfoGarantia,
      new Paragraph({
        children: [bg_selo_ok],
      }),
      new Paragraph({
        text: data.warrant.geration_efficiency_percentual,
        style: "TituloGarantiaEficiendia",
        alignment: AlignmentType.CENTER,
        spacing: {
          before: 0,
        },
      }),
    ],
  });
  function Show10X() {
    return (data.payment[0].ten_plots_show !== 'false') ? (
      new Table({
        rows: [
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    text: '10 PARCELAS SEM JUROS DE:',
                    style: "DadosEconomicosBold",
                    alignment: AlignmentType.LEFT,
                    spacing: {
                      after: 150,
                    },
                  }),
                ], 
                width: {
                  size: 45,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {size: 0, color: "white"},
                  left: {size: 0, color: "white"},
                  right: {size: 0, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `R$ ${data.payment[0].price_in_10}`,
                    style: "DadosEconomicos",
                    alignment: AlignmentType.LEFT,
                    spacing: {
                      after: 150,
                    },
                  }),
                ], 
                width: {
                  size: 55,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {size: 0, color: "white"},
                  left: {size: 0, color: "white"},
                  right: {size: 0, color: "white"},
                },
              }),
            ],
          }),
        ],
        spacing: {
          before: 0,
        },
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        }
      })
    ) : ("");
  }
  function Show36X() {
    return (data.payment[0].price_in_36 > '0') ? (
      new Table({
        rows: [
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    text: '36 PARCELAS DE: ',
                    style: "DadosEconomicos",
                    alignment: AlignmentType.LEFT,
                    spacing: {
                      after: 150,
                    },
                  }),
                ], 
                width: {
                  size: 45,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {size: 0, color: "white"},
                  left: {size: 0, color: "white"},
                  right: {size: 0, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `R$ ${data.payment[0].price_in_36}`,
                    style: "DadosEconomicos",
                    alignment: AlignmentType.LEFT,
                    spacing: {
                      after: 150,
                    },
                  }),
                ], 
                width: {
                  size: 55,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {size: 0, color: "white"},
                  left: {size: 0, color: "white"},
                  right: {size: 0, color: "white"},
                },
              }),
            ],
          }),
        ],
        spacing: {
          before: 0,
        },
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        }
      })
    ) : ("");
  }
  function Show48X() {
    return (data.payment[0].price_in_48 > '0') ? (
      new Table({
        rows: [
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    text: '48 PARCELAS DE: ',
                    style: "DadosEconomicos",
                    alignment: AlignmentType.LEFT,
                    spacing: {
                      after: 150,
                    },
                  }),
                ], 
                width: {
                  size: 45,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {size: 0, color: "white"},
                  left: {size: 0, color: "white"},
                  right: {size: 0, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `R$ ${data.payment[0].price_in_48}`,
                    style: "DadosEconomicos",
                    alignment: AlignmentType.LEFT,
                    spacing: {
                      after: 150,
                    },
                  }),
                ], 
                width: {
                  size: 55,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {size: 0, color: "white"},
                  left: {size: 0, color: "white"},
                  right: {size: 0, color: "white"},
                },
              }),
            ],
          }),
        ],
        spacing: {
          before: 0,
        },
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        }
      })
    ) : ("");
  }
  function Show60X() {
    return (data.payment[0].price_in_60 > '0') ? (
      new Table({
        rows: [
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    text: '60 PARCELAS DE: ',
                    style: "DadosEconomicos",
                    alignment: AlignmentType.LEFT,
                    spacing: {
                      after: 150,
                    },
                  }),
                ], 
                width: {
                  size: 45,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {size: 0, color: "white"},
                  left: {size: 0, color: "white"},
                  right: {size: 0, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `R$ ${data.payment[0].price_in_60}`,
                    style: "DadosEconomicos",
                    alignment: AlignmentType.LEFT,
                    spacing: {
                      after: 150,
                    },
                  }),
                ], 
                width: {
                  size: 55,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {size: 0, color: "white"},
                  left: {size: 0, color: "white"},
                  right: {size: 0, color: "white"},
                },
              }),
            ],
          }),
        ],
        spacing: {
          before: 0,
        },
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        }
      })
    ) : ("");
  }

  function Show72X() {
    return (data.payment[0].seventy_two_plots_show !== 'false') ? (
      new Table({
        rows: [
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    text: '72 PARCELAS DE:',
                    style: "DadosEconomicos",
                    alignment: AlignmentType.LEFT,
                    spacing: {
                      after: 150,
                    },
                  }),
                ], 
                width: {
                  size: 45,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {size: 0, color: "white"},
                  left: {size: 0, color: "white"},
                  right: {size: 0, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `R$ ${data.payment[0].price_in_72}`,
                    style: "DadosEconomicos",
                    alignment: AlignmentType.LEFT,
                    spacing: {
                      after: 150,
                    },
                  }),
                ], 
                width: {
                  size: 55,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {size: 0, color: "white"},
                  left: {size: 0, color: "white"},
                  right: {size: 0, color: "white"},
                },
              }),
            ],
          }),
        ],
        spacing: {
          before: 0,
        },
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        }
      })
    ) : ("");
  }

  doc.addSection({
    footers: {
      default: new Footer({
        children: [new Paragraph(bg_servicos_footer_OK)],
      }),
    },
    margins: {
      top: 0,
      bottom: 0,
    },
    children: [
      new Paragraph({
        children: [bg_servicos_geracao_estimada_ok],
      }),
      new Paragraph({
        text: "",
        spacing: {
          before: 0,
        },
      }),
      tableInfoGeracao,
      new Paragraph({
        text: "GERAÇÃO TOTAL = GERAÇÃO TOTAL SISTEMA FOTOVOLTAICO.",
        style: "TextoInfoGeracao",
      }),
      new Paragraph({
        text: "GERAÇÃO ENERGIA AUTOCONSUMO = PARTE DA GERAÇÃO TOTAL QUE É CONSUMIDA INSTANTANEAMENTE PELOS EQUIPAMENTOS LIGADOS.",
        style: "TextoInfoGeracao",
      }),
      new Paragraph({
        text: "GERAÇÃO ENERGIA INJETADA = PARTE DA GERAÇÃO TOTAL QUE NÃO É CONSUMIDA INSTANTANEAMENTE PELOS EQUIPAMENTOS LIGADOS E POR ISSO É INJETADA NA REDE DA DISTRIBUIDORA.",
        style: "TextoInfoGeracao",
      }),
      new Paragraph({
        children: [bg_servicos_economia_estimada_ok],
      }),
      new Paragraph({
        text: "",
        spacing: {
          before: 500,
        },
      }),
      tableInfoEconomia,
      new Paragraph({
        text: "ECONOMIA TOTAL = TOTAL DA ECONOMIA DO SISTEMA FOTOVOLTAICO EM R$.",
        style: "TextoInfoGeracao",
      }),
      new Paragraph({
        text: "ECONOMIA ENERGIA AUTOCONSUMO = PARTE DA ECONOMIA TOTAL RELATIVA AO AUTOCONSUMO DA ENERGIA GERADA. CADA KWH DE AUTOCONSUMO VALE O QUE SE PAGA PELA ENERGIA (R$ [tarifa ac]). ESTE VALOR DESAPARECE DA SUA CONTA DE ENERGIA POIS VOCÊ GERA E CONSOME AUTOMATICAMENTE A SUA ENERGIA DEIXANDO DE CONSUMIR ENERGIA DA DISTRIBUIDORA A R$ [tarifa ac]/KWH.",
        style: "TextoInfoGeracao",
      }),
      new Paragraph({
        text: "ECONOMIA ENERGIA INJETADA = PARTE DA ECONOMIA TOTAL RELATIVA À ENERGIA INJETADA NA REDE DA DISTRIBUIDORA. CADA KWH DE ENERGIA INJETADA VALE O QUE VOCÊ PAGA PELO KWH SEM ICMS (R$ [tarifa compra]). ESTE VALOR É O QUE VEM COMO DESCONTO NA SUA CONTA DE ENERGIA.",
        style: "TextoInfoGeracao",
      }),
      new Table({
        rows: [
          new TableRow({
            children: [
              new TableCell({
                verticalAlign: VerticalAlign.BOTTOM,
                children: [
                  new Paragraph({
                    text: 'TOTAL ECONOMIA ANUAL ESTIMADA: ',
                    style: "ReagusteAnual",
                    alignment: AlignmentType.RIGHT,
                    spacing: {
                      after: 40,
                    },
                  }),
                ], 
                width: {
                  size: 55,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {size: 0, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
                  right: {size: 0, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `R$ ${data.economy.total_economy_energy_rs}`,
                    style: "TituloGarantiaEficiendia",
                    alignment: AlignmentType.LEFT,
                    spacing: {
                      after: 40,
                    },
                  }),
                ], 
                width: {
                  size: 45,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {size: 0, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 5, color: "white"},
                  right: {size: 0, color: "white"},
                },
              }),
            ],
          }),
        ],
        spacing: {
          before: 600,
        },
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        }
      }),
      new Paragraph({
        text: "REAJUSTE ANUAL DE ENERGIA: 5,00%",
        style: "ReagusteAnual",
        alignment: AlignmentType.CENTER,
        spacing: {
          before: 0,
        },
      }),
      new Paragraph({
        children: [logo_footer_ok],
      }),
    ]
  });


  doc.addSection({
    footers: {
      default: new Footer({
        children: [
          new Paragraph(bg_servicos_footer_OK)
        ],
      }),
    },
    margins: {
      top: 0,
      bottom: 0,
    },
    children: [
      new Paragraph({
        children: [bg_servicos_preco_gerador_ok],
      }),
      new Table({
        rows: [
          new TableRow({
            children: [
              new TableCell({
                verticalAlign: VerticalAlign.CENTER,
                children: [
                  new Paragraph({
                    text: 'PREÇO DO GERADOR INSTALADO À VISTA: ',
                    style: "ReagusteAnual",
                    alignment: AlignmentType.RIGHT,
                    spacing: {
                      after: 0,
                    },
                  }),
                ], 
                width: {
                  size: 50,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {size: 0, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 50, color: "white"},
                  right: {size: 0, color: "white"},
                },
              }),
              new TableCell({
                verticalAlign: VerticalAlign.BOTTOM,
                children: [
                  new Paragraph({
                    text: `R$ ${data.payment[0].discount_5_percent_delivery}`,
                    style: "TituloGarantiaEficiendia",
                    alignment: AlignmentType.LEFT,
                    spacing: {
                      after: 40,
                    },
                  }),
                ], 
                width: {
                  size: 50,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {size: 0, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 5, color: "white"},
                  right: {size: 0, color: "white"},
                },
              }),
            ],
          }),
          // new TableRow({
          //   children: [
          //     new TableCell({
          //       verticalAlign: VerticalAlign.CENTER,
          //       children: [
          //         new Paragraph({
          //           text: 'SEGURO ANUAL: ',
          //           style: "SeguroAnual",
          //           alignment: AlignmentType.RIGHT,
          //           spacing: {
          //             before: 20,
          //           },
          //         }),
          //       ], 
          //       width: {
          //         size: 50,
          //         type: WidthType.PERCENTAGE,
          //       },
          //       borders: {
          //         top: {size: 0, color: "white"},
          //         bottom: {size: 0, color: "white"},
          //         left: {style: BorderStyle.DOUBLE, size: 5, color: "white"},
          //         right: {size: 0, color: "white"},
          //       },
          //     }),
          //     new TableCell({
          //       verticalAlign: VerticalAlign.BOTTOM,
          //       children: [
          //         new Paragraph({
          //           // text: `R$ ${data.payment[0].annual_insurance}`,
          //           text: `Um ano de seguro grátis.`,
          //           style: "SeguroAnual",
          //           alignment: AlignmentType.LEFT,
          //           spacing: {
          //             before: 20,
          //           },
          //         }),
          //       ], 
          //       width: {
          //         size: 50,
          //         type: WidthType.PERCENTAGE,
          //       },
          //       borders: {
          //         top: {size: 0, color: "white"},
          //         bottom: {size: 0, color: "white"},
          //         left: {style: BorderStyle.DOUBLE, size: 5, color: "white"},
          //         right: {size: 0, color: "white"},
          //       },
          //     }),
          //   ],
          // }),
        ],
        spacing: {
          before: 600,
        },
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        }
      }),
      new Paragraph({
        text: "",
        spacing: {
          before: 500,
        },
      }),
      new Paragraph({
        children: [bg_servicos_viabilidade_ok],
      }),
      new Paragraph({
        text: "",
        spacing: {
          before: 0,
        },
      }),
      new Table({
        rows: [
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    text: 'DADOS ECONÔMICOS DO SISTEMA: ',
                    style: "DadosEconomicosBold",
                    alignment: AlignmentType.LEFT,
                    spacing: {
                      after: 150,
                    },
                  }),
                ], 
                width: {
                  size: 45,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {size: 0, color: "white"},
                  left: {size: 0, color: "white"},
                  right: {size: 0, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: ''
                  }),
                ], 
                width: {
                  size: 55,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {size: 0, color: "white"},
                  left: {size: 0, color: "white"},
                  right: {size: 0, color: "white"},
                },
              }),
            ],
          }),
        ],
        spacing: {
          before: 0,
        },
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        }
      }),
      new Table({
        rows: [
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    text: 'VALOR DO kWh – AUTOCONSUMO: ',
                    style: 'DadosEconomicos',
                    alignment: AlignmentType.LEFT,
                    spacing: {
                      before: 120,
                    },
                  }),
                ], 
                width: {
                  size: 45,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {size: 0, color: "white"},
                  left: {size: 0, color: "white"},
                  right: {size: 0, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `R$ ${data.viability.value_kwh}`,
                    style: 'DadosEconomicos',
                    alignment: AlignmentType.LEFT,
                    spacing: {
                      before: 120,
                    },
                  }),
                ], 
                width: {
                  size: 55,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {size: 0, color: "white"},
                  left: {size: 0, color: "white"},
                  right: {size: 0, color: "white"},
                },
              }),
            ],
          }),

          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    text: 'VALOR DO KWh - CRÉDITO: ',
                    style: 'DadosEconomicos',
                    alignment: AlignmentType.LEFT,
                    spacing: {
                      before: 120,
                    },
                  }),
                ], 
                width: {
                  size: 45,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {size: 0, color: "white"},
                  left: {size: 0, color: "white"},
                  right: {size: 0, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `R$ ${data.viability.value_kwh_with_tax} com imposto`,
                    style: 'DadosEconomicos',
                    alignment: AlignmentType.LEFT,
                    spacing: {
                      before: 120,
                    },
                  }),
                ], 
                width: {
                  size: 55,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {size: 0, color: "white"},
                  left: {size: 0, color: "white"},
                  right: {size: 0, color: "white"},
                },
              }),
            ],
          }),

          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    text: 'DEGRADAÇÃO ANUAL GERAÇÃO ENERGIA: ',
                    style: 'DadosEconomicos',
                    alignment: AlignmentType.LEFT,
                    spacing: {
                      before: 120,
                    },
                  }),
                ], 
                width: {
                  size: 45,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {size: 0, color: "white"},
                  left: {size: 0, color: "white"},
                  right: {size: 0, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: '0,80%',
                    style: 'DadosEconomicos',
                    alignment: AlignmentType.LEFT,
                    spacing: {
                      before: 120,
                    },
                  }),
                ], 
                width: {
                  size: 55,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {size: 0, color: "white"},
                  left: {size: 0, color: "white"},
                  right: {size: 0, color: "white"},
                },
              }),
            ],
          }),

          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    text: 'REAJUSTE ANUAL DE ENERGIA: ',
                    style: 'DadosEconomicos',
                    alignment: AlignmentType.LEFT,
                    spacing: {
                      before: 120,
                    },
                  }),
                ], 
                width: {
                  size: 45,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {size: 0, color: "white"},
                  left: {size: 0, color: "white"},
                  right: {size: 0, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: '5,00%',
                    style: 'DadosEconomicos',
                    alignment: AlignmentType.LEFT,
                    spacing: {
                      before: 120,
                    },
                  }),
                ], 
                width: {
                  size: 55,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {size: 0, color: "white"},
                  left: {size: 0, color: "white"},
                  right: {size: 0, color: "white"},
                },
              }),
            ],
          }),
        ],
        spacing: {
          before: 100,
        },
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        }
      }),

      new Table({
        rows: [
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    text: 'VIABILIDADE DO INVESTIMENTO: ',
                    style: "DadosEconomicosBold",
                    alignment: AlignmentType.LEFT,
                    spacing: {
                      before: 150,
                      after: 100,
                    },
                  }),
                ], 
                width: {
                  size: 45,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {size: 0, color: "white"},
                  left: {size: 0, color: "white"},
                  right: {size: 0, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: ''
                  }),
                ], 
                width: {
                  size: 55,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {size: 0, color: "white"},
                  left: {size: 0, color: "white"},
                  right: {size: 0, color: "white"},
                },
              }),
            ],
          }),
        ],
        spacing: {
          before: 100,
        },
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        }
      }),
      new Table({
        rows: [
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    text: 'TEMPO DE RETORNO DO INVESTIMENTO: ',
                    style: 'DadosEconomicos',
                    alignment: AlignmentType.LEFT,
                    spacing: {
                      before: 120,
                    },
                  }),
                ], 
                width: {
                  size: 45,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {size: 0, color: "white"},
                  left: {size: 0, color: "white"},
                  right: {size: 0, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.viability.time_return}`,
                    style: 'DadosEconomicos',
                    alignment: AlignmentType.LEFT,
                    spacing: {
                      before: 120,
                    },
                  }),
                ], 
                width: {
                  size: 55,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {size: 0, color: "white"},
                  left: {size: 0, color: "white"},
                  right: {size: 0, color: "white"},
                },
              }),
            ],
          }),

          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    text: 'ECONOMIA TOTAL EM 25 ANOS: ',
                    style: 'DadosEconomicos',
                    alignment: AlignmentType.LEFT,
                    spacing: {
                      before: 120,
                    },
                  }),
                ], 
                width: {
                  size: 45,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {size: 0, color: "white"},
                  left: {size: 0, color: "white"},
                  right: {size: 0, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `R$ ${data.total_roi}`,
                    style: 'DadosEconomicos',
                    alignment: AlignmentType.LEFT,
                    spacing: {
                      before: 120,
                    },
                  }),
                ], 
                width: {
                  size: 55,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {size: 0, color: "white"},
                  left: {size: 0, color: "white"},
                  right: {size: 0, color: "white"},
                },
              }),
            ],
          }),

          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    text: 'ECONOMIA TOTAL PRIMEIRO ANO: ',
                    style: 'DadosEconomicos',
                    alignment: AlignmentType.LEFT,
                    spacing: {
                      before: 120,
                    },
                  }),
                ], 
                width: {
                  size: 45,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {size: 0, color: "white"},
                  left: {size: 0, color: "white"},
                  right: {size: 0, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `R$ ${data.economy.total_economy_energy_rs}`,
                    style: 'DadosEconomicos',
                    alignment: AlignmentType.LEFT,
                    spacing: {
                      before: 120,
                    },
                  }),
                ], 
                width: {
                  size: 55,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {size: 0, color: "white"},
                  left: {size: 0, color: "white"},
                  right: {size: 0, color: "white"},
                },
              }),
            ],
          }),

          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    text: 'RENDIMENTO DO INVESTIMENTO: ',
                    style: 'DadosEconomicos',
                    alignment: AlignmentType.LEFT,
                    spacing: {
                      before: 120,
                    },
                  }),
                ], 
                width: {
                  size: 45,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {size: 0, color: "white"},
                  left: {size: 0, color: "white"},
                  right: {size: 0, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.viability.rentability} % a.m.`,
                    style: 'DadosEconomicos',
                    alignment: AlignmentType.LEFT,
                    spacing: {
                      before: 120,
                    },
                  }),
                ], 
                width: {
                  size: 55,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {size: 0, color: "white"},
                  left: {size: 0, color: "white"},
                  right: {size: 0, color: "white"},
                },
              }),
            ],
          }),
        ],
        spacing: {
          before: 100,
        },
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        }
      }),
      new Paragraph({
        text: "TEMPO DE RETORNO DO INVESTIMENTO:",
        style: "TituloRetornoInvestimento",
        spacing: {
          before: 200,
          after: 100,
        },
      }),
      new Table({
        rows: [
          new TableRow({
            children: [
              // Coluna 1
              new TableCell({
                children: [
                  new Paragraph("")
                ],
                width: {
                  size: 8,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: "ECONOMIA R$",
                    alignment: AlignmentType.CENTER,
                    style: "TituloTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: "ACUMULADO R$",
                    alignment: AlignmentType.CENTER,
                    style: "TituloTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              // Coluna 2
              new TableCell({
                children: [
                  new Paragraph("")
                ],
                width: {
                  size: 8,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: "ECONOMIA R$",
                    alignment: AlignmentType.CENTER,
                    style: "TituloTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: "ACUMULADO R$",
                    alignment: AlignmentType.CENTER,
                    style: "TituloTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              // Coluna 3
              new TableCell({
                children: [
                  new Paragraph("")
                ],
                width: {
                  size: 8,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: "ECONOMIA R$",
                    alignment: AlignmentType.CENTER,
                    style: "TituloTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: "ACUMULADO R$",
                    alignment: AlignmentType.CENTER,
                    style: "TituloTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              // Coluna 4
              new TableCell({
                children: [
                  new Paragraph("")
                ],
                width: {
                  size: 8,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: "ECONOMIA R$",
                    alignment: AlignmentType.CENTER,
                    style: "TituloTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: "ACUMULADO R$",
                    alignment: AlignmentType.CENTER,
                    style: "TituloTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
            ],
          }),
          // Linha 1
          new TableRow({
            children: [
              // Coluna 1
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[0].ano_0.ano}: `,
                    style: "TituloTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 8,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[0].ano_0.economy}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[0].ano_0.accumulated}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              // Coluna 2
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[7].ano_7.ano}: `,
                    style: "TituloTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 8,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[7].ano_7.economy}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[7].ano_7.accumulated}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              // Coluna 3
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[14].ano_14.ano}:`,
                    style: "TituloTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[14].ano_14.economy}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[14].ano_14.accumulated}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              // Coluna 4
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[21].ano_21.ano}:`,
                    style: "TituloTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 8,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[21].ano_21.economy}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[21].ano_21.accumulated}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              
            ],
          }),
          new TableRow({
            children: [
              // Coluna 1
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[1].ano_1.ano}:`,
                    style: "TituloTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 8,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[1].ano_1.economy}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[1].ano_1.accumulated}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              // Coluna 2
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[8].ano_8.ano}:`,
                    style: "TituloTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 8,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[8].ano_8.economy}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[8].ano_8.accumulated}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              // Coluna 3
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[15].ano_15.ano}:`,
                    style: "TituloTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 8,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[15].ano_15.economy}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[15].ano_15.accumulated}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              // Coluna 4
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[22].ano_22.ano}:`,
                    style: "TituloTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 8,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[22].ano_22.economy}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[22].ano_22.accumulated}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              
            ],
          }),
          new TableRow({
            children: [
              // Coluna 1
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[2].ano_2.ano}:`,
                    style: "TituloTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 8,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[2].ano_2.economy}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[2].ano_2.accumulated}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              // Coluna 2
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[9].ano_9.ano}:`,
                    style: "TituloTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 8,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[9].ano_9.economy}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[9].ano_9.accumulated}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              // Coluna 3
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[16].ano_16.ano}:`,
                    style: "TituloTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 8,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[16].ano_16.economy}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[16].ano_16.accumulated}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              // Coluna 4
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[23].ano_23.ano}:`,
                    style: "TituloTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 8,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[23].ano_23.economy}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[23].ano_23.accumulated}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              
            ],
          }),
          new TableRow({
            children: [
              // Coluna 1
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[3].ano_3.ano}:`,
                    style: "TituloTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 8,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[3].ano_3.economy}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[3].ano_3.accumulated}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              // Coluna 2
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[10].ano_10.ano}:`,
                    style: "TituloTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 8,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[10].ano_10.economy}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[10].ano_10.accumulated}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              // Coluna 3
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[17].ano_17.ano}:`,
                    style: "TituloTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 8,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[17].ano_17.economy}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[17].ano_17.accumulated}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              // Coluna 4
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[24].ano_24.ano}:`,
                    style: "TituloTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 8,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[24].ano_24.economy}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[24].ano_24.accumulated}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              
            ],
          }),
          new TableRow({
            children: [
              // Coluna 1
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[4].ano_4.ano}:`,
                    style: "TituloTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 8,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[4].ano_4.economy}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[4].ano_4.accumulated}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              // Coluna 2
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[11].ano_11.ano}:`,
                    style: "TituloTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 8,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[11].ano_11.economy}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[11].ano_11.accumulated}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              // Coluna 3
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[18].ano_18.ano}:`,
                    style: "TituloTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 8,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[18].ano_18.economy}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[18].ano_18.accumulated}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              // Coluna 4
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[25].ano_25.ano}:`,
                    style: "TituloTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 8,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[25].ano_25.economy}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[25].ano_25.accumulated}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              
            ],
          }),
          new TableRow({
            children: [
              // Coluna 1
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[5].ano_5.ano}:`,
                    style: "TituloTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[5].ano_5.economy}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[5].ano_5.accumulated}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              // Coluna 2
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[12].ano_12.ano}:`,
                    style: "TituloTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[12].ano_12.economy}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[12].ano_12.accumulated}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              // Coluna 3
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[19].ano_19.ano}:`,
                    style: "TituloTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[19].ano_19.economy}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[19].ano_19.accumulated}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              // Coluna 4
              new TableCell({
                children: [
                  new Paragraph({
                    text: `Total: `,
                    alignment: AlignmentType.CENTER,
                    style: "TituloTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.total_roi}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[25].ano_25.accumulated}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              
            ],
          }),
          new TableRow({
            children: [
              // Coluna 1
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[6].ano_6.ano}:`,
                    style: "TituloTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[6].ano_6.economy}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[6].ano_6.accumulated}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              // Coluna 2
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[13].ano_13.ano}:`,
                    style: "TituloTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[13].ano_13.economy}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[13].ano_13.accumulated}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              // Coluna 3
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[20].ano_20.ano}:`,
                    style: "TituloTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 8,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[20].ano_20.economy}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `${data.roi[20].ano_20.accumulated}`,
                    alignment: AlignmentType.CENTER,
                    style: "TextoTableRetornoInvestimento",
                  })
                ],
                width: {
                  size: 12,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  left: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                  right: {style: BorderStyle.DOUBLE, size: 20, color: "white"},
                },
              }),
              // Coluna 4
              
            ],
          }),
        ],
        spacing: {
          before: 100,
        },
        float: {
          absoluteHorizontalPosition: -900,
          absoluteVerticalPosition: 0
        },
        width: {
          size: 115,
          type: WidthType.PERCENTAGE,
        }
      }),
      new Paragraph({
        children: [bg_servicos_pagamento_ok],
      }),
      new Paragraph({
        text: "",
        spacing: {
          before: 500,
        },
      }),
      new Table({
        rows: [
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    text: 'VALOR TOTAL: ',
                    style: "DadosEconomicosBold",
                    alignment: AlignmentType.LEFT,
                    spacing: {
                      after: 50,
                    },
                  }),
                ], 
                width: {
                  size: 55,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {size: 0, color: "white"},
                  left: {size: 0, color: "white"},
                  right: {size: 0, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `R$ ${data.payment[0].sales_price}`,
                    style: "DadosEconomicosBold",
                    alignment: AlignmentType.LEFT,
                    spacing: {
                      after: 50,
                    },
                  }),
                ], 
                width: {
                  size: 55,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {size: 0, color: "white"},
                  left: {size: 0, color: "white"},
                  right: {size: 0, color: "white"},
                },
              }),
            ],
          }),
        ],
        spacing: {
          before: 0,
        },
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        }
      }),
      new Table({
        rows: [
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    spacing: {
                      after: 50,
                    },
                  }),
                ], 
                width: {
                  size: 45,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {size: 0, color: "white"},
                  left: {size: 0, color: "white"},
                  right: {size: 0, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    spacing: {
                      after: 50,
                    },
                  }),
                ], 
                width: {
                  size: 55,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {size: 0, color: "white"},
                  left: {size: 0, color: "white"},
                  right: {size: 0, color: "white"},
                },
              }),
            ],
          }),
        ],
        spacing: {
          before: 0,
        },
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        }
      }),
      Show10X(),
      new Table({
        rows: [
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    text: 'VALOR TOTAL À VISTA NO PEDIDO:',
                    style: "DadosEconomicosBold",
                    alignment: AlignmentType.LEFT,
                    spacing: {
                      after: 0,
                    },
                  }),
                ], 
                width: {
                  size: 45,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {size: 0, color: "white"},
                  left: {size: 0, color: "white"},
                  right: {size: 0, color: "white"},
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: `R$ ${data.payment[0].discount_5_percent_delivery}`,
                    style: "DadosEconomicos",
                    alignment: AlignmentType.LEFT,
                    spacing: {
                      after: 0,
                    },
                  }),
                ], 
                width: {
                  size: 55,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  top: {size: 0, color: "white"},
                  bottom: {size: 0, color: "white"},
                  left: {size: 0, color: "white"},
                  right: {size: 0, color: "white"},
                },
              }),
            ],
          }),
        ],
        spacing: {
          before: 0,
        },
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        }
      }),
      new Table({
        rows: [
            new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph("")],
                        borders: {
                          top: {size: 0, color: "white"},
                          bottom: {size: 0, color: "white"},
                          left: {size: 0, color: "white"},
                          right: {size: 0, color: "white"},
                        },
                    }),
                ],
            }),
        ],
      }),
      // new Table({
      //   rows: [
      //     new TableRow({
      //       children: [
      //         new TableCell({
      //           children: [
      //             new Paragraph({
      //               text: 'VALOR TOTAL À VISTA ENTREGA EQUIPAMENTO: ',
      //               style: "DadosEconomicosBold",
      //               alignment: AlignmentType.LEFT,
      //               spacing: {
      //                 after: 150,
      //               },
      //             }),
      //           ], 
      //           width: {
      //             size: 45,
      //             type: WidthType.PERCENTAGE,
      //           },
      //           borders: {
      //             top: {size: 0, color: "white"},
      //             bottom: {size: 0, color: "white"},
      //             left: {size: 0, color: "white"},
      //             right: {size: 0, color: "white"},
      //           },
      //         }),
      //         new TableCell({
      //           children: [
      //             new Paragraph({
      //               text: ` 3% de desconto`,
      //               style: "DadosEconomicos",
      //               alignment: AlignmentType.LEFT,
      //               spacing: {
      //                 after: 150,
      //               },
      //             }),
      //           ], 
      //           width: {
      //             size: 55,
      //             type: WidthType.PERCENTAGE,
      //           },
      //           borders: {
      //             top: {size: 0, color: "white"},
      //             bottom: {size: 0, color: "white"},
      //             left: {size: 0, color: "white"},
      //             right: {size: 0, color: "white"},
      //           },
      //         }),
      //       ],
      //     }),
      //   ],
      //   spacing: {
      //     before: 0,
      //   },
      //   width: {
      //     size: 100,
      //     type: WidthType.PERCENTAGE,
      //   }
      // }),
      // new Table({
      //   rows: [
      //     new TableRow({
      //       children: [
      //         new TableCell({
      //           children: [
      //             new Paragraph({
      //               text: 'FINANCIAMENTO PRÉ-APROVADO: ',
      //               style: "DadosEconomicosBold",
      //               alignment: AlignmentType.LEFT,
      //               spacing: {
      //                 after: 150,
      //               },
      //             }),
      //           ], 
      //           width: {
      //             size: 45,
      //             type: WidthType.PERCENTAGE,
      //           },
      //           borders: {
      //             top: {size: 0, color: "white"},
      //             bottom: {size: 0, color: "white"},
      //             left: {size: 0, color: "white"},
      //             right: {size: 0, color: "white"},
      //           },
      //         }),
      //         new TableCell({
      //           children: [
      //             new Paragraph({
      //               text: 'SEM ENTRADA',
      //               style: "DadosEconomicos",
      //               alignment: AlignmentType.LEFT,
      //               spacing: {
      //                 after: 150,
      //               },
      //             }),
      //           ], 
      //           width: {
      //             size: 55,
      //             type: WidthType.PERCENTAGE,
      //           },
      //           borders: {
      //             top: {size: 0, color: "white"},
      //             bottom: {size: 0, color: "white"},
      //             left: {size: 0, color: "white"},
      //             right: {size: 0, color: "white"},
      //           },
      //         }),
      //       ],
      //     }),
      //   ],
      //   spacing: {
      //     before: 0,
      //   },
      //   width: {
      //     size: 100,
      //     type: WidthType.PERCENTAGE,
      //   }
      // }),
      Show36X(),
      Show48X(),
      Show60X(),
      Show72X(),
      
      new Table({
        rows: [
            new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph("")],
                        borders: {
                          top: {size: 0, color: "white"},
                          bottom: {size: 0, color: "white"},
                          left: {size: 0, color: "white"},
                          right: {size: 0, color: "white"},
                        },
                    }),
                ],
            }),
        ],
      }),
      new Table({
        rows: [
            new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph("")],
                        borders: {
                          top: {size: 0, color: "white"},
                          bottom: {size: 0, color: "white"},
                          left: {size: 0, color: "white"},
                          right: {size: 0, color: "white"},
                        },
                    }),
                ],
            }),
        ],
      }),
       new Table({
         rows: [
           new TableRow({
             children: [
               new TableCell({
                 children: [
                   new Paragraph({
                     text: 'OBS: ',
                     style: "DadosEconomicosBold",
                     alignment: AlignmentType.LEFT,
                     spacing: {
                       before: 150,
                     },
                   }),
                 ], 
                 width: {
                   size: 45,
                   type: WidthType.PERCENTAGE,
                 },
                 borders: {
                   top: {style: BorderStyle.DOUBLE, size: 2, color: "#f8dd00"},
                   bottom: {size: 0, color: "white"},
                   left: {size: 0, color: "white"},
                   right: {size: 0, color: "white"},
                 },
               }),
               new TableCell({
                 children: [
                   new Paragraph({
                     // text: `R$ ${data.payment[0].annual_insurance}`,
                     text: `Mediante consulta de crédito`,
                     style: "DadosEconomicos",
                     alignment: AlignmentType.LEFT,
                     spacing: {
                       before: 150,
                     },
                   }),
                 ], 
                 width: {
                   size: 55,
                   type: WidthType.PERCENTAGE,
                 },
                 borders: {
                   top: {style: BorderStyle.DOUBLE, size: 4, color: "#f8dd00"},
                   bottom: {size: 0, color: "white"},
                   left: {size: 0, color: "white"},
                   right: {size: 0, color: "white"},
                 },
               }),
             ],
           }),
         ],
         spacing: {
           before: 0,
         },
         width: {
           size: 100,
           type: WidthType.PERCENTAGE,
         }
       }),
      new Paragraph({
        children: [bg_servicos_validade_proposta_ok],
      }),
      new Table({
        rows: [
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    text: '05 dias após emissão',
                    style: "TextoValidadeProposta",
                    alignment: AlignmentType.RIGHT,
                    spacing: {
                      before: 450,
                    }
                  }),
                ]
              }),
            ],
          })
        ],
        float: {
          absoluteHorizontalPosition: 1500,
          absoluteVerticalPosition: 14700,
        },
        borders: {
          top: {size: 0, color: "white"},
          bottom: {size: 0, color: "white"},
          left: {size: 0, color: "white"},
          right: {size: 0, color: "white"},
        },
        width: {
          size: 4535,
          type: WidthType.DXA,
        },
        layout: TableLayoutType.FIXED,
      }),
      new Paragraph({
        children: [logo_footer_ok],
      }),
    ]
  });


  return (doc)
}
