import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form'
import * as Yup from 'yup';
import { default as NumberFormat } from 'react-number-format';
import { FiArrowLeft, FiPlusCircle } from 'react-icons/fi';

import { inverterSaveRequest } from '~/store/modules/inverter/actions';

const schema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
});

export default function AddInverter() {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm({
    validationSchema: schema
  });
  const [inverter, setInverter] = useState({
    name: "",
    manufacturer: "",
    serie: "",
    initial: "",
    product_year: "",
    char_description: "",
    char_type_conversor: "",
    char_int_circuit_input: "",
    char_int_circuit_output: "",
    char_con_power_nominal_output: "",
    char_con_yield_europeo: "",
    char_con_nro_trackers: "",
    data_output_power_nomina: "",
    data_output_stability_power: "",
    data_output_frequency: "",
    data_output_report_icc: "",
    data_input_power_nominal: "",
    data_input_tension_min_tracker: "",
    data_input_tension_max_tracker: "",
    data_input_tension_max: "",
    data_input_chain_nominal: "",
    data_input_chain_max: "",
    potential_max_pv: "",
    potential_max_input_cc: "",
    number_input_tracker: "",
    losses_absorption_night: "",
    losses_standby: "",
    yield_tracker_20: "",
    yield_tracker_100: "",
    yield_5: "",
    yield_10: "",
    yield_20: "",
    yield_33: "",
    yield_50: "",
    yield_100: "",
    device_confom_dk5940: "",
    protection_interface_present: "",
    device_interface_present: "",
    transformer_isolation_integrated: "",
    certifications_note: "",
    material_code: "",
    material_description: "",
    material_value_acquisition: "",
    material_value_sale: "",
    warrant: ""
  });

  const onChange = e => {
    setInverter({...inverter,
      [e.target.name]: e.target.value}
    );
  };

  function onSubmit(data) {
    dispatch(inverterSaveRequest(inverter));
  }


  return (
    <div className="container">
      <div className="title">
        <h1>
          <FiPlusCircle size="19px"/> Cadastro de <span> inversor </span>
        </h1>
        <Link to="/inverters" className="button-add"><FiArrowLeft size="16px" color="#6159fd" />Voltar para listagem</Link>
      </div>

      <div className="painel">
        <form className="manager" onSubmit={handleSubmit(onSubmit)}>

          <h2>Modelo / Identificação do inversor</h2>

          <div className="row row-space">
            <div className="col-2">
              <div className="input-group">
                <label htmlFor="name">Nome do inversor</label>
                <input
                  name="name"
                  type="text"
                  placeholder="Ex.: Nome do inversor"
                  onChange={onChange}
                  ref={register}
                />
                {errors.name && <span>{errors.name.message}</span>}
              </div>
            </div>
            <div className="col-2">
              <div className="input-group">
                <label htmlFor="manufacturer">Fabricante</label>
                <input
                  name="manufacturer"
                  type="text"
                  placeholder="Ex.: Nome do Fabricante LTDA"
                  onChange={onChange}
                  ref={register}
                />
              </div>
            </div>
          </div>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="serie">Série</label>
                <input
                  name="serie"
                  type="text"
                  placeholder="Ex.: Solar Family"
                  onChange={onChange}
                  ref={register}
                />
              </div>
            </div>

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="initial">Sigla</label>
                <input
                  name="initial"
                  type="text"
                  placeholder="Ex.: CS3U-360P"
                  onChange={onChange}
                  ref={register}
                />
              </div>
            </div>
          </div>

          <h2 className="identificator">Características</h2>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="char_description">Descrição</label>
                <input
                  name="char_description"
                  type="text"
                  placeholder="Inverter Solar Family"
                  onChange={onChange}
                  ref={register}
                />
              </div>
            </div>

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="char_type_conversor">Tipo conversor</label>
                <input
                  name="char_type_conversor"
                  type="text"
                  placeholder="Ex.: Inverter grid-connected"
                  onChange={onChange}
                  ref={register}
                />
              </div>
            </div>
          </div>

          <h2 className="identificator">Produto</h2>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="product_year">Ano de produção</label>
                <select name="product_year" ref={register} onChange={onChange}>
                  <option value="">Selecione o ano de produção</option>
                  <option value="2018">2018</option>
                  <option value="2019">2019</option>
                  <option value="2020">2020</option>
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                  <option value="2026">2026</option>
                  <option value="2027">2027</option>
                  <option value="2028">2028</option>
                  <option value="2029">2029</option>
                  <option value="2030">2030</option>
                </select>
              </div>
            </div>

            <div className="col-2">
              <div className="input-group check">
                <label htmlFor="product_out_stock">Fora de produção?</label>
                <div className="check-container">
                  <input
                    type="checkbox"
                    name="product_out_stock"
                    ref={register}
                    onChange={onChange}
                  />
                  <label> Sim? </label>
                </div>
              </div>
            </div>

          </div>

          <h2 className="identificator">Produto</h2>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="char_int_circuit_input">Circuito elétrico de entrada</label>
                <input
                  name="char_int_circuit_input"
                  type="text"
                  placeholder="Ex.: F+N"
                  onChange={onChange}
                  ref={register}
                />
              </div>
            </div>

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="char_int_circuit_output">Circuito elétrico de saída</label>
                <input
                  name="char_int_circuit_output"
                  type="text"
                  placeholder="Ex.: F+N"
                  onChange={onChange}
                  ref={register}
                />
              </div>
            </div>

          </div>

          <h2 className="identificator">Caracteristicas conversor</h2>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="char_con_power_nominal_output">Potência nominal de saída CA</label>
                <NumberFormat
                  suffix=" KW"
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  placeholder="Ex.: 3 KW"
                  onValueChange={(values) => {
                    const {value} = values;
                    setInverter({...inverter, char_con_power_nominal_output: value})}
                  }
                  getInputRef={(props) =>
                    <input name="char_con_power_nominal_output" type="text" ref={register}/>
                  }
                />
              </div>
            </div>

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="char_con_yield_europeo">Rendimento Europeo</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={3}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  placeholder="Ex.: 0.978"
                  onValueChange={(values) => {
                    const {value} = values;
                    setInverter({...inverter, char_con_yield_europeo: value})}
                  }
                  getInputRef={(props) =>
                    <input name="char_con_yield_europeo" type="text" ref={register} />
                  }
                />

              </div>
            </div>

          </div>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="char_con_nro_trackers">Número de rastreadores</label>
                <input
                  name="char_con_nro_trackers"
                  type="text"
                  placeholder="Ex.: 1"
                  onChange={onChange}
                  ref={register}
                />
              </div>
            </div>

          </div>

          {/* <h2 className="identificator">Dados de saída (CA)</h2>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="data_output_power_nomina">Tensão nominal</label>
                <NumberFormat
                  suffix=" V"
                  placeholder="Ex.: 231 V"
                  onValueChange={(values) => {
                    const {value} = values;
                    setInverter({...inverter, data_output_power_nomina: value})}
                  }
                  getInputRef={(props) =>
                    <input name="data_output_power_nomina" type="text" ref={register}/>
                  }
                />
              </div>
            </div>

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="data_output_stability_power">Estabilidade de tensão estática</label>
                <NumberFormat
                  suffix=" %"
                  placeholder="Ex.: 1 %"
                  onValueChange={(values) => {
                    const {value} = values;
                    setInverter({...inverter, data_output_stability_power: value})}
                  }
                  getInputRef={(props) =>
                    <input name="data_output_stability_power" type="text" ref={register}/>
                  }
                />
              </div>
            </div>

          </div>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="data_output_frequency">Frequência</label>
                <NumberFormat
                  suffix=" Hz"
                  placeholder="Ex.: 60 Hz"
                  onValueChange={(values) => {
                    const {value} = values;
                    setInverter({...inverter, data_output_frequency: value})}
                  }
                  getInputRef={(props) =>
                    <input name="data_output_frequency" type="text" ref={register}/>
                  }
                />
              </div>
            </div>

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="data_output_report_icc">Ralatório ICC/Em</label>
                <input
                  name="data_output_report_icc"
                  type="text"
                  placeholder="Ex.: 2"
                  onChange={onChange}
                  ref={register}
                />
              </div>
            </div>

          </div> */}

          {/* <h2 className="identificator">Dados de entrada (CC)</h2>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="data_input_power_nominal">Tensão nominal</label>
                <NumberFormat
                  suffix=" V"
                  placeholder="Ex.: 360 V"
                  onValueChange={(values) => {
                    const {value} = values;
                    setInverter({...inverter, data_input_power_nominal: value})}
                  }
                  getInputRef={(props) =>
                    <input name="data_input_power_nominal" type="text" ref={register}/>
                  }
                />
              </div>
            </div>

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="data_input_tension_max">Tensão máxima</label>
                <NumberFormat
                  suffix=" V"
                  placeholder="Ex.: 550 V"
                  onValueChange={(values) => {
                    const {value} = values;
                    setInverter({...inverter, data_input_tension_max: value})}
                  }
                  getInputRef={(props) =>
                    <input name="data_input_tension_max" type="text" ref={register} />
                  }
                />
              </div>
            </div>

          </div>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="data_input_tension_min_tracker">Tensão mínima de reg. rastreador</label>
                <NumberFormat
                  suffix=" V"
                  placeholder="Ex.: 120 V"
                  onValueChange={(values) => {
                    const {value} = values;
                    setInverter({...inverter, data_input_tension_min_tracker: value})}
                  }
                  getInputRef={(props) =>
                    <input name="data_input_tension_min_tracker" type="text" ref={register} />
                  }
                />
              </div>
            </div>

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="data_input_chain_nominal">Corrente nominal</label>
                <NumberFormat
                  suffix=" A"
                  placeholder="Ex.: 15 A"
                  onValueChange={(values) => {
                    const {value} = values;
                    setInverter({...inverter, data_input_chain_nominal: value})}
                  }
                  getInputRef={(props) =>
                    <input name="data_input_chain_nominal" type="text" ref={register} />
                  }
                />
              </div>
            </div>

          </div>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="data_input_tension_max_tracker">Tensão máxima de reg. rastreador</label>
                <NumberFormat
                  suffix=" V"
                  placeholder="Ex.: 500 V"
                  onValueChange={(values) => {
                    const {value} = values;
                    setInverter({...inverter, data_input_tension_max_tracker: value})}
                  }
                  getInputRef={(props) =>
                    <input name="data_input_tension_max_tracker" type="text" ref={register} />
                  }
                />
              </div>
            </div>

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="data_input_chain_max">Corrente máxima</label>
                <NumberFormat
                  suffix=" A"
                  placeholder="Ex.: 15 A"
                  onValueChange={(values) => {
                    const { value } = values;
                    setInverter({...inverter, data_input_chain_max: value})}
                  }
                  getInputRef={(props) =>
                    <input name="data_input_chain_max" type="text" ref={register} />
                  }
                />
              </div>
            </div>

          </div> */}

          {/* <h2 className="identificator">Caracteristicas do inversor grid-connected</h2>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="potential_max_pv">Potência máxima PV aconselhada</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  placeholder="Ex.: 3.15 KW"
                  suffix=" KW"
                  onValueChange={(values) => {
                    const {value} = values;
                    setInverter({...inverter, potential_max_pv: value})}
                  }
                  getInputRef={(props) =>
                    <input name="potential_max_pv" type="text" ref={register} />
                  }
                />

              </div>
            </div>

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="potential_max_input_cc">Potência nominal de entrada CC</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  placeholder="Ex.: 3.06 KW"
                  suffix=" KW"
                  onValueChange={(values) => {
                    const {value} = values;
                    setInverter({...inverter, potential_max_input_cc: value})}
                  }
                  getInputRef={(props) =>
                    <input name="potential_max_input_cc" type="text" ref={register} />
                  }
                />

              </div>
            </div>

          </div>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="number_input_tracker">Número de entradas por rastreador</label>
                <NumberFormat
                  placeholder="Ex.: 1"
                  onValueChange={(values) => {
                    const { value } = values;
                    setInverter({...inverter, number_input_tracker: value})}
                  }
                  getInputRef={(props) =>
                    <input name="number_input_tracker" type="text" ref={register}/>
                  }
                />
              </div>
            </div>

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="losses_absorption_night">Perdas de absorção noturna</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  placeholder="Ex.: 0.5 W"
                  suffix=" W"
                  onValueChange={(values) => {
                    const {value} = values;
                    setInverter({...inverter, losses_absorption_night: value})}
                  }
                  getInputRef={(props) =>
                    <input name="losses_absorption_night" type="text" ref={register} />
                  }
                />

              </div>
            </div>

          </div>


          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="losses_standby">Perdas em standby</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  placeholder="Ex.: 0.5 W"
                  suffix=" W"
                  onValueChange={(values) => {
                    const {value} = values;
                    setInverter({...inverter, losses_standby: value})}
                  }
                  getInputRef={(props) =>
                    <input name="losses_standby" type="text" ref={register} />
                  }
                />

              </div>
            </div>

          </div>


          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="yield_tracker_20">Rendimento do rastreador a 20%</label>
                <NumberFormat
                  suffix=" %"
                  placeholder="Ex.: 10 %"
                  onValueChange={(values) => {
                    const { value } = values;
                    setInverter({...inverter, yield_tracker_20: value})}
                  }
                  getInputRef={(props) =>
                    <input name="yield_tracker_20" type="text" ref={register} />
                  }
                />
              </div>
            </div>

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="yield_tracker_100">Rendimento do rastreador a 100%</label>
                <NumberFormat
                  suffix=" %"
                  placeholder="Ex.: 10 %"
                  onValueChange={(values) => {
                    const { value } = values;
                    setInverter({...inverter, yield_tracker_100: value})}
                  }
                  getInputRef={(props) =>
                    <input name="yield_tracker_100" type="text" ref={register} />
                  }
                />
              </div>
            </div>

          </div>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="yield_5">Rendimento a 5%</label>
                <NumberFormat
                  suffix=" %"
                  placeholder="Ex.: 5 %"
                  onValueChange={(values) => {
                    const { value } = values;
                    setInverter({...inverter, yield_5: value})}
                  }
                  getInputRef={(props) =>
                    <input name="yield_5" type="text" ref={register} />
                  }
                />
              </div>
            </div>

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="yield_10">Rendimento a 10%</label>
                <NumberFormat
                  suffix=" %"
                  placeholder="Ex.: 10 %"
                  onValueChange={(values) => {
                    const { value } = values;
                    setInverter({...inverter, yield_10: value})}
                  }
                  getInputRef={(props) =>
                    <input name="yield_10" type="text" ref={register} />
                  }
                />
              </div>
            </div>

          </div>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="yield_20">Rendimento a 20%</label>
                <NumberFormat
                  suffix=" %"
                  placeholder="Ex.: 10 %"
                  onValueChange={(values) => {
                    const { value } = values;
                    setInverter({...inverter, yield_20: value})}
                  }
                  getInputRef={(props) =>
                    <input name="yield_20" type="text" ref={register} />
                  }
                />
              </div>
            </div>

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="yield_33">Rendimento a 33%</label>
                <NumberFormat
                  suffix=" %"
                  placeholder="Ex.: 33 %"
                  onValueChange={(values) => {
                    const { value } = values;
                    setInverter({...inverter, yield_33: value})}
                  }
                  getInputRef={(props) =>
                    <input name="yield_33" type="text" ref={register} />
                  }
                />
              </div>
            </div>

          </div>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="yield_50">Rendimento a 50%</label>
                <NumberFormat
                  suffix=" %"
                  placeholder="Ex.: 50 %"
                  onValueChange={(values) => {
                    const { value } = values;
                    setInverter({...inverter, yield_50: value})}
                  }
                  getInputRef={(props) =>
                    <input name="yield_50" type="text" ref={register} />
                  }
                />
              </div>
            </div>

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="yield_100">Rendimento a 100%</label>
                <NumberFormat
                  suffix=" %"
                  placeholder="Ex.: 100 %"
                  onValueChange={(values) => {
                    const { value } = values;
                    setInverter({...inverter, yield_100: value})}
                  }
                  getInputRef={(props) =>
                    <input name="yield_100" type="text" ref={register} />
                  }
                />
              </div>
            </div>

          </div>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group check">
                <div className="check-container check">
                  <input
                    type="checkbox"
                    name="device_confom_dk5940"
                    ref={register}
                    onChange={onChange}
                  />
                  <label> Dispositivo em conformidade DK 5940</label>
                </div>
              </div>
            </div>

            <div className="col-2">
              <div className="input-group check">
                <div className="check-container check">
                  <input
                    type="checkbox"
                    name="protection_interface_present"
                    ref={register}
                    onChange={onChange}
                  />
                  <label> Proteção de interface presente</label>
                </div>
              </div>
            </div>

          </div>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group check">
                <div className="check-container check">
                  <input
                    type="checkbox"
                    name="device_interface_present"
                    ref={register}
                    onChange={onChange}
                  />
                  <label> Dispositivo de interface presente</label>
                </div>
              </div>
            </div>

            <div className="col-2">
              <div className="input-group check">
                <div className="check-container check">
                <input
                    type="checkbox"
                    name="transformer_isolation_integrated"
                    ref={register}
                    onChange={onChange}
                  />
                  <label> Transformador de isolamento integrado</label>
                </div>
              </div>
            </div>

          </div>

          <div className="row row-space">

            <div className="col-1">
              <div className="input-group">
                <label htmlFor="certifications_note">Notas</label>
                <input
                  name="certifications_note"
                  type="text"
                  placeholder="Informe as notas"
                  onChange={onChange}
                  ref={register}
                />
              </div>
            </div>

          </div> */}

          <h2 className="identificator">Informações sobre UPS</h2>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="material_code">Código principal</label>
                <input
                  name="material_code"
                  type="text"
                  placeholder="INV1273"
                  onChange={onChange}
                  ref={register}
                />
              </div>
            </div>

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="material_description">Descrição</label>
                <input
                  name="material_description"
                  type="text"
                  placeholder="Inverter Solar Family SF3000TL"
                  onChange={onChange}
                  ref={register}
                />
              </div>
            </div>

          </div>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="material_value_acquisition">Preço de compra</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  prefix={'R$ '}
                  placeholder="Ex.: R$ 0,00"
                  getInputRef={(props) =>
                    <input name="material_value_acquisition" type="text" ref={register}/>
                  } onValueChange={(values) => {
                    const {value} = values;
                    setInverter({...inverter, material_value_acquisition: value})}
                  }
                />

              </div>
            </div>

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="material_value_sale">Preço de venda</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  prefix={'R$ '}
                  placeholder="Ex.: R$ 0,00"
                  getInputRef={(props) =>
                    <input name="material_value_sale" type="text" ref={register}/>
                  } onValueChange={(values) => {
                    const {value} = values;
                    setInverter({...inverter, material_value_sale: value})}
                  }
                />
              </div>
            </div>

          </div>

          <h2 className="identificator">Garantia em anos</h2>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="warrant">Informe o tempo de garantia do inversor </label>
                <input
                  name="warrant"
                  type="text"
                  placeholder="25"
                  onChange={onChange}
                  ref={register}
                />
              </div>
            </div>

          </div>


          <div className="row row-space">
            <div className="col-1">
              <div className="input-group">
                <button type="submit">Salvar dados</button>
              </div>
            </div>
          </div>

        </form>
      </div>
    </div>
  );

}
