import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form'
import { default as NumberFormat } from 'react-number-format';

import { FiArrowLeft, FiPlusCircle } from 'react-icons/fi';
import { dealershipSaveRequest } from '~/store/modules/dealership/actions';

export default function AddDealership() {
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const [dealership, setDealership] = useState([]);

  const onChange = e => {
    setDealership({...dealership,
      [e.target.name]: e.target.value}
    );
  };

  function onSubmit(data) {
    dispatch(dealershipSaveRequest(dealership));
  }

  function calcValueWithTax(valueTaxInput){
    var tax = (valueTaxInput / 100) * dealership.value_kwh;
    var valueTax = parseFloat(dealership.value_kwh) - parseFloat(tax);
    setDealership({...dealership,
      icms: valueTaxInput,
      value_with_tax: valueTax.toFixed(2) }
    );
  }

  return (
    <div className="container">
      <div className="title">
        <h1>
          <FiPlusCircle size="19px"/> Cadastro de <span> concessionária </span>
        </h1>
        <Link to="/dealerships" className="button-add"><FiArrowLeft size="16px" color="#6159fd" />Voltar para listagem</Link>
      </div>

      <div className="painel">
        <form className="manager" onSubmit={handleSubmit(onSubmit)}>

          <h2>Preencha o formulário abaixo</h2>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="potential">Estado (Sigla)</label>
                <input name="state" type="text" ref={register} placeholder="PR" onChange={onChange} />
              </div>
            </div>
            <div className="col-2">
              <div className="input-group">
                <label htmlFor="dealership">Nome da concessionária</label>
                <input name="dealership" type="text" ref={register} placeholder="Copel" onChange={onChange} />
              </div>
            </div>
          </div>

          <div className="row row-space">

            <div className="col-3">
              <div className="input-group">
                <label htmlFor="value_kwh">Valor KWh</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  prefix={'R$ '}
                  placeholder="Ex.: R$ 0,00"
                  getInputRef={(props) =>
                    <input name="value_kwh" type="text" ref={register}/>
                  } onValueChange={(values) => {
                    const {value} = values;
                    setDealership({...dealership, value_kwh: value})}
                  }
                />
              </div>
            </div>

            <div className="col-3">
              <div className="input-group">
                <label htmlFor="icms">Valor do ICMS</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  placeholder="Ex.: 9,00 %"
                  suffix=" %"
                  getInputRef={(props) =>
                    <input name="icms" type="text" ref={register}/>
                  } onValueChange={(values) => {
                    const {value} = values;
                    calcValueWithTax(value)}
                  }
                />
              </div>
            </div>

            <div className="col-3">
              <div className="input-group">
                <label htmlFor="value_with_tax">Valor do KWh com imposto</label>
                <input type="text" name="value_with_tax" value={dealership.value_with_tax} disabled/>
              </div>
            </div>

          </div>

          <div className="row row-space">
            <div className="col-1">
              <div className="input-group">
                <button type="submit">Salvar dados</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

}
