import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form'

import { FiArrowLeft, FiPlusCircle } from 'react-icons/fi';

import api from '~/services/api' ;
import { cityRegionUpdateRequest } from '~/store/modules/city_region/actions';


export default function EditCityRegion() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const { register, handleSubmit, errors } = useForm();
  const [cityRegion, setCityRegion] = useState([]);
  const [region, setRegion] = useState([]);

  const onChange = e => {
    setCityRegion({...cityRegion,
      [e.target.name]: e.target.value}
    );
  };

  const EditCityRegion = () => {
    let { id } = useParams();
    return id;
  };
  const city_region_id = EditCityRegion();

  const onSubmit = data => {
    data.region_id = cityRegion.region_id;
    dispatch(cityRegionUpdateRequest(city_region_id, data));
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/cities-regions/${city_region_id}`);
        setCityRegion(response.data);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await api.get('regions');
        setRegion(response.data.data);
        setIsLoading(false);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="container">
      <div className="title">
        <h1>
          <FiPlusCircle size="19px"/> Editar <span> cidade por região </span>
        </h1>
        <Link to="/cities-regions" className="button-add"><FiArrowLeft size="16px" color="#6159fd" />Voltar para listagem</Link>
      </div>

      <div className="painel">

        <form onSubmit={handleSubmit(onSubmit)} className="manager">

          <h2>Preencha o formulário abaixo</h2>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="name">Nome da Cidade</label>
                <input name="name" type="text" ref={register({ required: true })} placeholder="Francisco Beltrão" defaultValue={cityRegion.name} />
                {errors.name && errors.name.type === 'required' && (
                  <span className="error-inputs">
                    Este campo é obrigatório
                  </span>
                )}
              </div>
            </div>

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="state">Região</label>
                <select name="region_id" ref={register({ required: true })} value={cityRegion.region_id} className="select capitalize" onChange={onChange}>
                  { isLoading ? (
                    <option>Carregando</option>
                  ) : (
                    region.map((value, index) => {
                      return <option value={value.id} key={index}>{value.state} {value.name ? (` - ${value.name}`) : ' - Todo estado' }</option>
                    })
                  )}
                  <option value="">Selecione uma região</option>
                </select>
                {errors.region_id && errors.region_id.type === 'required' && (
                  <span className="error-inputs">
                    Este campo é obrigatório
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="row row-space">
            <div className="col-1">
              <div className="input-group">
                <button type="submit">Salvar dados</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

}
