import React from 'react';
import { Switch, withRouter } from 'react-router-dom';
import Route from './Route';

import SignIn from '../pages/SignIn';

import ForgotPassword from '../pages/ForgotPassword';
import Dashboard from '../pages/Dashboard';

import Budget from '../pages/Budget';

import Users from '../pages/Users';
import CreateUser from '../pages/Users/add';
import EditUser from '../pages/Users/edit';

import Payments from '../pages/Payment';
import CreatePayment from '../pages/Payment/add';
import EditPayment from '../pages/Payment/edit';
import ImportPayment from '../pages/Payment/import';
import UpdatePayment from '../pages/Payment/update_payments';
import ListPayment from '../pages/Payment/list_payments';
import ListPaymentType from '../pages/Payment/list_payments_type';

import Boards from '../pages/Board';
import CreateBoard from '../pages/Board/add';
import EditBoard from '../pages/Board/edit';

import Inverters from '../pages/Inverter';
import CreateInverter from '../pages/Inverter/add';
import EditInverter from '../pages/Inverter/edit';

import Dealerships from '../pages/Dealership';
import CreateDealership from '../pages/Dealership/add';
import EditDealership from '../pages/Dealership/edit';

import Temperatures from '../pages/Temperature';
import CreateTemperature from '../pages/Temperature/add';
import EditTemperature from '../pages/Temperature/edit';

import Region from '../pages/Region';
import CreateRegion from '../pages/Region/add';
import EditRegion from '../pages/Region/edit';

import CityRegion from '../pages/CityRegion';
import CreateCityRegion from '../pages/CityRegion/add';
import EditCityRegion from '../pages/CityRegion/edit';

import DiscountRegion from '../pages/DiscountRegion';
import CreateDiscountRegion from '../pages/DiscountRegion/add';
import EditDiscountRegion from '../pages/DiscountRegion/edit';

import DiscountUser from '../pages/DiscountUser';
import CreateDiscountUser from '../pages/DiscountUser/add';
import EditDiscountUser from '../pages/DiscountUser/edit';

import Settings from '../pages/settings';

export default function Routes() {
  return (
    <Switch>
      {/* Main menu */}
      <Route path="/" exact component={withRouter(SignIn)} />
      <Route path="/forgot-password" component={withRouter(ForgotPassword)} />
      <Route path="/dashboard" component={withRouter(Dashboard)} isPrivate />
      <Route path="/users" component={withRouter(Users)} isPrivate />
      <Route path="/payments" component={withRouter(Payments)} isPrivate />
      <Route path="/boards" component={withRouter(Boards)} isPrivate />
      <Route path="/inverters" component={withRouter(Inverters)} isPrivate />
      <Route path="/settings" component={withRouter(Settings)} isPrivate />
      <Route path="/dealerships" component={withRouter(Dealerships)} isPrivate />
      <Route path="/temperatures" component={withRouter(Temperatures)} isPrivate />
      <Route path="/regions" component={withRouter(Region)} isPrivate />
      <Route path="/cities-regions" component={withRouter(CityRegion)} isPrivate />
      <Route path="/discount-regions" component={withRouter(DiscountRegion)} isPrivate />
      <Route path="/discount-users" component={withRouter(DiscountUser)} isPrivate />

      {/* Budget */}
      <Route path="/budget" component={withRouter(Budget)} isPrivate />

      {/* Manager system */}
      <Route path="/create-user" component={withRouter(CreateUser)} isPrivate />
      <Route path={"/edit-user/:id"} component={withRouter(EditUser)} isPrivate />

      <Route path="/create-payment" component={withRouter(CreatePayment)} isPrivate />
      <Route path="/edit-payment/:id" component={withRouter(EditPayment)} isPrivate />
      <Route path="/import-payment" component={withRouter(ImportPayment)} isPrivate />
      <Route path="/update-payments/:firstItem/:lastItem" component={withRouter(UpdatePayment)} isPrivate />
      <Route path="/list-payment/:price_state/:user_id/:region_id" component={withRouter(ListPayment)} isPrivate />
      <Route path="/list-payments-type/:board_id/:price_state/:user_id/:region_id" component={withRouter(ListPaymentType)} isPrivate />

      <Route path="/create-board" component={withRouter(CreateBoard)} isPrivate />
      <Route path="/edit-board/:id" component={withRouter(EditBoard)} isPrivate />

      <Route path="/create-inverter" component={withRouter(CreateInverter)} isPrivate />
      <Route path="/edit-inverter/:id" component={withRouter(EditInverter)} isPrivate />

      <Route path="/create-dealership" component={withRouter(CreateDealership)} isPrivate />
      <Route path="/edit-dealership/:id" component={withRouter(EditDealership)} isPrivate />

      <Route path="/create-temperature" component={withRouter(CreateTemperature)} isPrivate />
      <Route path="/edit-temperature/:id" component={withRouter(EditTemperature)} isPrivate />
      
      <Route path="/create-region" component={withRouter(CreateRegion)} isPrivate />
      <Route path="/edit-region/:id" component={withRouter(EditRegion)} isPrivate />
      
      <Route path="/create-city-region" component={withRouter(CreateCityRegion)} isPrivate />
      <Route path="/edit-city-region/:id" component={withRouter(EditCityRegion)} isPrivate />

      <Route path="/create-discount-region" component={withRouter(CreateDiscountRegion)} isPrivate />
      <Route path="/edit-discount-region/:id" component={withRouter(EditDiscountRegion)} isPrivate />
      
      <Route path="/create-discount-user" component={withRouter(CreateDiscountUser)} isPrivate />
      <Route path="/edit-discount-user/:id" component={withRouter(EditDiscountUser)} isPrivate />

      {/* <Route path="/" component={() => <h1>404</h1>} /> */}
    </Switch>
  );
}
