export function saveUserRequest(data) {
  return {
    type: '@user/SAVE_USER_REQUEST',
    payload: { data },
  };
}

export function saveUserSuccess(data) {
  return {
    type: '@user/SAVE_USER_SUCCESS',
    payload: { data },
  };
}

export function updateUserRequest(id, data) {
  return {
    type: '@user/UPDATE_USER_REQUEST',
    payload: { id, data },
  };
}

export function updateUserSuccess(profile) {
  return {
    type: '@user/UPDATE_USER_SUCCESS',
    payload: { profile },
  };
}

export function deleteUserRequest(user_id) {
  return {
    type: '@user/DELETE_USER_REQUEST',
    payload: { user_id },
  };
}

export function userFailure(data) {
  return {
    type: '@user/UPDATE_FAILURE',
  };
}
