import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font
} from "@react-pdf/renderer";


import Calibri from "./fonts/calibri.ttf"
import Calibrib from "./fonts/calibrib.ttf"
import logo from '~/assets/logo-relatorio.png';
import selo from '~/assets/selo.png';
import bg_rel from '~/assets/bg-relatorio.jpg';
import bg_servicos from '~/assets/bg-servicos-descricao.png';
import bg_servicos_equipamentos from '~/assets/bg-servicos-equipamentos.png';
import bg_servicos_servicos from '~/assets/bg-servicos-servicos.png';
import bg_servicos_garantia from '~/assets/bg-servicos-garantia.png';
import bg_servicos_geracao_estimada from '~/assets/bg-servicos-geracao-estimada.png';
import bg_servicos_economia_estimada from '~/assets/bg-servicos-economia-estimada.png';
import bg_servicos_preco_gerador from '~/assets/bg-servicos-preco.png';
import bg_servicos_viabilidade from '~/assets/bg-servicos-viabilidade.png';
import bg_servicos_pagamento from '~/assets/bg-servicos-pagamento.png';
import bg_servicos_validade_proposta from '~/assets/bg-servicos-validade-proposta.png';
import bg_servicos_footer from '~/assets/bg-servicos-footer.jpg';

const styles = StyleSheet.create({
  page_home: {
    backgroundColor: "transparent"
  },
  page: {
    backgroundColor: "#ffffff",
    padding: "20px 40px 0px 40px"
  },
  bg_view: {
    backgroundColor: "#f6f6f7"
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    backgroundColor: "#f6f6f7"
  },
  image: {
    height: "100%",
    width: "100%"
  },
  image_logo: {
    width: "100%",
    width: 200,
    position: 'absolute',
    top: 10,
    right: 0
  },
  info_client_name: {
    fontSize: 26,
    color: "#001D3A",
    marginBottom: 0,
    fontFamily: "Calibri-Bold",
  },
  info_client: {
    fontSize: 12,
    lineHeight: 1.1,
    fontFamily: "Calibri-Bold",
    color: "#001D3A",
  },
  header: {
    flexDirection: 'row',
  },
  section_header: {
    flexGrow: 1
  },
  section_title_principal: {
    fontFamily: "Calibri-Bold",
    color: "#001D3A",
    fontSize: 18,
    marginTop: 50,
    textAlign: "center"
  },
  section_title_garantia: {
    fontFamily: "Calibri-Bold",
    color: "#001D3A",
    fontSize: 18,
    marginTop: 10,
    marginLeft: "28%",
    position: 'relative', 
    zIndex: '9999'
  },
  section_title_geracao_div: {
    marginTop: 10,
  },
  section_title_geracao: {
    fontFamily: "Calibri",
    color: "#666",
    fontSize: 5,
    textAlign: "left",
  },
  section_title_geracao_economia_div: {
    marginTop: 10,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%"
  },
  section_title_economia: {
    fontFamily: "Calibri-Bold",
    color: "#333",
    fontSize: 12,
    textAlign: "left",
  },
  section_title_economia_big: {
    fontFamily: "Calibri-Bold",
    color: "#333",
    fontSize: 16,
    textAlign: "left",
  },
  section_title_economia_reajuste: {
    fontFamily: "Calibri-Bold",
    color: "#333",
    fontSize: 12,
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%"
  },
  section_title_preco_gerador_div: {
    marginTop: 50,
    marginBottom: 0,
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%"

  },
  section_title_preco_gerador: {
    fontFamily: "Calibri-Bold",
    color: "#333",
    fontSize: 12,
    textAlign: "left",
  },
  section_title_preco_gerador_big: {
    fontFamily: "Calibri-Bold",
    color: "#333",
    fontSize: 16,
    textAlign: "left",
  },
  section_title_preco_gerador_reajuste: {
    fontFamily: "Calibri-Bold",
    color: "#333",
    fontSize: 10,
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    marginTop: 2,
    marginBottom: 0,
  },
  title_section_view: {
    position: "relative",
    width: "100%",
  },
  title_section_bg: {
    position: "absolute",
    top: 10,
    width: "100%",
    height: 30,
    objectFit: "contain"
  },
  table: {
    marginTop: 50,
    display: "table",
    width: "100%",
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableNoMargin: {
    marginTop: 0,
    display: "table",
    width: "100%",
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row"
  },
  tableCol1: {
    width: 180,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCustomCol1: {
    width: 150,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol2: {
    width: 80,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCustomCol2: {
    width: 110,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol3: {
    width: 69,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol25: {
    width: "25%",
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol32: {
    width: "32%",
    margin: 2,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol: {
    width: 250,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableColBorder: {
    width: 250,
    borderTopWidth: 2,
    borderTopColor: "#f8dd00"
  },
  tableColEqui: {
    width: 205,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCellSmall: {
    fontFamily: "Calibri-Bold",
    margin: 0,
    fontSize: 7,
    textAlign: "center",
    textTransform: "uppercase"
  },
  tableCellSmallRegular: {
    fontFamily: "Calibri",
    fontSize: 7,
    textAlign: "center",
    textTransform: "uppercase"
  },
  tableCellTitle: {
    fontFamily: "Calibri-Bold",
    margin: "auto",
    margin: 4,
    fontSize: 11,
    textAlign: "right",
    textTransform: "uppercase"
  },
  tableCellTitleLeft: {
    fontFamily: "Calibri",
    margin: "auto",
    margin: 4,
    fontSize: 11,
    textAlign: "left",
  },
  tableCellTitleLeftStrong: {
    fontFamily: "Calibri-Bold",
    margin: "auto",
    margin: 4,
    fontSize: 11,
    textAlign: "left",
  },
  tableCellTitleTable: {
    fontFamily: "Calibri",
    margin: "auto",
    margin: 4,
    fontSize: 11,
    textAlign: "right",
    textTransform: "uppercase"
  },
  tableCellTitleCenter: {
    fontFamily: "Calibri-Bold",
    margin: "auto",
    margin: 4,
    fontSize: 8,
    textAlign: "center"
  },
  tableCellTitleStrong: {
    fontFamily: "Calibri-Bold",
    margin: "auto",
    margin: 4,
    fontSize: 11,
    textAlign: "right",
    textTransform: "uppercase"
  },
  tableCellTitleStrongLeft: {
    fontFamily: "Calibri-Bold",
    margin: "auto",
    margin: 4,
    fontSize: 11,
    textAlign: "left",
    textTransform: "uppercase"
  },
  tableCell: {
    fontFamily: "Calibri",
    margin: "auto",
    margin: 4,
    fontSize: 11
  },
  tableCellCenter: {
    fontFamily: "Calibri",
    margin: "auto",
    margin: 4,
    fontSize: 8.5,
    textAlign: "center"
  },
  tableCellRight: {
    fontFamily: "Calibri",
    margin: "auto",
    margin: 4,
    fontSize: 8.5,
    textAlign: "right"
  },
  img_selo: {
    position: "relative",
    width: "80px",
    top: "35%",
    left: "7%",
  },
  img_selo_img: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "80px",
    objectFit: "contain",
    height: 100,
    zIndex: 9999
  },
  section_title_validade: {
    fontFamily: "Calibri-Bold",
    marginTop: 50,
    fontSize: 18,
    marginLeft: "45%",
    position: "relative",
    top: "-35px"
  },
  section_title_viabilidade_texto_1: {
    fontFamily: "Calibri-Bold",
    marginTop: 50,
    fontSize: 13,
    textAlign: "left",
    marginLeft: 40
  },
  section_title_viabilidade: {
    fontFamily: "Calibri-Bold",
    marginTop: 10,
    fontSize: 10,
    textAlign: "left",
    marginLeft: 40
  },
  section_title_viabilidade_retorno: {
    fontFamily: "Calibri-Bold",
    marginTop: 10,
    marginBottom: 5,
    fontSize: 12,
    textAlign: "left",
    marginLeft: 40
  },
  image_logo_footer: {
    width: "100%",
    width: 80,
    position: 'absolute',
    bottom: 20,
    right: 30
  },
  footer: {
    position: "fixed",
    left: 0,
    bottom: 0,
    width: "116%",
    marginTop: 21,
    marginLeft: -40,
  },
  footerTop: {
    position: "fixed",
    left: 0,
    bottom: 0,
    width: "116%",
    marginTop: 7,
    marginLeft: -40,
  },
  footer2: {
    position: "fixed",
    left: 0,
    bottom: 0,
    width: "116%",
    marginTop: 18,
    marginLeft: -40,
  },
  footer3: {
    position: "fixed",
    left: 0,
    bottom: 0,
    width: "116%",
    marginTop: -20,
    marginLeft: -40,
  },
  footer3Top: {
    position: "fixed",
    left: 0,
    bottom: 0,
    width: "116%",
    // marginTop: -15,
    marginTop: 12,
    marginLeft: -40,
  }
});

Font.register({
  family: "Calibri",
  format: "truetype",
  src: Calibri
});
Font.register({
  family: "Calibri-Bold",
  format: "truetype",
  src: Calibrib
});

export function PdfDocument({data, state, preview}) {
  var datePT_BR;
  if (typeof state.created_at !== 'undefined') {
    var created_at = state.created_at;
    var retorno = created_at.split(" ");
    var newDate = retorno[0].split("-");
    var objDate = new Date(newDate[1] +"/"+ newDate[2] +"/"+ newDate[0]),
    locale = "pt-br",
    month = objDate.toLocaleString(locale, { month: "long" });
    datePT_BR = newDate[2] +" de "+ month +" de "+ newDate[0] +" às " + retorno[1];
  } else {
    var dt = new Date();
    var dia = dt.getDate();
    var mes = dt.getMonth();
    var ano = dt.getFullYear();
    var hora = dt.getHours();
    var min = (dt.getMinutes() < 10 ? '0' : '') + dt.getMinutes();
    locale = "pt-br";
    month = dt.toLocaleString(locale, { month: "long" });
    // datePT_BR = dia +" de "+ month +" de "+ ano +" às " + hora+":"+min;
    datePT_BR = dia +" de "+ month +" de "+ ano;
  }

  return (
    <Document>
      <Page style={styles.page_home}>
      <Image
        style={styles.image}
        // source={`${preview || bg_rel}`}
        source={`${bg_rel}`}
      />
      </Page>
      <Page style={styles.page}>
        <View style={styles.header}>
          <View style={styles.section_header}>
            <Text style={styles.info_client_name}>{state.client_name}</Text>
            <Text style={styles.info_client}>{state.client_city_rep} - {state.client_state}</Text>
            <Text style={styles.info_client}>{datePT_BR}</Text>
            <Text style={styles.info_client}>{state.client_phone}</Text>
          </View>
          <View style={styles.section_header}>
            <Image
              style={styles.image_logo}
              source={`${logo}`}
            />
          </View>
        </View>
        <View>
          <View>
            <Text style={styles.section_title_principal}>Gerador Fotovoltaico - {data.description.power_total} KWp</Text>
          </View>
          {/* Descrição */}
          <View style={styles.title_section_view}>
            <Image source={`${bg_servicos}`} style={styles.title_section_bg} />
          </View>
          <View>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellTitle}>POTÊNCIA TOTAL: </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{data.description.power_total} KWp</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellTitle}>PRODUÇÃO ENERGIA MÉDIA:</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{data.description.production_energy_month} KWh</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellTitle}>AUTOCONSUMO INSTANTÂNEO DA PRODUÇÃO:</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{data.description.self_consumption_snapshot_production} KWh</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellTitle}>PRODUÇÃO INJETADA NA REDE:</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{data.description.production_injected} KWh</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellTitle}>ÁREA TOTAL UTILIZADA:</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{data.description.area_total_used} m²</Text>
                </View>
              </View>
            </View>
          </View>

          {/* Equipamentos */}
          <View style={styles.title_section_view}>
            <Image source={`${bg_servicos_equipamentos}`} style={styles.title_section_bg} />
          </View>
          <View>
            <View style={styles.table}>

              <View style={styles.tableRow}>
                <View style={styles.tableColEqui}>
                  <Text style={styles.tableCellTitle}>PAINEL SOLAR: </Text>
                </View>
                <View style={styles.tableCol3}>
                  <Text style={styles.tableCell}>{data.equipment.board}</Text>
                </View>
                <View style={styles.tableCol3}>
                  <Text style={styles.tableCell}>{data.equipment.board_manufacturer}</Text>
                </View>
                <View style={styles.tableCol3}>
                  <Text style={styles.tableCell}>{data.equipment.board_qtde}</Text>
                </View>
              </View>

                {data.equipment.inverter.map((a, index) => {
                  return (
                    <View style={styles.tableRow}>
                      <View style={styles.tableColEqui}>
                        <Text style={styles.tableCellTitle}>INVERSOR: </Text>
                      </View>
                      <View style={styles.tableCol3}>
                        <Text style={styles.tableCell}>{a.inverter} KWp</Text>
                      </View>
                      <View style={styles.tableCol3}>
                        <Text style={styles.tableCell}>{a.inverter_manufacturer}</Text>
                      </View>
                      <View style={styles.tableCol3}>
                        <Text style={styles.tableCell}>{a.inverter_qtde} unidades</Text>
                      </View>
                    </View>
                  );
                }) }
              
              <View style={styles.tableRow}>
                <View style={styles.tableColEqui}>
                  <Text style={styles.tableCellTitle}>KIT FIXAÇÃO: </Text>
                </View>
                <View style={styles.tableCol3}>
                  <Text style={styles.tableCell}>{data.equipment.kit_fixation}</Text>
                </View>
                <View style={styles.tableCol3}>
                  <Text style={styles.tableCell}>{data.equipment.kit_fixation_description}</Text>
                </View>
                <View style={styles.tableCol3}>
                  <Text style={styles.tableCell}>{data.equipment.kit_fixation_value}</Text>
                </View>
              </View>
              {(data.equipment.inverter.length > '1') ? ( 
                <></>
                ) : (
                  <View style={styles.tableRow}>
                    <View style={styles.tableColEqui}>
                      <Text style={styles.tableCellTitle}>MÓDULO DE MONITORAMENTO WIFI: </Text>
                    </View>
                    <View style={styles.tableCol3}>
                      <Text style={styles.tableCell}>{data.equipment.kit_monitoring_wifi}</Text>
                    </View>
                    <View style={styles.tableCol3}>
                      <Text style={styles.tableCell}>{data.equipment.kit_monitoring_description_wifi}</Text>
                    </View>
                    <View style={styles.tableCol3}>
                      <Text style={styles.tableCell}>{data.equipment.kit_monitoring_value_wifi}</Text>
                    </View>
                  </View>
                )
              }
            </View>
          </View>

          {/* Serviços */}
          <View style={styles.title_section_view}>
            <Image source={`${bg_servicos_servicos}`} style={styles.title_section_bg} />
          </View>
          <View>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellTitle}>MATERIAL ELÉTRICO</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellTitleLeftStrong}>{data.services.material_electric}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellTitle}>MÃO DE OBRA INSTALAÇÃO</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellTitleLeftStrong}>{data.services.hand_work_installation}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellTitle}>PROJETO ELÉTRICO SISTEMA</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCellTitleLeftStrong}>{data.services.project_electric_system}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellTitle}>REGULARIZAÇÃO DISTRIBUIDORA</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellTitleLeftStrong}>{data.services.regularization_distributor}</Text>
                </View>
              </View>
            </View>
          </View>

          {/* Garantia */}
          <View style={styles.title_section_view}>
            <Image source={`${bg_servicos_garantia}`} style={styles.title_section_bg} />
          </View>
          <View>
            {<View style={styles.img_selo}>
              <Image source={`${selo}`} style={styles.img_selo_img} />
            </View>}
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellTitle}>PAINEL SOLAR: </Text>
                </View>
                <View style={styles.tableCustomCol1}>
                  <Text style={styles.tableCell}>{data.warrant.board_efficiency}</Text>
                </View>
                <View style={styles.tableCustomCol2}>
                  <Text style={styles.tableCell}>25 anos</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellTitle}>PAINEL SOLAR: </Text>
                </View>
                <View style={styles.tableCustomCol1}>
                  <Text style={styles.tableCell}>{data.warrant.board_defect}</Text>
                </View>
                <View style={styles.tableCustomCol2}>
                  <Text style={styles.tableCell}>{data.warrant.board_efficiency_time} anos</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellTitle}>INVERSOR: </Text>
                </View>
                <View style={styles.tableCustomCol1}>
                  <Text style={styles.tableCell}>{data.warrant.inverter_defect}</Text>
                </View>
                <View style={styles.tableCustomCol2}>
                  <Text style={styles.tableCell}>{data.warrant.inverter_defect_time}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellTitle}>INSTALAÇÃO: </Text>
                </View>
                <View style={styles.tableCustomCol1}>
                  <Text style={styles.tableCell}>DEFEITOS NAS INSTALAÇÕES</Text>
                </View>
                <View style={styles.tableCustomCol2}>
                  <Text style={styles.tableCell}>{data.warrant.installation}</Text>
                </View>
              </View>
              {/* <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellTitle}>GERAÇÃO DE ENERGIA: </Text>
                </View>
                <View style={styles.tableCustomCol1}>
                  <Text style={styles.tableCell}>95% EFICIÊNCIA</Text>
                </View>
                <View style={styles.tableCustomCol2}>
                  <Text style={styles.tableCell}>06 anos</Text>
                </View>
              </View> */}
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellTitle}></Text>
                </View>
                <View style={styles.tableCustomCol1}>
                  <Text style={styles.tableCell}></Text>
                </View>
                <View style={styles.tableCustomCol2}>
                  <Text style={styles.tableCell}></Text>
                </View>
              </View>
              <View>
                {/* <Text style={styles.section_title_garantia}>GARANTIA DE GERAÇÃO - {data.warrant.geration_efficiency_percentual}</Text> */}
                <Text style={styles.section_title_garantia}>{data.warrant.geration_efficiency_percentual}</Text>
              </View>
            </View>

          </View>
          <View style={styles.footer}>
            <Image source={`${bg_servicos_footer}`} />
            <Image
              style={styles.image_logo_footer}
              source={`${logo}`}
            />
          </View>

        </View>

      </Page>

      <Page style={styles.page}>
        {/* Geração estimada */}
        <View style={styles.title_section_view}>
          <Image source={`${bg_servicos_geracao_estimada}`} style={styles.title_section_bg} />
        </View>
        <View>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellTitle}></Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellTitleCenter}>GERAÇÃO TOTAL ENERGIA KWh</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellTitleCenter}>GERAÇÃO ENERGIA AUTOCONSUMO KWh</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellTitleCenter}>GERAÇÃO ENERGIA INJETADA KWh</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellRight}>JANEIRO</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.generation.janeiro.consumption_01}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.generation.janeiro.self_consumption}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.generation.janeiro.injected}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellRight}>FEVEREIRO</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.generation.fevereiro.consumption_02}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.generation.fevereiro.self_consumption}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.generation.fevereiro.injected}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellRight}>MARÇO</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.generation.marco.consumption_03}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.generation.marco.self_consumption}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.generation.marco.injected}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellRight}>ABRIL</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.generation.abril.consumption_04}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.generation.abril.self_consumption}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.generation.abril.injected}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellRight}>MAIO</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.generation.maio.consumption_05}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.generation.maio.self_consumption}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.generation.maio.injected}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellRight}>JUNHO</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.generation.junho.consumption_06}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.generation.junho.self_consumption}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.generation.junho.injected}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellRight}>JULHO</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.generation.julho.consumption_07}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.generation.julho.self_consumption}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.generation.julho.injected}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellRight}>AGOSTO</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.generation.agosto.consumption_08}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.generation.agosto.self_consumption}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.generation.agosto.injected}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellRight}>SETEMBRO</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.generation.setembro.consumption_09}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.generation.setembro.self_consumption}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.generation.setembro.injected}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellRight}>OUTUBRO</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.generation.outubro.consumption_10}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.generation.outubro.self_consumption}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.generation.outubro.injected}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellRight}>NOVEMBRO</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.generation.novembro.consumption_11}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.generation.novembro.self_consumption}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.generation.novembro.injected}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellRight}>DEZEMBRO</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.generation.dezembro.consumption_12}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.generation.dezembro.self_consumption}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.generation.dezembro.injected}</Text>
              </View>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol25}>
              <Text style={styles.tableCellTitleStrong}>TOTAL</Text>
            </View>
            <View style={styles.tableCol25}>
              <Text style={styles.tableCellCenter}>{data.generation.total_geration_energy_kwp}</Text>
            </View>
            <View style={styles.tableCol25}>
              <Text style={styles.tableCellCenter}>{data.generation.total_geration_energy_self_consumption_kwp}</Text>
            </View>
            <View style={styles.tableCol25}>
              <Text style={styles.tableCellCenter}>{data.generation.total_geration_injected_kwp}</Text>
            </View>
          </View>
          <View>
            <View style={styles.section_title_geracao_div}>
              <Text style={styles.section_title_geracao}>GERAÇÃO TOTAL = GERAÇÃO TOTAL SISTEMA FOTOVOLTAICO</Text>
              <Text style={styles.section_title_geracao}>GERAÇÃO ENERGIA AUTOCONSUMO = PARTE DA GERAÇÃO TOTAL QUE É CONSUMIDA INSTANTANEAMENTE PELOS EQUIPAMENTOS LIGADOS</Text>
              <Text style={styles.section_title_geracao}>GERAÇÃO ENERGIA INJETADA = PARTE DA GERAÇÃO TOTAL QUE NÃO É CONSUMIDA INSTANTANEAMENTE PELOS EQUIPAMENTOS LIGADOS E POR ISSO É INJETADA NA REDE DA DISTRIBUIDORA</Text>
            </View>
          </View>
        </View>

        {/* Economia estimada */}
        <View style={styles.title_section_view}>
          <Image source={`${bg_servicos_economia_estimada}`} style={styles.title_section_bg} />
        </View>
        <View>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellTitle}></Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellTitleCenter}>ECONOMIA TOTAL R$</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellTitleCenter}>GERAÇÃO ENERGIA AUTOCONSUMO R$</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellTitleCenter}>ECONOMIA ENERGIA INJETADA R$</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellRight}>JANEIRO</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.economy.janeiro.economy}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.economy.janeiro.economy_self_consumption}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.economy.janeiro.economy_injected}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellRight}>FEVEREIRO</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.economy.fevereiro.economy}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.economy.fevereiro.economy_self_consumption}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.economy.fevereiro.economy_injected}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellRight}>MARÇO</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.economy.marco.economy}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.economy.marco.economy_self_consumption}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.economy.marco.economy_injected}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellRight}>ABRIL</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.economy.abril.economy}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.economy.abril.economy_self_consumption}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.economy.abril.economy_injected}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellRight}>MAIO</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.economy.maio.economy}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.economy.maio.economy_self_consumption}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.economy.maio.economy_injected}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellRight}>JUNHO</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.economy.junho.economy}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.economy.junho.economy_self_consumption}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.economy.junho.economy_injected}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellRight}>JULHO</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.economy.julho.economy}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.economy.julho.economy_self_consumption}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.economy.julho.economy_injected}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellRight}>AGOSTO</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.economy.agosto.economy}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.economy.agosto.economy_self_consumption}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.economy.agosto.economy_injected}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellRight}>SETEMBRO</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.economy.setembro.economy}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.economy.setembro.economy_self_consumption}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.economy.setembro.economy_injected}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellRight}>OUTUBRO</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.economy.outubro.economy}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.economy.outubro.economy_self_consumption}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.economy.outubro.economy_injected}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellRight}>NOVEMBRO</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.economy.novembro.economy}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.economy.novembro.economy_self_consumption}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.economy.novembro.economy_injected}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellRight}>DEZEMBRO</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.economy.dezembro.economy}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.economy.dezembro.economy_self_consumption}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.economy.dezembro.economy_injected}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellTitleStrong}>TOTAL</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.economy.total_economy_energy_rs}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.economy.total_economy_geration_energy_self_consumption_kwp_rs}</Text>
              </View>
              <View style={styles.tableCol25}>
                <Text style={styles.tableCellCenter}>{data.economy.total_economy_injected_rs_with_tax}</Text>
              </View>
            </View>
          </View>
          <View>
            <View style={styles.section_title_geracao_div}>
              <Text style={styles.section_title_geracao}>ECONOMIA TOTAL = TOTAL DA ECONOMIA DO SISTEMA FOTOVOLTAICO EM R$.</Text>
              <Text style={styles.section_title_geracao}>ECONOMIA ENERGIA AUTOCONSUMO = PARTE DA ECONOMIA TOTAL RELATIVA AO AUTOCONSUMO DA ENERGIA GERADA. CADA KWH DE AUTOCONSUMO VALE O QUE SE PAGA PELA ENERGIA (R$ {data.viability.value_kwh}).</Text>
              <Text style={styles.section_title_geracao}>ESTE VALOR DESAPARECE DA SUA CONTA DE ENERGIA POIS VOCÊ GERA E CONSOME AUTOMATICAMENTE A SUA ENERGIA DEIXANDO DE CONSUMIR ENERGIA DA DISTRIBUIDORA A R$ {data.viability.value_kwh}/KWH.</Text>
              <Text style={styles.section_title_geracao}>ECONOMIA ENERGIA INJETADA = PARTE DA ECONOMIA TOTAL RELATIVA À ENERGIA INJETADA NA REDE DA DISTRIBUIDORA. CADA KWH DE ENERGIA INJETADA VALE O QUE VOCÊ PAGA PELO KWH SEM ICMS (R$ {data.viability.value_kwh_with_tax}). ESTE VALOR É O QUE VEM COMO DESCONTO NA SUA CONTA DE ENERGIA.</Text>
            </View>
          </View>
          <View>
            <View style={styles.section_title_geracao_economia_div}>
              <Text style={styles.section_title_economia}>TOTAL ECONOMIA ANUAL ESTIMADA: </Text>
              <Text style={styles.section_title_economia_big}>R$ {data.economy.total_economy_energy_rs}</Text>
            </View>
            <View>
              <Text style={styles.section_title_economia_reajuste}>REAJUSTE ANUAL DE ENERGIA: 5,00%</Text>
            </View>
          </View>
        </View>
        <View style={styles.footer2}>
          <Image source={`${bg_servicos_footer}`} />
          <Image
            style={styles.image_logo_footer}
            source={`${logo}`}
          />
        </View>
      </Page>

      <Page style={styles.page}>
        {/* Preço do Gerador */}
        <View style={styles.title_section_view}>
          <Image source={`${bg_servicos_preco_gerador}`} style={styles.title_section_bg} />
        </View>
        <View>
          <View style={styles.section_title_preco_gerador_div}>
            <Text style={styles.section_title_preco_gerador}>PREÇO DO GERADOR INSTALADO À VISTA: </Text>
            <Text style={styles.section_title_preco_gerador_big}>R$ {data.payment[0].discount_5_percent_delivery}</Text>
          </View>
          <View>
            {/* <Text style={styles.section_title_preco_gerador_reajuste}>PREÇO POR KWP: R$ {data.viability.price_generator} </Text> */}
            {/* <Text style={styles.section_title_preco_gerador_reajuste}>Preço gerador instalado à vista: R$ {data.viability.price_generator_in_cash} </Text> */}
            {/* <Text style={styles.section_title_preco_gerador_reajuste}>SEGURO ANUAL: R$ {data.payment[0].annual_insurance} </Text> */}
            {/* <Text style={styles.section_title_preco_gerador_reajuste}>SEGURO ANUAL: Um ano de seguro grátis. </Text> */}
          </View>
        </View>

        {/* Viabilidade */}
        <View style={styles.title_section_view}>
          <Image source={`${bg_servicos_viabilidade}`} style={styles.title_section_bg} />
        </View>
        <View>
          <Text style={styles.section_title_viabilidade_texto_1}>DADOS ECONÔMICOS DO SISTEMA: </Text>

          <View style={styles.tableNoMargin}>

            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellTitleLeft}>VALOR DO KWh - AUTOCONSUMO:</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellTitleLeft}>R$ {data.viability.value_kwh}</Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellTitleLeft}>VALOR DO KWh - CRÉDITO:</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellTitleLeft}>R$ {data.viability.value_kwh_with_tax} com imposto</Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellTitleLeft}>DEGRADAÇÃO ANUAL GERAÇÃO ENERGIA:</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellTitleLeft}>0,80%</Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellTitleLeft}>REAJUSTE ANUAL DE ENERGIA:</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellTitleLeft}>5,00%</Text>
              </View>
            </View>

          </View>
        </View>

        <View>
          <Text style={styles.section_title_viabilidade}>VIABILIDADE DO INVESTIMENTO: </Text>

          <View style={styles.tableNoMargin}>

            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellTitleLeft}>TEMPO DE RETORNO DO INVESTIMENTO:</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellTitleLeft}>{data.viability.time_return}</Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellTitleLeft}>ECONOMIA TOTAL EM 25 ANOS</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellTitleLeft}>R$ {data.total_roi}</Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellTitleLeft}>ECONOMIA TOTAL PRIMEIRO ANO</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellTitleLeft}>R$ {data.economy.total_economy_energy_rs}</Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellTitleLeft}>RENDIMENTO DO INVESTIMENTO</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellTitleLeft}>{data.viability.rentability}% a.m.</Text>
              </View>
            </View>

          </View>
        </View>

        <View>
          <Text style={styles.section_title_viabilidade_retorno}>TEMPO DE RETORNO DO INVESTIMENTO </Text>
        </View>
        <View style={styles.tableNoMargin}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol25}>

              <View style={styles.tableCell}>
                <View style={styles.tableNoMargin}>

                  <View style={styles.tableRow}>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmall}></Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmall}>ECONOMIA R$</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmall}>ACUMULADO R$</Text>
                    </View>
                  </View>

                  <View style={styles.tableRow}>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmall}>{data.roi[0].ano_0.ano}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[0].ano_0.economy}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[0].ano_0.accumulated}</Text>
                    </View>
                  </View>

                  <View style={styles.tableRow}>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmall}>{data.roi[1].ano_1.ano}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[1].ano_1.economy}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[1].ano_1.accumulated}</Text>
                    </View>
                  </View>

                  <View style={styles.tableRow}>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmall}>{data.roi[2].ano_2.ano}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[2].ano_2.economy}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[2].ano_2.accumulated}</Text>
                    </View>
                  </View>

                  <View style={styles.tableRow}>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmall}>{data.roi[3].ano_3.ano}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[3].ano_3.economy}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[3].ano_3.accumulated}</Text>
                    </View>
                  </View>

                  <View style={styles.tableRow}>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmall}>{data.roi[4].ano_4.ano}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[4].ano_4.economy}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[4].ano_4.accumulated}</Text>
                    </View>
                  </View>

                  <View style={styles.tableRow}>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmall}>{data.roi[5].ano_5.ano}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[5].ano_5.economy}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[5].ano_5.accumulated}</Text>
                    </View>
                  </View>

                  <View style={styles.tableRow}>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmall}>{data.roi[6].ano_6.ano}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[6].ano_6.economy}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[6].ano_6.accumulated}</Text>
                    </View>
                  </View>


                </View>
              </View>

            </View>
            <View style={styles.tableCol25}>

            <View style={styles.tableCell}>
                <View style={styles.tableNoMargin}>

                  <View style={styles.tableRow}>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmall}></Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmall}>ECONOMIA R$</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmall}>ACUMULADO R$</Text>
                    </View>
                  </View>

                  <View style={styles.tableRow}>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmall}>{data.roi[7].ano_7.ano}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[7].ano_7.economy}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[7].ano_7.accumulated}</Text>
                    </View>
                  </View>

                  <View style={styles.tableRow}>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmall}>{data.roi[8].ano_8.ano}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[8].ano_8.economy}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[8].ano_8.accumulated}</Text>
                    </View>
                  </View>

                  <View style={styles.tableRow}>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmall}>{data.roi[9].ano_9.ano}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[9].ano_9.economy}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[9].ano_9.accumulated}</Text>
                    </View>
                  </View>

                  <View style={styles.tableRow}>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmall}>{data.roi[10].ano_10.ano}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[10].ano_10.economy}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[10].ano_10.accumulated}</Text>
                    </View>
                  </View>

                  <View style={styles.tableRow}>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmall}>{data.roi[11].ano_11.ano}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[11].ano_11.economy}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[11].ano_11.accumulated}</Text>
                    </View>
                  </View>

                  <View style={styles.tableRow}>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmall}>{data.roi[12].ano_12.ano}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[12].ano_12.economy}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[12].ano_12.accumulated}</Text>
                    </View>
                  </View>

                  <View style={styles.tableRow}>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmall}>{data.roi[13].ano_13.ano}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[13].ano_13.economy}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[13].ano_13.accumulated}</Text>
                    </View>
                  </View>

                </View>
              </View>

            </View>
            <View style={styles.tableCol25}>

            <View style={styles.tableCell}>
                <View style={styles.tableNoMargin}>

                  <View style={styles.tableRow}>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmall}></Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmall}>ECONOMIA R$</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmall}>ACUMULADO R$</Text>
                    </View>
                  </View>

                  <View style={styles.tableRow}>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmall}>{data.roi[14].ano_14.ano}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[14].ano_14.economy}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[14].ano_14.accumulated}</Text>
                    </View>
                  </View>

                  <View style={styles.tableRow}>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmall}>{data.roi[15].ano_15.ano}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[15].ano_15.economy}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[15].ano_15.accumulated}</Text>
                    </View>
                  </View>

                  <View style={styles.tableRow}>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmall}>{data.roi[16].ano_16.ano}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[16].ano_16.economy}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[16].ano_16.accumulated}</Text>
                    </View>
                  </View>

                  <View style={styles.tableRow}>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmall}>{data.roi[17].ano_17.ano}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[17].ano_17.economy}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[17].ano_17.accumulated}</Text>
                    </View>
                  </View>

                  <View style={styles.tableRow}>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmall}>{data.roi[18].ano_18.ano}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[18].ano_18.economy}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[18].ano_18.accumulated}</Text>
                    </View>
                  </View>

                  <View style={styles.tableRow}>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmall}>{data.roi[19].ano_19.ano}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[19].ano_19.economy}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[19].ano_19.accumulated}</Text>
                    </View>
                  </View>

                  <View style={styles.tableRow}>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmall}>{data.roi[20].ano_20.ano}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[20].ano_20.economy}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[20].ano_20.accumulated}</Text>
                    </View>
                  </View>

                </View>
              </View>

            </View>
            <View style={styles.tableCol25}>

            <View style={styles.tableCell}>
                <View style={styles.tableNoMargin}>

                  <View style={styles.tableRow}>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmall}></Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmall}>ECONOMIA R$</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmall}>ACUMULADO R$</Text>
                    </View>
                  </View>

                  <View style={styles.tableRow}>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmall}>{data.roi[21].ano_21.ano}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[21].ano_21.economy}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[21].ano_21.accumulated}</Text>
                    </View>
                  </View>

                  <View style={styles.tableRow}>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmall}>{data.roi[22].ano_22.ano}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[22].ano_22.economy}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[22].ano_22.accumulated}</Text>
                    </View>
                  </View>

                  <View style={styles.tableRow}>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmall}>{data.roi[23].ano_23.ano}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[23].ano_23.economy}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[23].ano_23.accumulated}</Text>
                    </View>
                  </View>

                  <View style={styles.tableRow}>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmall}>{data.roi[24].ano_24.ano}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[24].ano_24.economy}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[24].ano_24.accumulated}</Text>
                    </View>
                  </View>
                  
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmall}>{data.roi[25].ano_25.ano}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[25].ano_25.economy}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[25].ano_25.accumulated}</Text>
                    </View>
                  </View>

                  <View style={styles.tableRow}>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmall}>Total</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.total_roi}</Text>
                    </View>
                    <View style={styles.tableCol32}>
                      <Text style={styles.tableCellSmallRegular}>{data.roi[25].ano_25.accumulated}</Text>
                    </View>
                  </View>


                </View>
              </View>

            </View>
          </View>
        </View>


        {/* Pagamento */}
        <View style={styles.title_section_view}>
          <Image source={`${bg_servicos_pagamento}`} style={styles.title_section_bg} />
        </View>
        <View>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellTitleStrongLeft}>VALOR TOTAL:</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellTitleStrongLeft}>R$ {data.payment[0].sales_price}</Text>
              </View>
            </View>
            { (data.payment[0].ten_plots_show !== 'false') ? (
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellTitleStrongLeft}>10 PARCELAS SEM JUROS DE:</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>R$ {data.payment[0].price_in_10}</Text>
                </View>
              </View>
            ) : (<></>) }
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellTitleStrongLeft}>VALOR À VISTA NO PEDIDO:</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>R$ {data.payment[0].discount_5_percent_delivery}</Text>
              </View>
            </View>
            {/* <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellTitleStrongLeft}>VALOR À VISTA ENTREGA EQUIPAMENTO:</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>3% de desconto</Text>
              </View>
            </View> */}
            { (data.payment[0].discount_friend > '0') ? (
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellTitleStrongLeft}>À VISTA PROMOÇÃO DESCONTO AMIGO:</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellTitleStrongLeft}>R$ {data.payment[0].discount_friend} - CONSULTE-NOS</Text>
                </View>
              </View>
            ) : (<></>) }
            {/* <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellTitleStrongLeft}>FINANCIAMENTO PRÉ-APROVADO:</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>SEM EXTRADA</Text>
              </View>
            </View> */}
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellTitleStrongLeft}></Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}></Text>
              </View>
            </View>
            { (data.payment[0].price_in_36 > '0') ? (
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellTitleLeft}>36 PARCELAS DE:</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>R$ {data.payment[0].price_in_36}</Text>
                </View>
              </View>
            ) : (<></>)}
            { (data.payment[0].price_in_48 > '0') ? (
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellTitleLeft}>48 PARCELAS DE:</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>R$ {data.payment[0].price_in_48}</Text>
                </View>
              </View>
            ) : (<></>)}
            { (data.payment[0].price_in_60 > '0') ? (
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellTitleLeft}>60 PARCELAS DE:</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>R$ {data.payment[0].price_in_60}</Text>
                </View>
              </View>
            ) : (<></>)}
            { (data.payment[0].seventy_two_plots_show !== 'false') ? (
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellTitleLeft}>72 PARCELAS DE:</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>R$ {data.payment[0].price_in_72}</Text>
                </View>
              </View>
            ) : (<></>) }
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellTitleStrongLeft}></Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}></Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellTitleStrongLeft}></Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}></Text>
              </View>
            </View>
            { <View style={styles.tableRow}>
              <View style={styles.tableColBorder}>
                <Text style={styles.tableCellTitleStrongLeft}>OBS:</Text>
              </View>
              <View style={styles.tableColBorder}>
                 <Text style={styles.tableCell}>Mediante consulta de crédito.</Text>
              </View>
            </View> }
          </View>
        </View>

        {/* Validade da Proposta */}
        <View style={styles.title_section_view}>
          <Image source={`${bg_servicos_validade_proposta}`} style={styles.title_section_bg} />
          <Text style={styles.section_title_validade}>05 dias após sua emissão</Text>
        </View>
          <View style={
            (data.payment[0].discount_friend > '0') ? ( 
              styles.footer3Top
            ): (
              styles.footer3
            )}>
          <Image source={`${bg_servicos_footer}`} />
          <Image
            style={styles.image_logo_footer}
            source={`${logo}`}
          />
        </View>

      </Page>

    </Document>
  );
}
