import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form'
import * as Yup from 'yup';
import { FiArrowLeft, FiPlusCircle } from 'react-icons/fi';

import { saveUserRequest } from '~/store/modules/user/actions';

const schema = Yup.object().shape({
  name: Yup.string()
    .required('Campo obrigatório.'),
  email: Yup.string()
    .email()
    .required('Campo obrigatório.'),
  password: Yup.string()
    .min(8, 'A senha deve possuir ao menos 8 caracteres.')
    .required('Campo obrigatório.'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'As senhas devem ser iguais.'),
  roles: Yup.string()
    .required('Campo obrigatório.'),
});

export default function AddBoard() {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm({
    validationSchema: schema
  });

  function onSubmit(data) {
    dispatch(saveUserRequest(data));
  }

  return (
    <div className="container">
      <div className="title">
        <h1>
          <FiPlusCircle size="19px"/> Cadastro de <span> usuário </span>
        </h1>
        <Link to="/users" className="button-add"><FiArrowLeft size="16px" color="#6159fd" />Voltar para listagem</Link>
      </div>

      <div className="painel">
        <form className="manager" onSubmit={handleSubmit(onSubmit)}>
          <h2>Preencha o formulário abaixo com os dados do usuário</h2>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="name">Nome do usuário</label>
                <input name="name" type="text" ref={register} placeholder="Ex.: Daniel " />
                {errors.name && <span>{errors.name.message}</span>}
              </div>
            </div>

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="email">E-mail</label>
                <input name="email" type="email" ref={register} placeholder="daniel@solbrasilenergia.com.br" />
                {errors.email && <span>{errors.email.message}</span>}
              </div>
            </div>
          </div>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="password">Senha</label>
                <input name="password" type="password" ref={register} placeholder="********" />
                {errors.password && <span>{errors.password.message}</span>}
              </div>
            </div>

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="confirm_password">Confirmação de senha</label>
                <input name="confirm_password" type="password" ref={register} placeholder="********" />
                {errors.confirm_password && <span>{errors.confirm_password.message}</span>}
              </div>
            </div>

          </div>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="roles">Nível de acesso</label>
                <select name="roles" ref={register}>
                  <option value="">Selecione</option>
                  <option value="admin">Administrador</option>
                  <option value="user">Manutenção</option>
                  <option value="agent">Representante</option>
                </select>
                {errors.roles && <span>{errors.roles.message}</span>}
              </div>
            </div>

          </div>

          <div className="row row-space">
            <div className="col-1">
              <div className="input-group">
                <button type="submit">Salvar dados</button>
              </div>
            </div>
          </div>
        </form>

      </div>
    </div>
  );

}
