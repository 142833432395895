import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form'

import { FiArrowLeft, FiPlusCircle } from 'react-icons/fi';

import api from '~/services/api' ;
import { regionUpdateRequest } from '~/store/modules/region/actions';


export default function EditRegion() {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();
  const [region, setRegion] = useState([]);

  const onChange = e => {
    setRegion({...region,
      [e.target.name]: e.target.value}
    );
  };

  const EditRegion = () => {
    let { id } = useParams();
    return id;
  };
  const region_id = EditRegion();

  const onSubmit = data => {
    data.state = region.state;
    dispatch(regionUpdateRequest(region_id, data));
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/regions/${region_id}`);
        setRegion(response.data);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="container">
      <div className="title">
        <h1>
          <FiPlusCircle size="19px"/> Editar <span> Região </span>
        </h1>
        <Link to="/regions" className="button-add"><FiArrowLeft size="16px" color="#6159fd" />Voltar para listagem</Link>
      </div>

      <div className="painel">

        <form onSubmit={handleSubmit(onSubmit)} className="manager">

          <h2>Preencha o formulário abaixo</h2>

          <div className="row row-space">

          <div className="col-2">
              <div className="input-group">
                <label htmlFor="state">Estado (Sigla)</label>
                <select name="state" ref={register({ required: true })} className="select" value={region.state} onChange={onChange}>
                  <option value="" className="placeholder" selected disabled>Selecione um estado</option>
                  <option value="ac">Acre</option>
                  <option value="al">Alagoas</option>
                  <option value="ap">Amapá</option>
                  <option value="am">Amazonas</option>
                  <option value="ba">Bahia</option>
                  <option value="ce">Ceará</option>
                  <option value="df">Distrito Federal</option>
                  <option value="es">Espírito Santo</option>
                  <option value="go">Goiás</option>
                  <option value="ma">Maranhão</option>
                  <option value="mt">Mato Grosso</option>
                  <option value="ms">Mato Grosso do Sul</option>
                  <option value="mg">Minas Gerais</option>
                  <option value="pa">Pará</option>
                  <option value="pb">Paraíba</option>
                  <option value="pr">Paraná</option>
                  <option value="pe">Pernambuco</option>
                  <option value="pi">Piauí</option>
                  <option value="rj">Rio de Janeiro</option>
                  <option value="rn">Rio Grande do Norte</option>
                  <option value="rs">Rio Grande do Sul</option>
                  <option value="ro">Rondônia</option>
                  <option value="rr">Roraima</option>
                  <option value="sc">Santa Catarina</option>
                  <option value="sp">São Paulo</option>
                  <option value="se">Sergipe</option>
                  <option value="to">Tocantins</option>
                </select>
                {errors.state && errors.state.type === 'required' && (
                  <span className="error-inputs">
                    Este campo é obrigatório
                  </span>
                )}
              </div>
            </div>
            <div className="col-2">
              <div className="input-group">
                <label htmlFor="name">Nome da região</label>
                <input name="name" type="text" ref={register({ required: true })} placeholder="Francisco Beltrão" defaultValue={region.name} />
                {errors.name && errors.name.type === 'required' && (
                  <span className="error-inputs">
                    Este campo é obrigatório
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="row row-space">
            <div className="col-1">
              <div className="input-group">
                <button type="submit">Salvar dados</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

}
