import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { default as NumberFormat } from 'react-number-format';
import axios from 'axios';
import Modal from '~/components/Modal';

import api from '~/services/api' ;
import { Link } from 'react-router-dom';
import { FiArrowLeft, FiPlusCircle, FiHelpCircle } from 'react-icons/fi';
import Select from 'react-select'
import ReactTooltip from "react-tooltip";

import { saveBudgetRequest } from '~/store/modules/budget/actions';

export default function Budget() {
  const profile = useSelector(state => state.user.profile);
  const dispatch = useDispatch();
  const [boards, setBoards] = useState([]);
  const [inversors, setInversors] = useState([]);
  const [dealerships, setDealerships] = useState([]);
  const [payments, setPayments] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoadingCidade, setIsLoadingCidade] = useState(true);
  const [isLoadingEstadoPayment, setIsLoadingEstadoPayment] = useState(true);
  const [budgetSave, setBudgetSave] = useState([]);
  const [fields, setFields] = useState([{ inversor:null, qtd: null }]);
  const [inversor, setInversor] = useState([]);
  const [count, setCount] = React.useState(0);

  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur"
  });
  const [ inputqtde, setInputQtde ] = React.useState('');
  const [ inputqtdevlr, setInputQtdeVlr ] = React.useState('');
  const [ cityRep, setCityRep ] = React.useState('');
  const [ isPaymentTable, setIsPaymentTable ] = useState(true);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#9e9e9e',
      minHeight: '45px',
      height: '45px',
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '45px',
      padding: '0 6px'
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '45px',
    }),
  };

  // Modal
  const [show, setShow] = useState(false);
  const openModal = () => setShow(true);
  const closeModal = () => setShow(false);

  const onChange = e => {
    setBudgetSave({...budgetSave,
      [e.target.name]: e.target.value}
    );

    if (e.target.name === 'board_id') {
      setInputQtde(e.target.value);
    }
    if (e.target.name === 'qtde_board') {
      setInputQtdeVlr(e.target.value);
    }
    if (e.target.name === 'client_city_rep') {
      setCityRep(e.target.value);
    }
    if (e.target.name === 'degree_slope') {
      if (e.target.value !== '') {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }

  };

  function onChangeFunc(optionSelected) {
    if (optionSelected !== null) {
      const name = this.name;
      const value = optionSelected.value;
      const label = optionSelected.label;
      setBudgetSave({...budgetSave,
        [name]: value}
      );
    }
  };
  
  function onRadioChange(e) {
    if (e.target.value !== true) {
      delete budgetSave.payment_id;
    } else {
      delete budgetSave.sales_price;
      delete budgetSave.cash_price_advance;
      delete budgetSave.cash_price_delivery;
      delete budgetSave.price_in_10;
      delete budgetSave.price_in_36;
      delete budgetSave.price_in_48;
      delete budgetSave.price_in_60;
    }
    setIsPaymentTable(e.target.value);
  };

  function handleChangeAddInverter(i, event, type) {
    const values = [...fields];
    if (type === 'input'){ 
      values[i].inversor = inversor;
      values[i].qtd = event.target.value;
    } else {
      if (values[i].qtd !== 0) {
        values[i].inversor = inversor;
        values[i].qtd = values[i].qtd;
      } else {
        values[i].inversor = inversor;
        values[i].qtd = 0;
      }
    }
    setFields(values);
    setBudgetSave({...budgetSave,
      inversors: fields
    });
  }

  function handleAdd() {
    const values = [...fields];
    values.push({ inversor:null, qtd: null });
    setFields(values);
  }

  const handleRemove = (i) => {
    const values = [...fields];
    const newValues = values.filter((item) => item.inversor !== i);
    setFields(newValues);
    document.getElementById('InputChangeQtdeRemove').focus(); 
  }

  const onSubmit = data => {
    data.client_phone = budgetSave.client_phone;
    data.inversors = fields;
    data.client_city_rep = budgetSave.client_city_rep;
    data.payment_id = budgetSave.payment_id;
    data.average_kwh = budgetSave.average_kwh;
    data.connection = budgetSave.connection;
    data.dealership_id = budgetSave.dealership_id;

    data.type_payment = isPaymentTable;
    data.sales_price = budgetSave.sales_price;
    data.cash_price_advance = budgetSave.cash_price_advance;
    data.cash_price_delivery = budgetSave.cash_price_delivery;
    data.price_in_10 = budgetSave.price_in_10;
    data.price_in_36 = budgetSave.price_in_36;
    data.price_in_48 = budgetSave.price_in_48;
    data.price_in_60 = budgetSave.price_in_60;

    setBudgetSave({...budgetSave,
      client_phone: data.client_phone,
      inversors: fields
    });
    dispatch(saveBudgetRequest(data));
  }

  function openModalOK() {
    budgetSave.client_city_rep = cityRep;

    openModal();
  }

  const calculateKwh = async () => {
    const post = {
      'client_lat': budgetSave.client_lat,
      'client_long': budgetSave.client_long
    }
    const temperatureMedia = await api.post('/temperatures/media', post);
    const response = await api.get(`boards/${inputqtde}`);
    var char_power_nominal = response.data.char_power_nominal;
    var kwhMonth = ((((char_power_nominal * temperatureMedia.data * 0.8) / 1000) * 30) * inputqtdevlr).toFixed();
    setCount(kwhMonth);

    setBudgetSave({...budgetSave,
      user_id: profile.id
    });

    paymentsByState();

  };

  const paymentSave = e => {
    const fetchPayment = async () => {
      try {
        await calculateKwh();
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchPayment();
  }

  const onBlur = e => {
    if (e.target.value !== '') {
      const city = e.target.value;
      async function fetchLatLong(data){
        let address = city +' '+ budgetSave.client_state;
        await axios.get("https://maps.googleapis.com/maps/api/geocode/json?address="+address+"&key=AIzaSyD5D5tR3ogyttcxgDPjDNQlBNMEy7-OQ38").then((response) => {
          console.log('Lat.: ' + response.data.results[0].geometry.location.lat);
          console.log('Lon.: ' + response.data.results[0].geometry.location.lng);

          setBudgetSave({...budgetSave,
            client_city: city,
            client_state: budgetSave.client_state,
            client_lat: response.data.results[0].geometry.location.lat,
            client_long: response.data.results[0].geometry.location.lng,
          });
        });
      }
      fetchLatLong()
    }

  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await api.get('boards');
        setBoards(response.data.data);
        setIsLoading(false);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchData();
  }, []);
  
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await api.post('temperatures/states-by-temperatures');
        setStates(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchData();
  }, []);

  const cityByTempetures = e => {
    const fetchCities = async () => {
      if (e.target.value !== '') {
        setIsLoadingCidade(true);
        try {
          const response = await api.get(`temperatures/findByCity/${e.target.value}`);
          setCities(response.data);
          setIsLoadingCidade(false);
        } catch (error) {
          console.log('Error: '+error);
        }
      }
    };
    fetchCities();
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await api.get('inversors');
        setInversors(response.data.data);
        setIsLoading(false);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await api.get('/dealerships/registers/all');
        setDealerships(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchData();
  }, []);

  const paymentsByState = e => {
    const fetchState = async () => {
      setIsLoadingEstadoPayment(true);
      try {
        const options = [];
        const response = await api.get(`payments/findByState/${budgetSave.client_state}/${inputqtde}/${inputqtdevlr}`);
        console.log(response);

        if (response.data.length > 0){
          response.data.forEach(function(value) {
            options.push({
              value: value.id,
              label: `Potência: ${value.potential} | Placa: ${value.board_name} | Qtde Placas: ${value.qtd_boards} | Valor de Venda: ${value.sales_price} | Tipo: ${(value.price_type === '0' ? "Padrão" : "Especial")}`
            });
          });
          setPayments(options);

        } else {

          const responsePayments = await api.get('payments/findByPaymentsNotState/all');
          responsePayments.data.forEach(function(value) {
            options.push({
              value: value.id,
              label: `Potência: ${value.potential} | Placa: ${value.board_name} | Qtde Placas: ${value.qtd_boards} | Valor de Venda: ${value.sales_price} | Tipo: ${(value.price_type === '0' ? "Padrão" : "Especial")}`
            });
          });
          setPayments(options);
        }
        
        setIsLoadingEstadoPayment(false);
      } catch (error) {
        console.log('Error: '+error);
      }
    }
    fetchState();
  }



  return (
    <div className="container">
      <div className="title">
        <h1>
          <FiPlusCircle size="19px"/> <span> Criar proposta </span>
        </h1>
        <Link to="/dashboard" className="button-add"><FiArrowLeft size="16px" color="#6159fd" />Voltar para dashboard</Link>
      </div>

      <div className="painel">

        <form onSubmit={handleSubmit(onSubmit)} className="manager">

          <input type="hidden" name="client_lat" ref={register} defaultValue={budgetSave.client_lat}/>
          <input type="hidden" name="client_long" ref={register} defaultValue={budgetSave.client_long}/>

          <h2>Informações do cliente</h2>

          <input name="user_id" type="hidden" ref={register} defaultValue={profile.id}/>

          <div className="row row-space">

            <div className="col-3">
              <div className="input-group">
                <label htmlFor="client_name">Nome do cliente</label>
                <input name="client_name" type="text" ref={register({ required: true, minLength: 4 })} placeholder="Ex.: Daniel" onChange={onChange} />
                {errors.client_name && <span>Nome do cliente é obrigatório</span>}
              </div>
            </div>
            <div className="col-3">
              <div className="input-group">
                <label htmlFor="client_email">E-mail</label>
                <input name="client_email" type="email" ref={register} placeholder="daniel@solbrasilenergia.com.br" onChange={onChange} />
              </div>
            </div>
            <div className="col-3">
              <div className="input-group">
                <label htmlFor="client_phone">Telefone</label>
                <NumberFormat
                  format="(##) #####-####"
                  mask="_"
                  placeholder="Ex.: (46) 00000 - 0000"
                  onChange={onChange}
                  name="client_phone"
                  ref={e => register({ name: "client_phone" })}
                  onValueChange={(values) => {
                    const {value} = values;
                    setBudgetSave({...budgetSave, client_phone: value})}
                  }
                  getInputRef={(props) =>
                    <input name="client_phone" type="text" ref={register({ required: true })} value={budgetSave.client_phone}/>
                  }
                />
                {errors.client_phone && <span>Telefone é obrigatório</span>}
              </div>
            </div>

          </div>

          <div className="row row-space">
            
          </div>
          
          <div className="row row-space">
            <div className="col-3">
              <div className="input-group">
                <label htmlFor="client_state">Estado</label>
                <select name="client_state" ref={register({ required: true })} onChange={onChange} onBlur={cityByTempetures} >
                  { isLoading ? (
                    <option>Carregando</option>
                  ) : (
                    states.map((value, index) => {
                      return <option value={value.state} key={index}>{value.state}</option>
                    })
                  )}
                  <option value="">Selecione um estado</option>
                </select>
                {errors.client_state && <span>Estado é obrigatório</span>}
              </div>
            </div>

            <div className="col-3">
              <div className="input-group">
                <label htmlFor="client_city" className="help">
                  Cidade do cálculo
                  <a
                    data-for="custom-event"
                    data-tip="Cidade do cálculo: é a cidade mais próxima da cidade da instalação ou a própria cidade da instalação."
                    data-event="click focus"
                  >
                    <FiHelpCircle size="16px" color="#6159fd" className="icon-help-message" /> 
                  </a>
                  <ReactTooltip id="custom-event" globalEventOff="click" /> 
                </label>
                <select 
                  name="client_city" 
                  ref={register({ required: true })}
                  onChange={onChange} 
                  onBlur={onBlur}
                >
                  { isLoadingCidade ? (
                    <option value="">Selecione uma cidade</option>
                  ) : (
                    cities.map((value, index) => {
                      return <option value={value.city} key={index}>{value.city}</option>
                    })
                  )}
                </select>
                <ReactTooltip />
                {errors.client_city && <span>Cidade do cálculo é obrigatório</span>}
              </div>
            </div>
            
            <div className="col-3">
              <div className="input-group">
                <label htmlFor="client_city" className="help">
                  Cidade da instalação
                  <a
                    data-for="custom-event"
                    data-tip="Cidade da instalação: é a cidade onde o sistema será instalado. Está será a cidade apresentada na proposta."
                    data-event="click focus"
                  >
                    <FiHelpCircle size="16px" color="#6159fd" className="icon-help-message" /> 
                  </a>
                  <ReactTooltip id="custom-event" globalEventOff="click" /> 
                </label>
                <input name="client_city_rep" type="text" ref={register({ required: true })} value={budgetSave.client_city_rep} placeholder="Ex.: Francisco Beltrão " onChange={onChange} />
                {errors.client_city_rep && <span>Cidade da instalação é obrigatório</span>}
              </div>
            </div>
          </div>
         
          <h2 className="identificator">Informações de conta de energia</h2>

          <div className="row row-space">
            <div className="col-2">
              <div className="input-group">
                <label htmlFor="value_kwh">Valor KWh</label>
                <input name="value_kwh" type="text" ref={register({ required: true })} placeholder="0.88" onChange={onChange} />
                {errors.value_kwh && <span>Valor KWh é obrigatório</span>}
              </div>
            </div>

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="dealership_id">Concessionária</label>
                <select name="dealership_id" ref={register({ required: true })} onChange={onChange} >
                  { isLoading ? (
                    <option>Carregando</option>
                  ) : (
                    dealerships.map((value, index) => {
                      return <option value={value.id} key={index}>{value.dealership}</option>
                    })
                  )}
                  <option value="">Selecione uma concessionária</option>
                </select>
                {errors.value_kwh && <span>Concessionária é obrigatório</span>}
              </div>
            </div>

          </div>
          
          <h2 className="identificator">Informações de materiais</h2>

          <div className="row row-space">
            <div className="col-2">
              <div className="input-group">
                <label htmlFor="name">Painel Solar</label>
                <select name="board_id" ref={register({ required: true })} onChange={onChange}>
                  { isLoading ? (
                    <option>Carregando</option>
                  ) : (
                    boards.map((value, index) => {
                      return <option value={value.id} key={index}>{value.name}</option>
                    })
                  )}
                  <option value="">Selecione um painel</option>
                </select>
                {errors.board_id && <span>Painel Solar é obrigatório</span>}
              </div>
            </div>

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="qtde_board">Quantidade</label>
                <input name="qtde_board" type="text" ref={register({ required: true })} placeholder="10" onChange={onChange} onBlur={paymentSave}/>
                <span className="totalGerationKwh">Total gerado pelas placas em 30 dias: <span className="valueKWH">{count}</span> aproximadamente.</span>
                {errors.qtde_board && <span>Quantidade é obrigatório</span>}
              </div>
            </div>

          </div>

          <div className="border-material">

            <h2>Quantidade de inversores</h2>

            <>
              <div className="action">
                <button type="button" className="add" onClick={() => handleAdd()}>Adicionar mais inversor</button>
              </div>
              {fields.map((field, idx) => {
                return (
                  <div key={`${field}-${idx}`}>
                    <div className="row row-space">
                      <div className="col-2">
                        <div className="input-group">
                          <label htmlFor={`inversor_id-${idx}`}>Inversor</label>
                          <select name={`inversor_id-${idx}`} ref={register} value={field.inversor} onChange={e => setInversor(e.target.value)} onBlur={e => handleChangeAddInverter(idx, e, 'select')} >
                            <option value="">Selecione um inversor</option>
                            { isLoading ? (
                              <option>Carregando</option>
                            ) : (
                              inversors.map((value, index) => {
                                return (
                                  <option value={value.id} key={index} selected={field.inversor === value.id}>{value.name}</option>
                                )
                              })
                            )}
                            
                          </select>
                        </div>
                      </div>

                      <div className="col-2">
                        <div className="input-group">
                          <label htmlFor={`qtde_inverter-${idx}`}>Quantidade</label>
                          <input name={`qtde_inverter-${idx}`} type="text" ref={register} placeholder="1" onBlur={e => handleChangeAddInverter(idx, e, 'input')} id="InputChangeQtdeRemove"/>
                        </div>
                      </div>

                      <div className="action">
                        <button type="button" className="remove" onClick={() => handleRemove(field.inversor)}>Remover inversor</button>
                      </div>
                    </div>
                  </div>
                );
              })}

            </>


          </div>

          <h2 className="identificator">Orientação e Inclinação</h2>

          <div className="row row-space">
           <div className="col-3">
              <div className="input-group">
                <label htmlFor="correction_factor" className="help">
                  Fator correção
                  <a
                    data-for="custom-event"
                    data-tip="O fator correção somente poderá ser alterado mediante aprovação técnica."
                    data-event="click focus"
                  >
                    <FiHelpCircle size="16px" color="#6159fd" className="icon-help-message" /> 
                  </a>
                  <ReactTooltip id="custom-event" globalEventOff="click" /> 
                </label>
                <input name="correction_factor" type="text" ref={register} placeholder="0.98" onChange={onChange} className="correctionFactor"/>
              </div>
            </div>

            <div className="col-3">
              <div className="input-group">
                <label htmlFor="loss_factor" className="help">
                  Fator de perdas
                  <a
                    data-for="custom-event"
                    data-tip="O fator perda será utilizado no calculos onde deve ser considerado perdas por radiação. <br />Deixe 0 para não considerar perdas, informe um valor em % e ele será usado no cálculo."
                    data-event="click focus"
                  >
                    <FiHelpCircle size="16px" color="#6159fd" className="icon-help-message" /> 
                  </a>
                  <ReactTooltip id="custom-event" globalEventOff="click"  multiline={true} /> 
                </label>
                <input name="loss_factor" id="loss_factor" type="text" ref={register({ required: true })} placeholder="10%" onChange={onChange}/>
                {errors.loss_factor && <span>Fator de perdas é obrigatório</span>}
              </div>
            </div>

            <div className="col-3">
              <div className="input-group">
                <label htmlFor="degree_slope">Grau de inclinação</label>
                <input
                  name="degree_slope"
                  type="text"
                  placeholder="Ex.: 5"
                  onChange={onChange}
                  ref={register({ required: true })}
                />
                {errors.degree_slope && <span>Grau de inclinação é obrigatório</span>}
              </div>
            </div>
          </div>

          <h2 className="identificator">Formas de pagamento</h2>

          <ul className="custom-radio">
            <li>
              <label>
                <input
                  type="radio"
                  value="true"
                  name="type_payment"
                  onChange={onRadioChange}
                />
                Tabela de Pagamento
              </label>
            </li>

            <li>
              <label>
                <input
                  type="radio"
                  value="false"
                  name="type_payment"
                  onChange={onRadioChange}
                />
                Informar manual
              </label>
            </li>

          </ul>
          
          {isPaymentTable === 'true' && 

            <div className="row row-space">
              <div className="col-1">
                <div className="input-group">
                  { isLoadingEstadoPayment ? (
                    <span>Carregando</span>
                  ) : (
                    <Select 
                      options={payments} 
                      name="payment_id" 
                      placeholder="Selecione uma forma de pagamento" 
                      className="basic-single"
                      classNamePrefix="select"
                      isSearchable="true"
                      isClearable="true"
                      onChange={onChangeFunc}
                      styles={customStyles}
                    />
                  )}
                </div>
              </div>
            </div>
          }

          {isPaymentTable === 'false' && 
            <>
              <div className="row row-space">

                <div className="col-3">
                  <div className="input-group">
                    <label htmlFor="sales_price">Preço de venda</label>
                    <NumberFormat
                      decimalSeparator={","}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      thousandSeparator={"."}
                      prefix={'R$ '}
                      placeholder="Ex.: R$ 0,00"
                      getInputRef={(props) =>
                        <input name="sales_price" type="text" ref={register}/>
                      } onValueChange={(values) => {
                        const {value} = values;
                        setBudgetSave({...budgetSave, sales_price: value})}
                      }
                    />
                  </div>
                </div>

                <div className="col-3">
                  <div className="input-group">
                    <label htmlFor="cash_price_advance">Preço à vista (adiant.)</label>
                    <NumberFormat
                      decimalSeparator={","}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      thousandSeparator={"."}
                      prefix={'R$ '}
                      placeholder="Ex.: R$ 0,00"
                      getInputRef={(props) =>
                        <input name="cash_price_advance" type="text" ref={register}/>
                      } onValueChange={(values) => {
                        const {value} = values;
                        setBudgetSave({...budgetSave, cash_price_advance: value})}
                      }
                    />
                  </div>
                </div>

                <div className="col-3">
                  <div className="input-group">
                    <label htmlFor="cash_price_delivery">Preço à vista (entrega)</label>
                    <NumberFormat
                      decimalSeparator={","}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      thousandSeparator={"."}
                      prefix={'R$ '}
                      placeholder="Ex.: R$ 0,00"
                      getInputRef={(props) =>
                        <input name="cash_price_delivery" type="text" ref={register}/>
                      } onValueChange={(values) => {
                        const {value} = values;
                        setBudgetSave({...budgetSave, cash_price_delivery: value})}
                      }
                    />
                  </div>
                </div>

              </div>

              <h2>Preço venda - Sol Brasil</h2>

              <div className="row row-space">

                <div className="col-2">
                  <div className="input-group">
                    <label htmlFor="price_in_10">10x sem juros</label>
                    <NumberFormat
                      decimalSeparator={","}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      thousandSeparator={"."}
                      prefix={'R$ '}
                      placeholder="Ex.: R$ 0,00"
                      getInputRef={(props) =>
                        <input name="price_in_10" type="text" ref={register}/>
                      } onValueChange={(values) => {
                        const {value} = values;
                        setBudgetSave({...budgetSave, price_in_10: value})}
                      }
                    />
                  </div>
                </div>

              </div>

              <h2>Preço financiadoras</h2>

              <div className="row row-space">

                <div className="col-3">
                  <div className="input-group">
                    <label htmlFor="price_in_36">36x</label>
                    <NumberFormat
                      decimalSeparator={","}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      thousandSeparator={"."}
                      prefix={'R$ '}
                      placeholder="Ex.: R$ 0,00"
                      getInputRef={(props) =>
                        <input name="price_in_36" type="text" ref={register}/>
                      } onValueChange={(values) => {
                        const {value} = values;
                        setBudgetSave({...budgetSave, price_in_36: value})}
                      }
                    />
                  </div>
                </div>

                <div className="col-3">
                  <div className="input-group">
                    <label htmlFor="price_in_48">48x</label>
                    <NumberFormat
                      decimalSeparator={","}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      thousandSeparator={"."}
                      prefix={'R$ '}
                      placeholder="Ex.: R$ 0,00"
                      getInputRef={(props) =>
                        <input name="price_in_48" type="text" ref={register}/>
                      } onValueChange={(values) => {
                        const {value} = values;
                        setBudgetSave({...budgetSave, price_in_48: value})}
                      }
                    />
                  </div>
                </div>

                <div className="col-3">
                  <div className="input-group">
                    <label htmlFor="price_in_60">60x</label>
                    <NumberFormat
                      decimalSeparator={","}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      thousandSeparator={"."}
                      prefix={'R$ '}
                      placeholder="Ex.: R$ 0,00"
                      getInputRef={(props) =>
                        <input name="price_in_60" type="text" ref={register}/>
                      } onValueChange={(values) => {
                        const {value} = values;
                        setBudgetSave({...budgetSave, price_in_60: value})}
                      }
                    />
                  </div>
                </div>

                <div className="col-3">
                  <div className="input-group">
                    <label htmlFor="price_in_72">72x</label>
                    <NumberFormat
                      decimalSeparator={","}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      thousandSeparator={"."}
                      prefix={'R$ '}
                      placeholder="Ex.: R$ 0,00"
                      getInputRef={(props) =>
                        <input name="price_in_72" type="text" ref={register}/>
                      } onValueChange={(values) => {
                        const {value} = values;
                        setBudgetSave({...budgetSave, price_in_72: value})}
                      }
                    />
                  </div>
                </div>

              </div>
            </>
          }
          
          <div className="row row-space">
            <div className="col-1">
              <div className="input-group">
                <button type="submit">Salvar proposta</button>
                { isDisabled ? (
                  <button type="button" disabled className="button-print disabled">Visualizar proposta</button>
                ) : (
                  <button type="button" onClick={openModalOK} className="button-print">Visualizar proposta</button>
                )}
              </div>

            </div>

          </div>

        </form>

        { show ? (
          <Modal closeModal={closeModal} show={show} data={budgetSave} />
        ) : (<></>)}

      </div>
    </div>
  );

}
