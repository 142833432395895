import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { temperatureUpdateSuccess } from './actions';

export function* temperatureSave({ payload }) {
  try {
    const response = yield call(api.post, 'temperatures', payload.data);
    toast.success(response.data);
  } catch (error) {
    toast.error('Não foi possível salvar os dados. Contacte o administrador!');
  }
}

export function* temperatureUpdate({ id, payload }) {
  try {
    const response = yield call (api.put, `temperatures/${payload.id}`, payload.data);
    toast.success('Temperatura atualizada com sucesso!');
    yield put(temperatureUpdateSuccess(response.data));
  } catch (error) {
    toast.error('Não foi possível atualizar a temperatura. Contacte o administrador.');
  }
}

export function* temperatureDelete({ payload }) {
  try {
    yield call (api.delete, `temperatures/${payload.temperature_id}`);
    toast.success('Temperatura excluída com sucesso!');
  } catch (error) {
    toast.error('Não foi possível excluir a temperatura. Contacte o administrador.');
  }
}

export default all([
  takeLatest('@temperature/TEMPERATURE_SAVE_REQUEST', temperatureSave),
  takeLatest('@temperature/TEMPERATURE_UPDATE_REQUEST', temperatureUpdate),
  takeLatest('@temperature/TEMPERATURE_DELETE_REQUEST', temperatureDelete),
]);
