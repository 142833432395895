import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { discountRegionUpdateSuccess } from './actions';

export function* discountRegionSave({ payload }) {
  try {
    const response = yield call(api.post, 'discount-regions', payload.data);
    toast.success(response.data);
  } catch (error) {
    toast.error('Não foi possível salvar os dados. Contacte o administrador!');
  }
}

export function* discountRegionUpdate({ id, payload }) {
  try {
    const response = yield call (api.put, `discount-regions/${payload.id}`, payload.data);
    toast.success('Desconto atualizado com sucesso!');
    yield put(discountRegionUpdateSuccess(response.data));
  } catch (error) {
    toast.error('Não foi possível atualizar o desconto. Contacte o administrador.');
  }
}

export function* discountRegionDelete({ payload }) {
  try {
    yield call (api.delete, `discount-regions/${payload.discount_region_id}`);
    toast.success('Desconto excluído com sucesso!');
  } catch (error) {
    toast.error('Não foi possível excluir o desconto. Contacte o administrador.');
  }
}

export default all([
  takeLatest('@discountRegion/DISCOUNT_REGION_SAVE_REQUEST', discountRegionSave),
  takeLatest('@discountRegion/DISCOUNT_REGION_UPDATE_REQUEST', discountRegionUpdate),
  takeLatest('@discountRegion/DISCOUNT_REGION_DELETE_REQUEST', discountRegionDelete),
]);
