import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { saveUserSuccess, updateUserSuccess, userFailure } from './actions';

export function* updateProfile({ id, payload }) {
  try {
    const response = yield call (api.put, `users/${payload.id}`, payload.data);
    toast.success('Perfil atualizado com sucesso!');
    yield put(updateUserSuccess(response.data));
  } catch (error) {
    toast.error('Não foi possível atualizar perfil, confira seus dados');
    yield put(userFailure());
  }
}

export function* saveProfile({ payload }) {
  try {
    const response = yield call (api.post, 'users', payload.data);
    toast.success('Usuário cadastrado com sucesso!');
    yield put(saveUserSuccess(response.data));
  } catch (error) {
    toast.error('Não foi possível cadastrar o usuário, confira seus dados');
    yield put(userFailure());
  }
}

export function* deleteProfile({ payload }) {
  try {
    yield call (api.delete, `user/${payload.user_id}`);
    toast.success('Usuário excluído com sucesso!');
  } catch (error) {
    toast.error('Não foi possível excluir o usuário, contacte o administrador.');
    yield put(userFailure());
  }
}

export default all([
  takeLatest('@user/SAVE_USER_REQUEST', saveProfile),
  takeLatest('@user/UPDATE_USER_REQUEST', updateProfile),
  takeLatest('@user/DELETE_USER_REQUEST', deleteProfile),
]);
