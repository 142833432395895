export function saveBudgetRequest(data) {
  return {
    type: '@budget/SAVE_BUDGET_REQUEST',
    payload: { data },
  };
}

export function saveBudgetSuccess(budget) {
  return {
    type: '@budget/SAVE_BUDGET_SUCCESS',
    payload: budget,
  };
}

export function generateBudgetRequest(data) {
  return {
    type: '@budget/GENERATE_BUDGET_REQUEST',
    payload: { data },
  };
}

export function generateBudgetSuccess(budget) {
  return {
    type: '@budget/GENERATE_BUDGET_SUCCESS',
    payload: budget,
  };
}


export function getBudgetRequest(data) {
  return {
    type: '@budget/GET_BUDGET_REQUEST',
    payload: { data },
  };
}

export function getBudgetSuccess(budget) {
  return {
    type: '@budget/GET_BUDGET_SUCCESS',
    payload: budget,
  };
}

export function budgetFailure() {
  return {
    type: '@budget/BUDGET_FAILURE',
  };
}
