import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form'

import api from '~/services/api' ;
import { FiArrowLeft, FiUpload } from 'react-icons/fi';
import { paymentImportRequest } from '~/store/modules/payment/actions';

export default function ImportPayment() {
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [boards, setBoards] = useState([]);
  const [states, setStates] = useState([]);
  const [users, setUsers] = useState([]);
  const [regions, setRegions] = useState([]);

  function onSubmit(data) {
    // console.log(data);
    data.price_state = 0;
    data.user_id = null;
    data.region_id  = null;
    dispatch(paymentImportRequest(data));
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await api.get('boards');
        setBoards(response.data.data);
        setIsLoading(false);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await api.post('temperatures/states-by-temperatures');
        setStates(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await api.get('/users/results/all');
        setUsers(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await api.get('/regions/results/all');
        setRegions(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log('Error: '+error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="container">
      <div className="title">
        <h1>
          <FiUpload size="19px"/> Importar <span> forma de pagamento </span>
        </h1>
        <Link to="/payments" className="button-add"><FiArrowLeft size="16px" color="#6159fd" />Voltar para listagem</Link>
      </div>

      <div className="painel">
        <form className="manager" onSubmit={handleSubmit(onSubmit)}>

          <h2>Selecione o arquivo (.xls ou .xlsx)</h2>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <input name="file_import" type="file" ref={register} />
              </div>
            </div>
          </div>
          <br /><br />

          {/* <h2 className="identificator">Tipo tabela de preço</h2>

          <div className="row row-space">

            <div className="col-1">
              <div className="input-group">
                <label htmlFor="price_state">Selecione o Estado</label>
                <div className="check-container">
                  { isLoading ? (
                    <span>Carregando</span>
                  ) : (
                    states.map((value, index) => {
                      return <div className="checkbox-import"><input type="checkbox" name="price_state" ref={register} value={value.state} key={index}/> <label> {value.state} </label></div>
                    })
                  )}
                </div>
                <span className="totalGerationKwh">Selecione um ou mais estado para aplicar uma tabela de preço diferenciada, senão deixe em branco para aplicar tabela de preço para todos os estados.</span>
              </div>
            </div>

          </div> */}


          <div className="row row-space">

            <div className="col-1">
              <div className="input-group">
                <label htmlFor="price_type">Tabela de preço</label>
                <select name="price_type" ref={register} >
                  <option value="">Selecione o tipo do preço</option>
                  <option value="0">Padrão</option>
                  <option value="1">Especial</option>
                  <option value="2">Plus</option>
                </select>
              </div>
            </div>

          </div>

          <div className="row row-space">
            <div className="col-1">
              <div className="input-group">
                <label htmlFor="board_id">Painel Solar</label>
                <select name="board_id" ref={register} >
                  { isLoading ? (
                    <option>Carregando</option>
                  ) : (
                    boards.map((value, index) => {
                      return <option value={value.id} key={index}>{value.name}</option>
                    })
                  )}
                  <option value="">Selecione um painel</option>
                </select>
              </div>
            </div>
          </div>
          
          {/* <div className="row row-space">
            <div className="col-1">
              <div className="input-group">
                <label htmlFor="user_id">Usuário</label>
                <select name="user_id" ref={register} >
                  { isLoading ? (
                    <option>Carregando</option>
                  ) : (
                    users.map((value, index) => {
                      return <option value={value.id} key={index}>{value.name}</option>
                    })
                  )}
                  <option value="">Selecione um usuário</option>
                </select>
              </div>
            </div>
          </div>

          <div className="row row-space">
            <div className="col-1">
              <div className="input-group">
                <label htmlFor="region_id">Região</label>
                <select name="region_id" ref={register} className="select capitalize">
                  { isLoading ? (
                    <option>Carregando</option>
                  ) : (
                    regions.map((value, index) => {
                      return <option value={value.id} key={index}>{value.state} - {value.name}</option>
                    })
                  )}
                  <option value="">Selecione uma região</option>
                </select>
              </div>
            </div>
          </div> */}


          <div className="row row-space">
            <div className="col-1">
              <div className="input-group">
                <button type="submit">Importar dados</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

}
