export function cityRegionSaveRequest(data) {
  return {
    type: '@cityRegion/CITY_REGION_SAVE_REQUEST',
    payload: { data },
  };
}

export function cityRegionSaveSuccess(data) {
  return {
    type: '@cityRegion/CITY_REGION_SAVE_SUCCESS',
    payload: { data },
  };
}

export function cityRegionUpdateRequest(id, data) {
  return {
    type: '@cityRegion/CITY_REGION_UPDATE_REQUEST',
    payload: { id, data },
  };
}

export function cityRegionUpdateSuccess() {
  return {
    type: '@cityRegion/CITY_REGION_UPDATE_SUCCESS',
  };
}

export function cityRegionDeleteRequest(city_region_id) {
  return {
    type: '@cityRegion/CITY_REGION_DELETE_REQUEST',
    payload: { city_region_id },
  };
}
