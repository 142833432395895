import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { dealershipUpdateSuccess } from './actions';

export function* dealershipSave({ payload }) {
  try {
    const response = yield call(api.post, 'dealerships', payload.data);
    toast.success(response.data);
  } catch (error) {
    toast.error('Não foi possível salvar os dados. Contacte o administrador!');
  }
}

export function* dealershipUpdate({ id, payload }) {
  try {
    const response = yield call (api.put, `dealerships/${payload.id}`, payload.data);
    toast.success('Concessionária atualizada com sucesso!');
    yield put(dealershipUpdateSuccess(response.data));
  } catch (error) {
    toast.error('Não foi possível atualizar concessionária. Contacte o administrador.');
  }
}

export function* dealershipDelete({ payload }) {
  try {
    yield call (api.delete, `dealerships/${payload.dealership_id}`);
    toast.success('Concessionária excluída com sucesso!');
  } catch (error) {
    toast.error('Não foi possível excluir a concessionária. Contacte o administrador.');
  }
}

export default all([
  takeLatest('@dealership/DEALERSHIP_SAVE_REQUEST', dealershipSave),
  takeLatest('@dealership/DEALERSHIP_UPDATE_REQUEST', dealershipUpdate),
  takeLatest('@dealership/DEALERSHIP_DELETE_REQUEST', dealershipDelete),
]);
