export function inverterSaveRequest(data) {
  return {
    type: '@inverter/INVERTER_SAVE_REQUEST',
    payload: { data },
  };
}

export function inverterSaveSuccess(data) {
  return {
    type: '@inverter/INVERTER_SAVE_SUCCESS',
    payload: { data },
  };
}

export function inverterUpdateRequest(id, data) {
  return {
    type: '@inverter/INVERTER_UPDATE_REQUEST',
    payload: { id, data },
  };
}

export function inverterUpdateSuccess(profile) {
  return {
    type: '@inverter/INVERTER_UPDATE_SUCCESS',
  };
}

export function inverterDeleteRequest(inverter_id) {
  return {
    type: '@inverter/INVERTER_DELETE_REQUEST',
    payload: { inverter_id },
  };
}
