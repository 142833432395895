import { combineReducers } from 'redux';

import auth from './auth/reducer';
import user from './user/reducer';
import payment from './payment/reducer';
import board from './board/reducer';
import inverter from './inverter/reducer';
import budget from './budget/reducer';
import dealership from './dealership/reducer';
import temperature from './temperature/reducer';
import setting from './setting/reducer';
import region from './region/reducer';
import city_region from './city_region/reducer';
import discount_region from './discount_region/reducer';
import discount_user from './discount_user/reducer';

export default combineReducers({
  auth,
  user,
  payment,
  board,
  inverter,
  budget,
  dealership,
  temperature,
  setting,
  region,
  city_region,
  discount_region,
  discount_user
});
