import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdFirstPage, MdLastPage, MdSearch } from 'react-icons/md';
import { FiUserCheck, FiPlus, FiEdit, FiTrash, FiX  } from 'react-icons/fi';
import { ImSpinner11 } from 'react-icons/im';
import Pagination from "react-js-pagination";

import api from '~/services/api' ;
import { deleteUserRequest } from '~/store/modules/user/actions';


export default function Users() {
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [searchInput, setSearchInput] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async (pageNumber = 1) => {
    setIsLoading(true);
    try {
      const response = await api.get(`users?page=${pageNumber}`);
      setUsers(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log('Error: '+error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const setSearch = e => {
    setSearchInput(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  }

  async function handleSearch(){
    setIsLoading(true);
    if (searchInput !== '') {
      try {
        const response = await api.get(`users/search/${searchInput}`);
        setUsers(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log(`Error: ${error}`);
      }
    } else {
      fetchData();
    }
  }

  function deleteUser(user, name) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui-confirm-delete'>
            <div className="title">Confirma a exclusão do usuário: <strong>{name}</strong>? </div>
            <button onClick={onClose}><FiX size="18px"/> Cancelar exclusão</button>
            <button
              className="yes"
              onClick={() => {
                handleClickDelete(user);
                onClose();
                fetchData();
              }}
            >
              <FiTrash size="18px"/> Sim, pode excluir!
            </button>
          </div>
        );
      }
    });
  }

  function handleClickDelete(user) {
    dispatch(deleteUserRequest(user));
  }

  return (
    <div className="container">
      <div className="title">
        <h1>
          <FiUserCheck size="19px"/> Listagem de <span> usuários </span>
        </h1>
        <Link to="/create-user" className="button-add"><FiPlus size="16px" color="#6159fd" />Cadastrar usuário</Link>
      </div>

      <div className="painel">
        <div className="header-search">
          <div className="search-table">
            <div className="control">
              <input
                className="input-group search"
                type="text"
                placeholder="Pesquisar"
                autoComplete="off"
                onChange={setSearch}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div className="control">
              <a
                className="button search"
                onClick={handleSearch}
                >
                <MdSearch
                  size={20}
                  color="#a9a9a9"
                />
              </a>
            </div>
          </div>
          <div>
            <span className="total-register">Total de registros: <strong>{users.total}</strong></span>
          </div>
        </div>
        {isLoading ? (
          <div className="loading">
          <ImSpinner11
            size={24}
            color="#0A203F"
            className="fa-spin"
          /> Carregando ...</div>
        ) : (
          <>
            <table className="table is-striped">
              <thead>
                <tr>
                  <th width="80" className="text-center">Código</th>
                  <th>Nome</th>
                  <th>E-mail</th>
                  <th>Permissão</th>
                  <th width="180" className="text-center">Ações</th>
                </tr>
              </thead>
              {users.data.length === 0 ? (
                <tbody>
                  <tr>
                    <td colSpan="5" className="text-center">Nenhum Registro encontrado :(</td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {users.data.map((value, index) => (
                    <tr key={value.id}>
                      <td key={value.id}>{value.id}</td>
                      <td>{value.name}</td>
                      <td>{value.email}</td>
                      {value.role_name === 'admin' ? (
                        <td>Administrador</td>
                      ) : ('')}
                      {value.role_name === 'agent' ? (
                        <td>Representante</td>
                      ) : ('')}
                      {value.role_name === 'user' ? (
                        <td>Manutenção</td>
                      ) : ('')}
                      <td width="250" className="text-center">
                        <div className="button-action">
                          <Link to={`/edit-user/${value.id}`} className="button-edit"><FiEdit size="18px"/>Editar</Link>
                          <button onClick={() => deleteUser(value.id, value.name)} className="button-delete"><FiTrash size="18px"/>Excluir</button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
            <Pagination
              activePage={users.current_page}
              itemsCountPerPage={users.per_page}
              totalItemsCount={users.total}
              onChange={(pageNumber) => fetchData(pageNumber)}
              prevPageText={<MdKeyboardArrowLeft size="18px" color="#A3AEB7" />}
              firstPageText={<MdFirstPage size="18px" color="#A3AEB7" />}
              nextPageText={<MdKeyboardArrowRight size="18px" color="#A3AEB7" />}
              lastPageText={<MdLastPage size="18px" color="#A3AEB7" />}
            />
          </>
        )}
      </div>
    </div>
  );

}
