import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import api from '~/services/api';

import { Container } from './styles';

export default function AvatarInput() {
  const profile = useSelector(state => state.user.profile);

  const [file, setFile] = useState(profile && profile.file_id);
  const [preview, setPreview] = useState(profile && profile.file_path);
  const data = useFormContext();

  async function handleChange(e) {
    const data = new FormData();
    data.append('avatar', e.target.files[0]);
    const response = await api.post('files', data);
    const { id, path } = response.data;

    setFile(id);
    setPreview(path);
  }

  return (
    <Container>
      <label htmlFor="avatar">

        <img src={preview || 'https://api.adorable.io/avatars/67/abott@adorable.png'} alt="" />

        <input type="hidden" name="file_id" value={file} ref={data.register} />

        <input
          type="file"
          id="avatar"
          accept="image/*"
          data-file={file}
          onChange={handleChange}
        />

      </label>
    </Container>
  );
}
