import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { paymentUpdateSuccess } from './actions';

export function* paymentSave({ payload }) {
  try {
    const response = yield call(api.post, 'payments', payload.data);
    toast.success(response.data);
  } catch (error) {
    toast.error('Não foi possível salvar os dados. Contacte o administrador!');
  }
}

export function* paymentUpdate({ id, payload }) {
  try {
    const response = yield call (api.put, `payments/${payload.id}`, payload.data);
    toast.success('Forma de pagamento atualizado com sucesso!');
    yield put(paymentUpdateSuccess(response.data));
  } catch (error) {
    toast.error('Não foi possível atualizar forma de pagamento. Contacte o administrador.');
  }
}

export function* paymentDelete({ payload }) {
  try {
    yield call (api.delete, `payments/${payload.payment_id}`);
    toast.success('Forma de pagamento excluída com sucesso!');
  } catch (error) {
    toast.error('Não foi possível excluir a forma de pagamento. Contacte o administrador.');
  }
}

export function* paymentImport({ payload }) {
  try {
    let data = new FormData();
    data.append('file_import', payload.data.file_import[0]);
    data.append('price_type', payload.data.price_type);
    data.append('board_id', payload.data.board_id);

    if (payload.data.price_state.length > 0) {
      var arrayStates = [];
      payload.data.price_state.map((value) => {
        arrayStates.push(value);
      })
      data.append('price_state', arrayStates);
    } else {
      data.append('price_state', '0');
    }
    yield call (api.post, `payments/import`, data);
    toast.success('Formas de pagamento importadas com sucesso!');
  } catch (error) {
    toast.error('Não foi possível importar a forma de pagamento. Contacte o administrador.');
  }
}

export function* paymentUpdateBoard({ payload }) {
  try {
    let data = new FormData();
    data.append('file_import', payload.data.file_import[0]);
    yield call (api.post, `payments/update-board/${payload.first}/${payload.last}`, data);
    toast.success('Formas de pagamento atualizada com sucesso!');
  } catch (error) {
    toast.error('Não foi possível atualizar a forma de pagamento. Contacte o administrador.');
  }
}

export default all([
  takeLatest('@payment/PAYMENT_SAVE_REQUEST', paymentSave),
  takeLatest('@payment/PAYMENT_UPDATE_REQUEST', paymentUpdate),
  takeLatest('@payment/DELETE_PAYMENT_REQUEST', paymentDelete),
  takeLatest('@payment/PAYMENT_IMPORT_REQUEST', paymentImport),
  takeLatest('@payment/PAYMENT_UPDATE_BOARD_REQUEST', paymentUpdateBoard),
]);
