import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form'

import { FiArrowLeft, FiUpload } from 'react-icons/fi';
import { paymentUpdateBoardRequest } from '~/store/modules/payment/actions';

export default function ImportPayment() {
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();

  const FirstItem = () => {
    let { firstItem } = useParams();
    return firstItem;
  };
  const first = FirstItem();
  
  const LastItem = () => {
    let { lastItem } = useParams();
    return lastItem;
  };
  const last = LastItem();

  function onSubmit(data) {
    // console.log(data);
    dispatch(paymentUpdateBoardRequest(data, first, last));
  }

  return (
    <div className="container">
      <div className="title">
        <h1>
          <FiUpload size="19px"/> Atualizar <span> forma de pagamento </span>
        </h1>
        <Link to="/payments" className="button-add"><FiArrowLeft size="16px" color="#6159fd" />Voltar para listagem</Link>
      </div>

      <div className="painel">
        <form className="manager" onSubmit={handleSubmit(onSubmit)}>

          <h2>Selecione o arquivo (.xls ou .xlsx)</h2>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <input name="file_import" type="file" ref={register} />
              </div>
            </div>
          </div>
          <br /><br />


          <div className="row row-space">
            <div className="col-1">
              <div className="input-group">
                <button type="submit">Atualizar dados</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

}
