import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form'
import { default as NumberFormat } from 'react-number-format';

import { FiArrowLeft, FiPlusCircle } from 'react-icons/fi';
import { paymentSaveRequest } from '~/store/modules/payment/actions';

export default function AddPayment() {
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const [payment, setPayment] = useState([]);

  const onChange = e => {
    setPayment({...payment,
      [e.target.name]: e.target.value}
    );
  };

  function onSubmit(data) {
    dispatch(paymentSaveRequest(payment));
  }

  return (
    <div className="container">
      <div className="title">
        <h1>
          <FiPlusCircle size="19px"/> Cadastro de <span> forma de pagamento </span>
        </h1>
        <Link to="/payments" className="button-add"><FiArrowLeft size="16px" color="#6159fd" />Voltar para listagem</Link>
      </div>

      <div className="painel">
        <form className="manager" onSubmit={handleSubmit(onSubmit)}>

          <h2>Preencha o formulário abaixo</h2>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="potential">Potencia KWp</label>
                <input name="potential" type="text" ref={register} placeholder="Ex.: 1.44 " onChange={onChange} />
              </div>
            </div>
            <div className="col-2">
              <div className="input-group">
                <label htmlFor="middle_generation">Geração média KWp</label>
                <input name="middle_generation" type="text" ref={register} placeholder="Ex.: 180" onChange={onChange} />
              </div>
            </div>
          </div>

          <div className="row row-space">

            <div className="col-1">
              <div className="input-group">
                <label htmlFor="qtd_boards">Quantidade de placas</label>
                <input name="qtd_boards" type="text" ref={register} placeholder="Ex.: 4" onChange={onChange} />
              </div>
            </div>

          </div>

          <div className="row row-space">

            <div className="col-3">
              <div className="input-group">
                <label htmlFor="sales_price">Preço de venda</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  prefix={'R$ '}
                  placeholder="Ex.: R$ 0,00"
                  getInputRef={(props) =>
                    <input name="sales_price" type="text" ref={register}/>
                  } onValueChange={(values) => {
                    const {value} = values;
                    setPayment({...payment, sales_price: value})}
                  }
                />
              </div>
            </div>

            <div className="col-3">
              <div className="input-group">
                <label htmlFor="cash_price_advance">Preço à vista (adiant.)</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  prefix={'R$ '}
                  placeholder="Ex.: R$ 0,00"
                  getInputRef={(props) =>
                    <input name="cash_price_advance" type="text" ref={register}/>
                  } onValueChange={(values) => {
                    const {value} = values;
                    setPayment({...payment, cash_price_advance: value})}
                  }
                />
              </div>
            </div>

            <div className="col-3">
              <div className="input-group">
                <label htmlFor="cash_price_delivery">Preço à vista (entrega)</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  prefix={'R$ '}
                  placeholder="Ex.: R$ 0,00"
                  getInputRef={(props) =>
                    <input name="cash_price_delivery" type="text" ref={register}/>
                  } onValueChange={(values) => {
                    const {value} = values;
                    setPayment({...payment, cash_price_delivery: value})}
                  }
                />
              </div>
            </div>

          </div>

          <h2>Preço venda - Sol Brasil</h2>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="price_in_10">10x sem juros</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  prefix={'R$ '}
                  placeholder="Ex.: R$ 0,00"
                  getInputRef={(props) =>
                    <input name="price_in_10" type="text" ref={register}/>
                  } onValueChange={(values) => {
                    const {value} = values;
                    setPayment({...payment, price_in_10: value})}
                  }
                />
              </div>
            </div>

          </div>

          <h2>Preço financiadoras</h2>

          <div className="row row-space">

            <div className="col-3">
              <div className="input-group">
                <label htmlFor="price_in_36">36x</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  prefix={'R$ '}
                  placeholder="Ex.: R$ 0,00"
                  getInputRef={(props) =>
                    <input name="price_in_36" type="text" ref={register}/>
                  } onValueChange={(values) => {
                    const {value} = values;
                    setPayment({...payment, price_in_36: value})}
                  }
                />
              </div>
            </div>

            <div className="col-3">
              <div className="input-group">
                <label htmlFor="price_in_48">48x</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  prefix={'R$ '}
                  placeholder="Ex.: R$ 0,00"
                  getInputRef={(props) =>
                    <input name="price_in_48" type="text" ref={register}/>
                  } onValueChange={(values) => {
                    const {value} = values;
                    setPayment({...payment, price_in_48: value})}
                  }
                />
              </div>
            </div>

            <div className="col-3">
              <div className="input-group">
                <label htmlFor="price_in_60">60x</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  prefix={'R$ '}
                  placeholder="Ex.: R$ 0,00"
                  getInputRef={(props) =>
                    <input name="price_in_60" type="text" ref={register}/>
                  } onValueChange={(values) => {
                    const {value} = values;
                    setPayment({...payment, price_in_60: value})}
                  }
                />
              </div>
            </div>

          </div>

          <div className="row row-space">
            <div className="col-3">
              <div className="input-group">
                <label htmlFor="price_in_72">72x</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  prefix={'R$ '}
                  placeholder="Ex.: R$ 0,00"
                  getInputRef={(props) =>
                    <input name="price_in_72" type="text" ref={register}/>
                  } onValueChange={(values) => {
                    const {value} = values;
                    setPayment({...payment, price_in_72: value})}
                  }
                />
              </div>
            </div>
          </div>

          <h2>Tipo tabela de preço</h2>

          <div className="row row-space">

            <div className="col-1">
              <div className="input-group">
                <label htmlFor="price_type">Tabela de preço</label>
                <select name="price_type" ref={register} onChange={onChange} >
                  <option value="">Selecione o tipo do preço</option>
                  <option value="0">Padrão</option>
                  <option value="1">Especial</option>
                  <option value="2">Plus</option>
                </select>
              </div>
            </div>

          </div>

          <div className="row row-space">
            <div className="col-1">
              <div className="input-group">
                <button type="submit">Salvar dados</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

}
