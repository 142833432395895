import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { discountUserUpdateSuccess } from './actions';

export function* discountUserSave({ payload }) {
  try {
    const response = yield call(api.post, 'discount-users', payload.data);
    toast.success(response.data);
  } catch (error) {
    toast.error('Não foi possível salvar os dados. Contacte o administrador!');
  }
}

export function* discountUserUpdate({ id, payload }) {
  try {
    const response = yield call (api.put, `discount-users/${payload.id}`, payload.data);
    toast.success('Desconto atualizado com sucesso!');
    yield put(discountUserUpdateSuccess(response.data));
  } catch (error) {
    toast.error('Não foi possível atualizar o desconto. Contacte o administrador.');
  }
}

export function* discountUserDelete({ payload }) {
  try {
    yield call (api.delete, `discount-users/${payload.discount_user_id}`);
    toast.success('Desconto excluído com sucesso!');
  } catch (error) {
    toast.error('Não foi possível excluir o desconto. Contacte o administrador.');
  }
}

export default all([
  takeLatest('@discountUser/DISCOUNT_USER_SAVE_REQUEST', discountUserSave),
  takeLatest('@discountUser/DISCOUNT_USER_UPDATE_REQUEST', discountUserUpdate),
  takeLatest('@discountUser/DISCOUNT_USER_DELETE_REQUEST', discountUserDelete),
]);
