import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import user from './user/sagas';
import payment from './payment/sagas';
import board from './board/sagas';
import inverter from './inverter/sagas';
import budget from './budget/sagas';
import dealership from './dealership/sagas';
import temperature from './temperature/sagas';
import setting from './setting/sagas';
import region from './region/sagas';
import city_region from './city_region/sagas';
import discount_region from './discount_region/sagas';
import discount_user from './discount_user/sagas';

export default function* rootSaga() {
  return yield all([
    auth,
    user,
    payment,
    board,
    inverter,
    budget,
    dealership,
    temperature,
    setting,
    region,
    city_region,
    discount_region,
    discount_user,
  ]);
}
