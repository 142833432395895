export function discountRegionSaveRequest(data) {
  return {
    type: '@discountRegion/DISCOUNT_REGION_SAVE_REQUEST',
    payload: { data },
  };
}

export function discountRegionSaveSuccess(data) {
  return {
    type: '@discountRegion/DISCOUNT_REGION_SAVE_SUCCESS',
    payload: { data },
  };
}

export function discountRegionUpdateRequest(id, data) {
  return {
    type: '@discountRegion/DISCOUNT_REGION_UPDATE_REQUEST',
    payload: { id, data },
  };
}

export function discountRegionUpdateSuccess() {
  return {
    type: '@discountRegion/DISCOUNT_REGION_UPDATE_SUCCESS',
  };
}

export function discountRegionDeleteRequest(discount_region_id) {
  return {
    type: '@discountRegion/DISCOUNT_REGION_DELETE_REQUEST',
    payload: { discount_region_id },
  };
}
