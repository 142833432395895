import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

export default reducers => {
  const persistedReducer = persistReducer({
      key: 'sol_brasil',
      storage,
      whitelist: ['auth', 'user', 'setting'],
    },
    reducers
  );

  return persistedReducer;
}
