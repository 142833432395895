export function dealershipSaveRequest(data) {
  return {
    type: '@dealership/DEALERSHIP_SAVE_REQUEST',
    payload: { data },
  };
}

export function dealershipSaveSuccess(data) {
  return {
    type: '@dealership/DEALERSHIP_SAVE_SUCCESS',
    payload: { data },
  };
}

export function dealershipUpdateRequest(id, data) {
  return {
    type: '@dealership/DEALERSHIP_UPDATE_REQUEST',
    payload: { id, data },
  };
}

export function dealershipUpdateSuccess() {
  return {
    type: '@dealership/DEALERSHIP_UPDATE_SUCCESS',
  };
}

export function dealershipDeleteRequest(dealership_id) {
  return {
    type: '@dealership/DEALERSHIP_DELETE_REQUEST',
    payload: { dealership_id },
  };
}
