import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form'
import * as Yup from 'yup';
import { default as NumberFormat } from 'react-number-format';
import { FiArrowLeft, FiPlusCircle } from 'react-icons/fi';

import { boardSaveRequest } from '~/store/modules/board/actions';

const schema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
});

export default function AddBoard() {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm({
    validationSchema: schema
  });
  const [board, setBoard] = useState({
    name: "",
    manufacturer: "",
    serie: "",
    initial: "",
    char_power_nominal: "",
    char_type: "",
    char_width: "",
    char_height: "",
    material_code: "",
    material_production_year: "",
    material_description: "",
    material_value_acquisition: "",
    material_value_sale: "",
    material_out_stock: "",
    char_eletrics_power: "",
    char_eletrics_coefficient_tension: "",
    char_eletrics_yield: "",
    char_eletrics_coefficient_chain: "",
    char_eletrics_tension_vmpp: "",
    char_eletrics_tension_voc: "",
    char_eletrics_coefficient_power:"",
    char_eletrics_chain_impp: "",
    char_eletrics_temp_noct: "",
    char_eletrics_chain_isc: "",
    char_eletrics_chain_max_isolation: "",
    char_eletrics_diodos_by_pass: "",
    dimensions_thickness: "",
    dimensions_area_reference: "",
    char_eletrics_tolerance_yield: "",
    dimensions_weight: "",
    certifications_iec: "",
    certifications_class_ii: "",
    certifications_inmetro: "",
    certifications_note: "",
    warrant: "",
  });

  const onChange = e => {
    setBoard({...board,
      [e.target.name]: e.target.value}
    );
  };

  function onSubmit(data) {
    dispatch(boardSaveRequest(board));
  }

  return (
    <div className="container">
      <div className="title">
        <h1>
          <FiPlusCircle size="19px"/> Cadastro de <span> placa </span>
        </h1>
        <Link to="/boards" className="button-add"><FiArrowLeft size="16px" color="#6159fd" />Voltar para listagem</Link>
      </div>

      <div className="painel">
        <form className="manager" onSubmit={handleSubmit(onSubmit)}>

          <h2>Modelo / Identificação da placa</h2>

          <div className="row row-space">
            <div className="col-2">
              <div className="input-group">
                <label htmlFor="name">Nome da placa</label>
                <input
                  name="name"
                  type="text"
                  placeholder="Ex.: Nome da placa"
                  onChange={onChange}
                  ref={register}
                />
                {errors.name && <span>{errors.name.message}</span>}
              </div>
            </div>
            <div className="col-2">
              <div className="input-group">
                <label htmlFor="manufacturer">Fabricante</label>
                <input
                  name="manufacturer"
                  type="text"
                  placeholder="Ex.: Nome do Fabricante LTDA"
                  onChange={onChange}
                  ref={register}
                />
              </div>
            </div>
          </div>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="serie">Série</label>
                <input
                  name="serie"
                  type="text"
                  placeholder="Ex.: CS5A"
                  onChange={onChange}
                  ref={register}
                />
              </div>
            </div>

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="initial">Sigla</label>
                <input
                  name="initial"
                  type="text"
                  placeholder="Ex.: CS3U-360P"
                  onChange={onChange}
                  ref={register}
                />
              </div>
            </div>
          </div>

          <h2 className="identificator">Características principais</h2>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="char_power_nominal">Potência nominal</label>
                <NumberFormat
                  suffix=" W"
                  placeholder="Ex.: 360 W"
                  onValueChange={(values) => {
                    const {value} = values;
                    setBoard({...board, char_power_nominal: value})}
                  }
                  getInputRef={(props) =>
                    <input name="char_power_nominal" type="text" ref={register}/>
                  }
                />

              </div>
            </div>

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="char_type">Tipo</label>
                <input
                  name="char_type"
                  type="text"
                  placeholder="Ex.: Silicío policristalino"
                  onChange={onChange}
                  ref={register}
                />
              </div>
            </div>

          </div>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="char_width">Largura</label>
                <NumberFormat
                  suffix=" mm"
                  placeholder="Ex.: 992 mm"
                  onValueChange={(values) => {
                    const {value} = values;
                    setBoard({...board, char_width: value})}
                  }
                  getInputRef={(props) =>
                    <input name="char_power_nominal" type="text" ref={register}/>
                  }
                />
              </div>
            </div>

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="char_height">Altura</label>
                <NumberFormat
                  suffix=" mm"
                  placeholder="Ex.: 2000 mm"
                  onValueChange={(values) => {
                    const {value} = values;
                    setBoard({...board, char_height: value})}
                  }
                  getInputRef={(props) =>
                    <input name="char_height" type="text" ref={register}/>
                  }
                />
              </div>
            </div>

          </div>

          <h2 className="identificator">Materiais</h2>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="material_code">Código</label>
                <input
                  name="material_code"
                  type="text"
                  placeholder="Ex.: 123456"
                  onChange={onChange}
                  ref={register}
                />
              </div>
            </div>

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="material_production_year">Ano de produção</label>
                <select name="material_production_year" ref={register} onChange={onChange}>
                  <option value="">Selecione o ano de produção</option>
                  <option value="2018">2018</option>
                  <option value="2019">2019</option>
                  <option value="2020">2020</option>
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                  <option value="2026">2026</option>
                  <option value="2027">2027</option>
                  <option value="2028">2028</option>
                  <option value="2029">2029</option>
                  <option value="2030">2030</option>
                </select>
              </div>
            </div>

          </div>

          <div className="row row-space">

            <div className="col-1">
              <div className="input-group">
                <label htmlFor="material_description">Descrição</label>
                <input
                  name="material_description"
                  type="text"
                  placeholder="Ex.: Descrição do material da placa"
                  onChange={onChange}
                  ref={register}
                />
              </div>
            </div>

          </div>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="material_value_acquisition">Preço de compra</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  prefix={'R$ '}
                  placeholder="Ex.: R$ 0,00"
                  getInputRef={(props) =>
                    <input name="material_value_acquisition" type="text" ref={register}/>
                  } onValueChange={(values) => {
                    const {value} = values;
                    setBoard({...board, material_value_acquisition: value})}
                  }
                />

              </div>
            </div>

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="material_value_sale">Preço de venda</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  prefix={'R$ '}
                  placeholder="Ex.: R$ 0,00"
                  getInputRef={(props) =>
                    <input name="material_value_sale" type="text" ref={register}/>
                  } onValueChange={(values) => {
                    const {value} = values;
                    setBoard({...board, material_value_sale: value})}
                  }
                />
              </div>
            </div>

          </div>

          <div className="row row-space">

            <div className="col-1">
              <div className="input-group check">
                <label htmlFor="material_out_stock">Fora de produção?</label>
                <div className="check-container">
                  <input
                    type="checkbox"
                    name="product_out_stock"
                    ref={register}
                    onChange={onChange}
                  />
                  <label> Sim? </label>
                </div>
              </div>
            </div>

          </div>


          <h2 className="identificator">Características elétricas</h2>

          {/* <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="char_eletrics_power">Potência / Superfície</label>
                <NumberFormat
                  suffix=" W/m²"
                  placeholder="Ex.: 181,45 W/m²"
                  onValueChange={(values) => {
                    const {value} = values;
                    setBoard({...board, char_eletrics_power: value})}
                  }
                  getInputRef={(props) =>
                    <input name="char_eletrics_power" type="text" ref={register} />
                  }
                />
              </div>
            </div>

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="char_eletrics_coefficient_tension">Coeficiente de tensão</label>
                <NumberFormat
                  suffix=" mV/ºC"
                  placeholder="Ex.: -0.29 mV/ºC"
                  onValueChange={(values) => {
                    const {value} = values;
                    setBoard({...board, char_eletrics_coefficient_tension: value})}
                  }
                  getInputRef={(props) =>
                    <input name="char_eletrics_coefficient_tension" type="text" ref={register} />
                  }
                />

              </div>
            </div>

          </div>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="char_eletrics_yield">Rendimento</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  placeholder="Ex.: 18,15 %"
                  suffix=" %"
                  onValueChange={(values) => {
                    const {value} = values;
                    setBoard({...board, char_eletrics_yield: value})}
                  }
                  getInputRef={(props) =>
                    <input name="char_eletrics_yield" type="text" ref={register} />
                  }
                />

              </div>
            </div>

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="char_eletrics_coefficient_chain">Coeficiente de corrente</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  placeholder="Ex.: -0.05 mA/ºC"
                  suffix=" mA/ºC"
                  onValueChange={(values) => {
                    const {value} = values;
                    setBoard({...board, char_eletrics_coefficient_chain: value})}
                  }
                  getInputRef={(props) =>
                    <input name="char_eletrics_coefficient_chain" type="text" ref={register} />
                  }
                />
              </div>
            </div>

          </div>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="char_eletrics_tension_vmpp">Tensão nominal (VMPP)</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  placeholder="Ex.: 39,6 V"
                  suffix=" V"
                  onValueChange={(values) => {
                    const {value} = values;
                    setBoard({...board, char_eletrics_tension_vmpp: value})}
                  }
                  getInputRef={(props) =>
                    <input name="char_eletrics_tension_vmpp" type="text" ref={register} />
                  }
                />
              </div>
            </div>

          </div> */}

          {/* <div className="row row-space"> */}

            {/* <div className="col-2">
              <div className="input-group">
                <label htmlFor="char_eletrics_tension_voc">Tensão Circuito Aberto (VOC)</label>
                <NumberFormat
                  suffix=" V"
                  placeholder="Ex.: 47 V"
                  onValueChange={(values) => {
                    const {value} = values;
                    setBoard({...board, char_eletrics_tension_voc: value})}
                  }
                  getInputRef={(props) =>
                    <input name="char_eletrics_tension_voc" type="text" ref={register} />
                  }
                />
              </div>
            </div> */}

            {/* <div className="col-2">
              <div className="input-group">
                <label htmlFor="char_eletrics_coefficient_power">Coeficiente de Potência</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  placeholder="Ex.: -0,37 %/ºC"
                  suffix=" %/ºC"

                  onValueChange={(values) => {
                    const {value} = values;
                    setBoard({...board, char_eletrics_coefficient_power: value})}
                  }
                  getInputRef={(props) =>
                    <input name="char_eletrics_coefficient_power" type="text" ref={register} />
                  }
                />
              </div>
            </div>

          </div> */}

          <div className="row row-space">
            
            <div className="col-2">
              
              <div className="input-group">
                <label htmlFor="char_eletrics_coefficient_power">Coeficiente de Potência</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  placeholder="Ex.: -0,37 %/ºC"
                  suffix=" %/ºC"

                  onValueChange={(values) => {
                    const {value} = values;
                    setBoard({...board, char_eletrics_coefficient_power: value})}
                  }
                  getInputRef={(props) =>
                    <input name="char_eletrics_coefficient_power" type="text" ref={register} />
                  }
                />
              </div>
            </div>

            {/* <div className="col-2">
              <div className="input-group">
                <label htmlFor="char_eletrics_chain_impp">Corrente nominal (IMPP)</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={1}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  placeholder="Ex.: 9.1 A"
                  suffix=" A"
                  onValueChange={(values) => {
                    const {value} = values;
                    setBoard({...board, char_eletrics_chain_impp: value})}
                  }
                  getInputRef={(props) =>
                    <input name="char_eletrics_chain_impp" type="text" ref={register} />
                  }
                />
              </div>
            </div> */}

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="char_eletrics_temp_noct">Temperatura nominal de trabalho </label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  placeholder="Ex.: 42 ºC"
                  suffix=" ºC"
                  onValueChange={(values) => {
                    const {value} = values;
                    setBoard({...board, char_eletrics_temp_noct: value})}
                  }
                  getInputRef={(props) =>
                    <input name="char_eletrics_temp_noct" type="text" ref={register} />
                  }
                />
              </div>
            </div>

          </div>

          {/* <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="char_eletrics_chain_isc">Corrente C.C. (ISC)</label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  placeholder="Ex.: 9.67 A"
                  suffix=" A"
                  onValueChange={(values) => {
                    const {value} = values;
                    setBoard({...board, char_eletrics_chain_isc: value})}
                  }
                  getInputRef={(props) =>
                    <input name="char_eletrics_chain_isc" type="text" ref={register} />
                  }
                />
              </div>
            </div>

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="char_eletrics_chain_max_isolation">Tensão máxima de isolamento </label>
                <NumberFormat
                  decimalSeparator={","}
                  decimalScale={0}
                  fixedDecimalScale={true}
                  thousandSeparator={"."}
                  placeholder="Ex.: 1.500 V"
                  suffix=" V"
                  onValueChange={(values) => {
                    const {value} = values;
                    setBoard({...board, char_eletrics_chain_max_isolation: value})}
                  }
                  getInputRef={(props) =>
                    <input name="char_eletrics_chain_max_isolation" type="text" ref={register} />
                  }
                />
              </div>
            </div>

          </div>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="char_eletrics_diodos_by_pass">Diodos de by-pass</label>
                <select name="char_eletrics_diodos_by_pass" ref={register} onChange={onChange}>
                  <option value="">Selecione diodos by pass ...</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
              </div>
            </div>

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="char_eletrics_tolerance_yield">Tolerância de rendimento </label>
                <NumberFormat
                  suffix=" %"
                  placeholder="Ex.: 3 %"
                  onValueChange={(values) => {
                    const {value} = values;
                    setBoard({...board, char_eletrics_tolerance_yield: value})}
                  }
                  getInputRef={(props) =>
                    <input name="char_eletrics_tolerance_yield" type="text" ref={register} />
                  }
                />
              </div>
            </div>

          </div> */}

          <h2 className="identificator">Dimensões</h2>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="dimensions_thickness">Espessura</label>
                <NumberFormat
                  suffix=" mm"
                  placeholder="Ex.: 35 mm"
                  onValueChange={(values) => {
                    const {value} = values;
                    setBoard({...board, dimensions_thickness: value})}
                  }
                  getInputRef={(props) =>
                    <input name="dimensions_thickness" type="text" ref={register} />
                  }
                />
              </div>
            </div>

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="dimensions_area_reference">Área disponível de referência </label>
                <NumberFormat
                  suffix=" m²"
                  placeholder="Ex.: 2 m²"
                  onValueChange={(values) => {
                    const {value} = values;
                    setBoard({...board, dimensions_area_reference: value})}
                  }
                  getInputRef={(props) =>
                    <input name="dimensions_area_reference" type="text" ref={register} />
                  }
                />
              </div>
            </div>

          </div>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="dimensions_weight">Peso</label>
                <NumberFormat
                  placeholder="Ex.: 22,5 KG"
                  format={"##,## KG"}
                  onValueChange={(values) => {
                    const {value} = values;
                    setBoard({...board, dimensions_weight: value})}
                  }
                  getInputRef={(props) =>
                    <input name="dimensions_weight" type="text" ref={register} />
                  }
                />
              </div>
            </div>

          </div>

          <h2 className="identificator">Certificações</h2>

          <div className="row row-space">

            <div className="col-3">
              <div className="input-group check">
                <div className="check-container check">
                  <input
                    type="checkbox"
                    name="certifications_iec"
                    ref={register}
                    onChange={onChange}
                  />
                  <label> Certificação IEC</label>
                </div>
              </div>
            </div>

            <div className="col-3">
              <div className="input-group check">
                <div className="check-container check">
                  <input
                    type="checkbox"
                    name="certifications_class_ii"
                    ref={register}
                    onChange={onChange}
                  />
                  <label> Certificação de classe II</label>
                </div>
              </div>
            </div>

            <div className="col-3">
              <div className="input-group check">
                <div className="check-container check">
                  <input
                    type="checkbox"
                    name="certifications_inmetro"
                    ref={register}
                    onChange={onChange}
                  />
                  <label> Certificação INMETRO</label>
                </div>
              </div>
            </div>

          </div>

          <div className="row row-space">

            <div className="col-1">
              <div className="input-group">
                <label htmlFor="certifications_note">Notas</label>
                <input
                  name="certifications_note"
                  type="text"
                  placeholder="Informe as notas"
                  onChange={onChange}
                  ref={register}
                />
              </div>
            </div>

          </div>

          <h2 className="identificator">Garantia em anos</h2>

          <div className="row row-space">

            <div className="col-2">
              <div className="input-group">
                <label htmlFor="warrant">Informe o tempo de garantia da placa </label>
                <input
                  name="warrant"
                  type="text"
                  placeholder="25"
                  onChange={onChange}
                  ref={register}
                />
              </div>
            </div>

          </div>


          <div className="row row-space">
            <div className="col-1">
              <div className="input-group">
                <button type="submit">Salvar dados</button>
              </div>
            </div>
          </div>

        </form>
      </div>
    </div>
  );

}
